// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.SimpleDataListHeader {
  width: 100%;
  padding: 15px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  font-weight: 600;
}

.SimpleDataListContainer {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.SimpleDataItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.SimpleDataItemContent {
  flex-grow: 1;
}

.FilterModal{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  max-height: 20vh;
  overflow: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/utilities/FilterComponent/FilterComponent.css"],"names":[],"mappings":";;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["\n\n.SimpleDataListHeader {\n  width: 100%;\n  padding: 15px;\n  background-color: #f0f0f0;\n  border-bottom: 1px solid #e0e0e0;\n  text-align: center;\n  font-weight: 600;\n}\n\n.SimpleDataListContainer {\n  width: 100%;\n  flex-grow: 1;\n  overflow-y: auto;\n}\n\n.SimpleDataItemContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n  border-bottom: 1px solid #e0e0e0;\n}\n\n.SimpleDataItemContent {\n  flex-grow: 1;\n}\n\n.FilterModal{\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 10px;\n  max-height: 20vh;\n  overflow: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
