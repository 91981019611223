import { CatalogResponse } from "../types";

export const getAdvancedTaggingFilters = (
  catalog: CatalogResponse["catalog"]
) => {
  const response = {} as Record<string, string[]>;
  for (const fileName in catalog) {
    for (const tagKey in catalog[fileName]) {
      const value = catalog[fileName][tagKey];
      const canBeSkipped =
        [
          "chunks",
          "word_count",
          "document_length",
          "file_size",
          "last_modified",
        ].includes(tagKey) ||
        !value ||
        !Array.isArray(value) ||
        value[0] === undefined ||
        value[0] === null;

      if (canBeSkipped) continue;

      response[tagKey] = response[tagKey] || [];
      response[tagKey] = [...new Set([...response[tagKey], ...value])];
    }
  }

  return response;
};
