import { createContext, useCallback, useContext, useState } from "react";
import Auth from "../auth/AuthProvider";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";

import {
  countStatusValues,
  filterCatalogByFailedTag,
  filterDataGroupByRelatedInfo,
  mergeTagWithDefaults,
  updateParentLabelObj,
} from "../components/utilities/functions/utils";
import { DataContext, defaultCurrentTag } from "./DataContext";
import {
  uploadTags,
  runRule,
} from "../components/utilities/functions/apiCalls";
import { toast } from "../components/utilities/Toast";
import { useAtom } from "jotai";
import { runningTasksAtom, selectedCatalogItemsAtom } from "../atoms";
import { abortTask, waitTaskDone as waitForTaskToEnd } from "../utils/workers";

export const TagContext = createContext();

export const TagProvider = ({ children }) => {
  const {
    setShowScreen,
    currentTag,
    setCurrentTag,
    setCurrentTotalProcessCount,
    availableTags,
    setAvailableTags,
    currentDataGroup,
    preferences,
    tagReRun,
    failedTags,
    usedCatalog,
    fetchInitialCatalog,
  } = useContext(DataContext);

  const [isActiveAction, setIsActiveAction] = useState(false);
  const [prevCount, setPrevCount] = useState(0);
  const [processingTags, setProcessingTags] = useState([]);
  const [, setRunningTasks] = useAtom(runningTasksAtom);
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const defaultTagTypes = {
    sensitivity: "Sensitivity",
    classification: "Classification",
  };

  const [relatedInfo, setRelatedInfo] = useState({});
  const [tagDict, setTagDict] = useState({
    ...availableTags.sensitivity.tagger_params.tag_dict,
    ...availableTags.llm.tagger_params.tag_dict,
  });
  const [processingTagTasks, setProcessingTagTasks] = useState(new Map());

  const abortTagging = useCallback(
    (tagKey) => {
      const tasks = processingTagTasks.get(tagKey) || [];
      toast.info({
        title: "Aborting tagging",
        description: `Aborting ${tasks.length} tasks for tag ${tagKey}`,
      });

      tasks.forEach(async (task_id) => {
        abortTask(
          task_id,
          (await Auth.currentAuthenticatedUser()).username
        ).then(() => {
          setProcessingTagTasks((prev) => {
            const newMap = new Map(prev);

            newMap.delete(tagKey);

            return newMap;
          });
          setProcessingTags((prev) =>
            prev.filter(({ label }) => label !== tagKey)
          );
          setRunningTasks((prev) => {
            return prev.filter((task) => task.id !== tagKey);
          });
        });
      });
    },
    [processingTagTasks]
  );

  const processTag = async (tagMethod, tagKey = null) => {
    const controller = new AbortController();
    setProcessingTags((prev) => {
      const newTags = [
        ...prev,
        {
          label: tagKey,
        },
      ];

      return newTags;
    });
    setIsActiveAction(true);
    setPrevCount(countStatusValues(currentDataGroup, "Processing").Processing);

    let currentDataGroupSnapshot = { ...currentDataGroup };
    const creds = (await Auth.currentAuthenticatedUser()).username;
    let newTag = {};

    if (tagMethod === "addTag") {
      if (!currentTag.name.trim() || !currentTag.description.trim()) {
        toast.error({
          title: "Error",
          description:
            "Please fill out the name, description, and type of tag before proceeding.",
        });
        return false;
      }
      newTag = { ...currentTag };
      setCurrentTag(preferences.webapp_profile.DEFAULT_TAG);
      const currentTags = availableTags;
      const updatedTags = updateParentLabelObj(currentTags, newTag);

      uploadTags(updatedTags, usedCatalog);
      setAvailableTags(updatedTags);
    } else if (tagMethod === "ReRunFailed") {
      currentDataGroupSnapshot = filterCatalogByFailedTag(
        currentDataGroupSnapshot,
        failedTags.get(tagKey) || []
      );

      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];

      tagReRun(tagKey);
    } else if (tagMethod === "RunAll") {
      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];

      tagReRun(tagKey);
    } else {
      if (selectedCatalogItems.size) {
        for (const docKey in currentDataGroupSnapshot) {
          if (selectedCatalogItems.has(docKey)) continue;
          delete currentDataGroupSnapshot[docKey];
        }
      } else {
        currentDataGroupSnapshot = filterDataGroupByRelatedInfo(
          currentDataGroupSnapshot,
          relatedInfo[tagKey].matchingNames
        );
      }

      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];
    }

    setCurrentTotalProcessCount(Object.keys(currentDataGroupSnapshot).length);
    setShowScreen("catalog");
    setCurrentTotalProcessCount(Object.keys(currentDataGroupSnapshot).length);

    const entries = [];

    for (const [id, catalogItem] of Object.entries(currentDataGroupSnapshot)) {
      const sendObject = {
        data_store: JSON.stringify({
          ...preferences.webapp_profile.DATA_STORES[catalogItem.storage_type],
          path: `${catalogItem.file_directory}/${id}`,
        }),
        tagger_list: JSON.stringify({
          llm: {
            tagger_params: {
              model: {
                provider: preferences.webapp_profile.PROVIDER_USED,
                version: preferences.webapp_profile.MODEL_USED,
              },
              iters: 1,
              tag_dict: { [newTag.name]: newTag },
            },
          },
        }),
        file_catalog_entry: JSON.stringify({ [id]: catalogItem }),
        catalog_name: usedCatalog,
        quarantine_name: preferences.system.QUARANTINECATALOG,
        check_sensitivity: false,
      };

      entries.push(sendObject);

      // TODO: Fix progress bar update / remove
      // TODO: Move catalog summary and search detail update to function that checks "Processed" for catalog and then calls APIs
      // setCurrentProcessCount((prevState) => prevState + 1);
      // setCatalogSummary(consolidationResponse.new_catalog_summary);
      // setSearchDetails(consolidationResponse.search_details);
    }

    sendRequest(
      {
        entries,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
      },
      ENDPOINTS["create_catalog_in_bulk"],
      undefined,
      undefined,
      controller.signal
    )
      .then((response) => response.json())
      .then(({ task_id }) => {
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          newMap.set(tagKey, [...(newMap.get(tagKey) || []), task_id]);
          return newMap;
        });

        return waitForTaskToEnd(task_id, creds);
      })
      .then(async () => {
        setIsActiveAction(false);
        setProcessingTags((prev) =>
          prev.filter(({ label }) => label !== tagKey)
        );
        setRunningTasks((prev) => prev.filter((task) => task.id !== tagKey));
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          newMap.delete(tagKey);
          return newMap;
        });

        return await fetchInitialCatalog(usedCatalog);
      })
      .catch((error) => {
        console.error("Error during processing tag:", error);
      });
  };

  const runSingleRule = async (catalog, output_tag) => {
    toast.info({
      title: "Info",
      description: "Applying rule on catalog...",
    });

    await runRule(catalog, usedCatalog, output_tag);

    toast.info({
      title: "Info",
      description: "Rule ran successfully!",
    });
  };

  const saveTag = async (currentTag) => {
    const newTag = { ...currentTag };

    const hasValidName =
      newTag.name && typeof newTag.name === "string" && newTag.name.trim();
    const hasValidDescription =
      newTag.description &&
      typeof newTag.description === "string" &&
      newTag.description.trim();
    const hasValidTagType =
      newTag.tagType &&
      typeof newTag.tagType === "string" &&
      newTag.tagType.trim();

    if (!hasValidName || !hasValidDescription || !hasValidTagType) {
      return false;
    }

    toast.info({
      title: "Info",
      description: "Preparing tag to be saved.",
    });

    const validExamples = (newTag.examples || []).filter(
      (example) =>
        example.evidence &&
        example.evidence.trim() !== "" &&
        example.value.trim() !== ""
    );

    newTag.examples = validExamples;

    const mergedTag = mergeTagWithDefaults(newTag, defaultCurrentTag);
    const currentTags = availableTags;
    const updatedTags = updateParentLabelObj(currentTags, mergedTag);
    setAvailableTags(updatedTags);
    await uploadTags(updatedTags, usedCatalog);

    toast.success({
      title: "Success",
      description: "Tag saved successfully!",
    });
  };

  return (
    <TagContext.Provider
      value={{
        // Getters
        relatedInfo,
        tagDict,
        isActiveAction,
        prevCount,
        processingTags,
        defaultTagTypes,
        // Setters
        setIsActiveAction,
        setRelatedInfo,
        setPrevCount,
        setTagDict,
        setProcessingTags,
        // Functions
        processTag,
        saveTag,
        runSingleRule,
        processingTagTasks,
        abortTagging,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};
