/* eslint-disable no-restricted-globals */

import { getAdvancedTaggingFilters } from "./tasks";

// register more tasks here, tasks must have zero or exactly one argument
const tasks = {
  getAdvancedTaggingFilters,
};

export default class Workers {
  static invoke<T extends keyof typeof tasks>(
    method: T,
    data: Parameters<(typeof tasks)[T]>[0]
  ) {
    return new Promise<ReturnType<(typeof tasks)[T]>>(async (res) => {
      const worker = new Worker(new URL("./threading.ts", import.meta.url));
      worker.postMessage({
        source: "DeasieFrontendWorkers",
        method,
        data,
      });
      worker.onmessage = (e) => {
        res(e.data);
        worker.terminate();
      };
    });
  }
}

self.onmessage = (e) => {
  if (e.data.source !== "DeasieFrontendWorkers") return;
  const method = tasks[e.data.method as keyof typeof tasks];

  self.postMessage(method(e.data.data));
};
