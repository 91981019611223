import {
  faFileAlt,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileText,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useRef } from "react";

export const useFileIconMapper = () => {
  const defaultIcon = useRef(faFileText);
  const extensionToIcon = useRef({
    pdf: faFilePdf,
    txt: faFileAlt,
    docx: faFileWord,
    doc: faFileWord,
    csv: faFileCsv,
    xlsx: faFileExcel,
    xls: faFileExcel,
    pptx: faFilePowerpoint,
    ppt: faFilePowerpoint,
    jpg: faFileImage,
    jpeg: faFileImage,
    png: faFileImage,
    gif: faFileImage,
  });

  const getIconFor = useCallback(
    (fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();

      return extensionToIcon.current[fileExtension] || defaultIcon.current;
    },
    [defaultIcon, extensionToIcon],
  );

  return { getIconFor };
};
