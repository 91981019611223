import { useAtom } from "jotai";
import { runningTasksAtom } from "../../../atoms";

const RunningTasksModal = () => {
  const [runningTasks] = useAtom(runningTasksAtom);
  return (
    <div className="flex h-full w-full p-4 flex-col gap-8">
      <div className="flex w-full justify-center gap-8">
        <table className="w-full">
          <thead>
            <tr>
              <th colSpan="3" className="text-center text-3xl pb-12">
                Tasks Currently Running
              </th>
            </tr>
            {runningTasks.length === 0 ? (
              <tr>
                <th colSpan="3" className="text-center font-normal text-sm">
                  No tasks running
                </th>
              </tr>
            ) : (
              <tr>
                <th className="font-semibold">Process</th>
                <th className="font-semibold">Description</th>
                <th className="font-semibold ">Status</th>
              </tr>
            )}
          </thead>
          <tbody>
            {runningTasks.map((task) => (
              <tr key={task.id}>
                <td className="text-center pb-4">{task.process}</td>
                <td className="text-center pb-4">{task.description}</td>
                <td className="flex justify-center items-center pb-4">
                  <div className="bg-slate-200 w-32 rounded-full">
                    <p className="p-2 text-sm text-center">Running</p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RunningTasksModal;
