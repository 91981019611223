import { createContext, useState, useEffect, useRef } from "react";
import Auth from "../auth/AuthProvider";

import { API } from "@aws-amplify/api";
import { onTableUpdate } from "../components/utilities/GraphQL/subscriptions";
import {
  updateCatalog,
  getRules,
  renameCatalog,
} from "../components/utilities/functions/apiCalls";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { LABELS_TO_EXCLUDE_FOR_SEARCH } from "../constants/labelConfig";

import {
  deleteLabelParentLabelObj,
  mergeTagWithDefaults,
  updateModelVersion,
} from "../components/utilities/functions/utils";
import { toast } from "../components/utilities/Toast";
import { Queue } from "../utils";
import { useCatalogChangeSubscription } from "../hooks/CatalogChangeSubscription";
import { useFailedTags } from "../hooks/FailedTags";
import { useUserProfile } from "./UserProfile";
import { useCreateCatalogMutation } from "../api/queryHooks";
import { selectedCatalogItemsAtom } from "../atoms";
import { useAtom } from "jotai";

export const DataContext = createContext();

export const defaultCurrentTag = {
  name: "",
  tagType: "classification",
  description: "",
  availableValues: [],
  allow_other_values: false,
  reference_file: "",
  max_words: 1,
  examples: null,
  option: "aiGenerated",
  risk_level: "",
  risk_level_description: {
    low: "",
    medium: "",
    high: "",
  },
  type: "word",
  is_document_level: false,
};

export const DataProvider = ({ children }) => {
  const [preferences, setPreferences] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showScreen, setShowScreen] = useState("catalog");
  const [usedCatalog, setUsedCatalog] = useState("catalog");
  const [catalogSummary, setCatalogSummary] = useState({});
  const [dataGroups, setDataGroups] = useState({});
  const [catalogFiles, setCatalogFiles] = useState({});
  const [currentDataGroup, setCurrentDataGroup] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [detectedDataGroup, setDetectedGroup] = useState({});
  const [useCases, setUseCases] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [usecaseSelected, setUsecaseSelected] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(-1);
  const [availableTags, setAvailableTags] = useState({});
  const [availableRules, setAvailableRules] = useState({});
  const [ruleDict, setRuleDict] = useState({});
  const [searchDetails, setSearchDetails] = useState({});
  const [quarantinedFiles, setQuarantinedFiles] = useState({});
  const [processingFile, setProcessingFile] = useState(-1);
  const [showConnectData, setShowConnectData] = useState(false);

  // TODO: change the current reference to DEFAULT_TAG parameter
  const [currentTag, setCurrentTag] = useState({
    name: "",
    tagType: "classification",
    description: "",
    availableValues: [],
    allow_other_values: false,
    reference_file: "",
    max_words: 1,
    examples: null,
    option: "aiGenerated",
    risk_level: "",
    risk_level_description: {
      low: "",
      medium: "",
      high: "",
    },
    type: "word",
  });
  const [currentProcessCount, setCurrentProcessCount] = useState(0);
  const [currentTotalProcessCount, setCurrentTotalProcessCount] = useState(0);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [startPoint, setStartPoint] = useState(0);
  const deleteTagsQueueRef = useRef(new Queue());
  const fetchCatalogQueueRef = useRef(new Queue({ maxTasks: 2 }));
  const [tagsToBeDeleted, setTagsToBeDeleted] = useState([]);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [view, setView] = useState("options");
  const [, setSelectedCatalogItems] = useAtom(selectedCatalogItemsAtom);

  const { failedTags, tagReRun, hasTagFailedFor, failedTagDeleted } =
    useFailedTags(currentDataGroup);

  const createCatalogMutation = useCreateCatalogMutation();

  const userProfile = useUserProfile();

  useEffect(() => {
    setSelectedCatalogItems(new Set());
  }, [setSelectedCatalogItems, usedCatalog]);

  useCatalogChangeSubscription({
    isLoading,
    activeCatalog: usedCatalog,
    onChange: () => {
      fetchCatalogQueueRef.current.addNewTask(async () => {
        await fetchInitialCatalog(usedCatalog);
      }, true);
    },
    onQuarantinedFileChange: (fileName, fileEntry) => {
      setQuarantinedFiles((prevQuarantinedFiles) => ({
        ...prevQuarantinedFiles,
        [fileName]: fileEntry,
      }));
    },
  });

  useEffect(() => {
    getRules(usedCatalog)
      .then((fetchedRules) => {
        const acc = {};
        const rulesToTagDict = fetchedRules.map((rule) => {
          const conditionsDescription = rule.rules
            .map(
              (condition) =>
                `if '${condition.tag_name}' equals '${condition.value}'`
            )
            .join(" and ");

          const ruleDict = {
            name: rule.output_tag,
            tagType: "rule",
            description: `Apply this tag on conditions: ${conditionsDescription}.`,
            availableValues: [],
            allow_other_values: false,
            reference_file: "",
            max_words: 1,
            examples: null,
            option: "aiGenerated",
            risk_level: "",
            risk_level_description: {
              low: "",
              medium: "",
              high: "",
            },
            type: "word",
          };

          acc[rule.output_tag] = ruleDict;
          return acc;
        });
        setRuleDict(acc);
      })
      .catch((error) => {
        console.error("Failed to fetch rules:", error);
      });
  }, [usedCatalog]);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        setPreferences(userProfile);
        setAvailableTags(userProfile.system.TAGGER_LIST);
        setUsedCatalog(userProfile.system.EXISTING_CATALOG);
        const hiddenTags = userProfile.hidden_tags.HIDDEN_TAGS;
        setHiddenCategories([...hiddenCategories, ...hiddenTags]);
      } catch (error) {
        console.error("Error fetching preferences:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPreferences();
  }, []);

  const handleLabelChange = async (itemKey, labelKey, newValue) => {
    const afterLabelChange = (prevState) => ({
      ...prevState,
      [itemKey]: {
        ...prevState[itemKey],
        [labelKey]: newValue,
      },
    });
    setCurrentDataGroup(afterLabelChange);
    setCatalogFiles(afterLabelChange);

    await updateCatalog(usedCatalog, afterLabelChange(catalogFiles));
    setModalOpen(false);
  };

  const editTag = (e, name) => {
    e.stopPropagation();

    const selectedTag = mergeTagWithDefaults(
      {
        ...availableTags.llm.tagger_params.tag_dict,
        ...availableTags.sensitivity.tagger_params.tag_dict,
      }[name],
      defaultCurrentTag
    );

    setCurrentTag(selectedTag);
    setShowScreen("addNewTag");
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
    setHiddenCategories([]);
    setSearchTerm("");
  };

  const handleLabelDelete = async (itemKey, labelKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the label "${labelKey}"?`
    );
    if (!confirmDelete) {
      return;
    }

    const afterLabelDelete = (prevState) => {
      const updatedItem = { ...prevState[itemKey] };
      delete updatedItem[labelKey];
      return { ...prevState, [itemKey]: updatedItem };
    };

    setCurrentDataGroup(afterLabelDelete);
    setCatalogFiles(afterLabelDelete);

    setModalOpen(false);
  };

  const deleteAllLabel = async (e, tagName) => {
    e.stopPropagation();
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the label "${tagName}"?`
    );

    if (!confirmDelete) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing tag ${tagName} to be deleted`,
    });

    setTagsToBeDeleted((prev) => [...prev, tagName]);

    try {
      deleteTagsQueueRef.current.addNewTask(async () => {
        const NEW_TAGGER_LIST = deleteLabelParentLabelObj(
          availableTags,
          tagName
        );

        const rawResponse = await sendRequest(
          {
            catalog: JSON.stringify(catalogFiles),
            [preferences.system.API_USERNAME_KEYWORD]: (
              await Auth.currentAuthenticatedUser()
            ).username,
            label: tagName,
            catalog_name: usedCatalog,
            new_tagger_list: JSON.stringify(NEW_TAGGER_LIST),
          },
          ENDPOINTS["delete_label"]
        );
        const response = await rawResponse.json();

        await new Promise((res, rej) => setTimeout(res, 5000));

        toast.success({
          title: "Success",
          description: `Tag ${tagName} successfully deleted`,
        });

        setAvailableTags(NEW_TAGGER_LIST);
        setTagsToBeDeleted((prev) => prev.filter((tag) => tag !== tagName));
        failedTagDeleted(tagName);

        return response;
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDatasetDelete = async (itemKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the dataset "${itemKey}"?`
    );
    toast.info({
      title: "Info",
      description: `Preparing dataset ${itemKey} to be deleted`,
    });
    if (!confirmDelete) {
      return;
    }

    try {
      const afterDataDelete = (prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[itemKey];
        return updatedState;
      };

      setCurrentDataGroup(afterDataDelete);
      setCatalogFiles(afterDataDelete);

      await updateCatalog(usedCatalog, afterDataDelete(catalogFiles));
      toast.success({
        title: "Success",
        description: `Successfully deleted dataset ${itemKey}`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error happened trying to delete your dataset ${itemKey}: ${String(
          error
        )}`,
      });
    }
  };

  const handleQuarantineDataDelete = async (itemKey) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the dataset "${itemKey}"?`
    );

    if (!confirmDelete) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing dataset ${itemKey} to be deleted`,
    });

    try {
      setQuarantinedFiles((prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[itemKey];
        return updatedState;
      });

      const updatedCatalog = afterDataDelete(quarantinedFiles);
      await updateCatalog(preferences.system.QUARANTINECATALOG, updatedCatalog);

      toast.success({
        title: "Success",
        description: `Successfully deleted dataset ${itemKey} from quarantine`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error happened trying to delete your dataset ${itemKey}: ${String(
          error
        )}`,
      });
    }
  };

  const afterDataDelete = (prevState, itemKey) => {
    const updatedState = { ...prevState };
    delete updatedState[itemKey];
    return updatedState;
  };

  const deleteMultipleQuarantine = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the quarantine?`
      )
    ) {
      return;
    }

    const newDataGroup = { ...quarantinedFiles };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      setCatalogFiles(newDataGroup);
      setCurrentDataGroup(newDataGroup);
      await updateCatalog(preferences.system.QUARANTINECATALOG, newDataGroup);

      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error
        )}`,
      });
    }
  };

  const deleteMultipleDatasets = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the catalog?`
      )
    ) {
      return;
    }

    const newDataGroup = { ...currentDataGroup };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      setCatalogFiles(newDataGroup);
      setCurrentDataGroup(newDataGroup);
      await updateCatalog(usedCatalog, newDataGroup);

      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error
        )}`,
      });
    }
  };

  const handleMultipleDelete = () => {
    const allKeys = Object.keys(currentDataGroup);
    deleteMultipleDatasets(allKeys);
    setSelectedFilters({});
  };

  const fetchInitialCatalog = async (catalog_name = null) => {
    try {
      const [SafeFiles, UnsafeFiles] = await Promise.all([
        sendRequest(
          {
            catalog_name: catalog_name,
            [preferences.system.API_USERNAME_KEYWORD]: (
              await Auth.currentAuthenticatedUser()
            ).username,
          },
          ENDPOINTS["get_catalog"]
        ).then((r) => r.json()),
        sendRequest(
          {
            catalog_name: preferences.system.QUARANTINECATALOG,
            [preferences.system.API_USERNAME_KEYWORD]: (
              await Auth.currentAuthenticatedUser()
            ).username,
          },
          ENDPOINTS["get_catalog"]
        ).then((r) => r.json()),
      ]);

      setCatalogFiles(SafeFiles.catalog);
      setCatalogSummary(SafeFiles.filter_map);
      setCurrentDataGroup(SafeFiles.catalog);
      setSearchDetails(SafeFiles.search_details);
      setQuarantinedFiles(UnsafeFiles.catalog);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateTagDict = (newTagDict) => {
    const cleanedTagDict = Object.keys(newTagDict)
      .filter((key) => !LABELS_TO_EXCLUDE_FOR_SEARCH.includes(key))
      .reduce((obj, key) => {
        obj[key] = availableTags.llm.tagger_params.tag_dict[key];
        return obj;
      }, {});

    setAvailableTags((prevState) => ({
      ...prevState,
      llm: {
        ...prevState.llm,
        tagger_params: {
          ...prevState.llm.tagger_params,
          tag_dict: cleanedTagDict,
        },
      },
    }));
  };

  const fetchInitialTaggerList = async (catalog_name = null) => {
    const rawResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: catalog_name || usedCatalog,
      },
      ENDPOINTS["get_tags"]
    );
    const response = await rawResponse.json();
    if (Object.keys(response.tags).length > 0) {
      let updatedTaggers = updateModelVersion(
        response.tags,
        preferences.webapp_profile.MODEL_USED,
        preferences.webapp_profile.PROVIDER_USED
      );

      // Manually including the 'Summary' tag
      if (!updatedTaggers.llm || !updatedTaggers.llm.tagger_params.tag_dict['Summary']) {
        updatedTaggers = {
          ...updatedTaggers,
          llm: {
            ...updatedTaggers.llm,
            tagger_params: {
              ...updatedTaggers.llm.tagger_params,
              tag_dict: {
                ...updatedTaggers.llm.tagger_params.tag_dict,
                'Summary': { 
                  name: "Summary",
                  tagType: "classification",
                  description: "A concise and factually correct summary of the content in the text",
                  availableValues: [],
                  allow_other_values: true,
                  reference_file: "",
                  max_words: 100,
                  // max_model_tokens: 16385,
                  examples: [],
                  option: "aiGenerated",
                  is_document_level: true,
                  risk_level: "Low",
                  risk_level_description: {
                      low: "",
                      medium: "",
                      high: "",
                  },
                  type: "word"
                }
              }
            }
          }
        };
      }
      setAvailableTags(updatedTaggers);
    } else {
      setAvailableTags(preferences.system.TAGGER_LIST);
    }
};

  const fetchInitialUsecases = async () => {
    try {
      const rawResponse = await sendRequest(
        {
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["get_all_usecases"]
      );
      const response = await rawResponse.json();
      setUseCases(response.usecases);
    } catch (error) {
      setUseCases({});
      console.error("Error fetching data:", error);
    }
  };

  const toggleCategoryVisibility = (e, category) => {
    e.stopPropagation();
    if (hiddenCategories.includes(category)) {
      setHiddenCategories(hiddenCategories.filter((c) => c !== category));
      toast.success({
        title: "Success",
        description: `Category ${category} successfully unhidden`,
      });
    } else {
      setHiddenCategories([...hiddenCategories, category]);
      toast.success({
        title: "Success",
        description: `Category ${category} successfully hidden`,
      });
    }
  };

  const handleCatalogChange = async (event) => {
    const value = event.target.value;
    if (value === "newCatalog") {
      const catalogName = prompt("Enter new catalog name:");
      if (catalogName) {
        createCatalogMutation.mutate(catalogName, {
          onSuccess: () => {
            setUsedCatalog(catalogName);
            toast.success({
              title: "Success",
              description: "Catalog created successfully!",
            });
          },
          onError: () => {
            toast.error({
              title: "Error",
              description: "Failed to create catalog.",
            });
          },
        });
      }
    } else {
      toast.info({
        title: "Info",
        description: "Loading catalog " + value,
      });
      setUsedCatalog(value);
    }
    await fetchInitialCatalog(value);
    await fetchInitialTaggerList(value);
    toast.success({
      title: "Success",
      description: `
      Successfully loaded datasets from ${value}`,
    });
  };

  const handleCatalogRename = async (oldName, newName) => {
    try {
      await renameCatalog(newName, oldName);
      setUsedCatalog(newName);
      await fetchInitialCatalog(newName);
      await fetchInitialTaggerList(newName);
      toast.success({
        title: "Success",
        description: `Successfully renamed to ${newName}`,
      });
    } catch (err) {
      setUsedCatalog(oldName);
      console.error("Failed...");
    }
  };

  const handleCatalogRenameByName = async (newName, oldName) => {
    try {
      await renameCatalog(newName, oldName);
      toast.success({
        title: "Success",
        description: `Successfully renamed to ${newName}`,
      });
    } catch (err) {
      toast.error("Failed to rename catalog.");
    }
  };

  if (isLoading) {
    return <div>Loading preferences...</div>;
  }

  return (
    <DataContext.Provider
      value={{
        // Getters
        isLoading,
        showScreen,
        usedCatalog,
        catalogSummary,
        dataGroups,
        catalogFiles,
        currentDataGroup,
        searchTerm,
        detectedDataGroup,
        useCases,
        usecaseSelected,
        isModalOpen,
        fileUploadProgress,
        availableTags,
        availableRules,
        searchDetails,
        quarantinedFiles,
        processingFile,
        currentTag,
        currentProcessCount,
        currentTotalProcessCount,
        dateRange,
        hiddenCategories,
        selectedFilters,
        preferences,
        startPoint,
        tagsToBeDeleted,
        failedTags,
        ruleDict,
        showFilePreview,
        view,
        showConnectData,

        // Setters
        setShowFilePreview,
        setShowConnectData,
        setView,
        setHiddenCategories,
        setStartPoint,
        setIsLoading,
        setShowScreen,
        setUsedCatalog,
        setCatalogSummary,
        setDataGroups,
        setCatalogFiles,
        setCurrentDataGroup,
        setSearchTerm,
        setDetectedGroup,
        setUseCases,
        setDateRange,
        setUsecaseSelected,
        setModalOpen,
        setFileUploadProgress,
        setAvailableTags,
        setAvailableRules,
        setSearchDetails,
        setQuarantinedFiles,
        setProcessingFile,
        setCurrentTag,
        setCurrentProcessCount,
        setCurrentTotalProcessCount,
        setSelectedFilters,
        setPreferences,
        tagReRun,
        hasTagFailedFor,
        // Functions
        handleLabelChange,
        editTag,
        handleLabelDelete,
        deleteAllLabel,
        handleDatasetDelete,
        updateTagDict,
        fetchInitialCatalog,
        fetchInitialTaggerList,
        fetchInitialUsecases,
        toggleCategoryVisibility,
        handleMultipleDelete,
        handleQuarantineDataDelete,
        deleteMultipleQuarantine,
        clearAllFilters,
        handleCatalogChange,
        handleCatalogRename,
        handleCatalogRenameByName,
        setRuleDict,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};