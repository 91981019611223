export const ENDPOINTS = {
  create_catalog: "/api/create_catalog/",
  create_catalog_in_bulk: "/api/create_catalog/bulk/",
  schedule_catalog_creation: "/api/schedule_catalog_creation/",
  schedule_periodic_catalog_creation:
    "/api/schedule_periodic_catalog_creation/",
  add_new_catalog: "/api/add_new_catalog/",
  delete_catalog: "/api/delete_catalog/",
  get_document_content: "/api/get_document_content/",
  get_catalog: "/api/get_catalog/",
  chat: "/api/chat/",
  fetch_files: "/api/fetch_files/",
  fetch_folders: "/api/fetch_folders/",
  get_catalog_summary: "/api/get_catalog_summary/",
  upload_catalog: "/api/upload_catalog/",
  rename_catalog: "/api/rename_catalog/",
  create_usecase: "/api/create_usecase/",
  get_all_usecases: "/api/get_all_usecases/",
  delete_usecase: "/api/delete_usecase/",
  get_usecase_history: "/api/get_usecase_history/",
  create_usecase_history: "/api/create_usecase_history/",
  rename_chat: "/api/rename_chat/",
  delete_usecase_history: "/api/delete_usecase_history/",
  delete_usecase_history_message: "/api/delete_usecase_history_message/",
  clear_usecase_history: "/api/clear_usecase_history/",
  delete_label: "/api/delete_label/",
  create_chunks: "/api/create_chunks/",
  get_tags: "/api/get_tags/",
  run_rule: "/api/run_rule/",
  get_rules: "/api/get_rules/",
  upload_tags: "/api/upload_tags/",
  upload_rules: "/api/upload_rules/",
  load_user_profile: "/api/load_user_profile/",
  save_user_profile: "/api/save_user_profile/",
  get_user_token_tracking: "/api/get_user_token_tracking/",
  get_standardize_tag_map: "/api/get_standardize_tag_map/",
  apply_standardize_tag_map: "/api/apply_standardize_tag_map/",
  check_chunk_sensitivity: "/api/check_chunk_sensitivity/",
  approve_quarantine_file: "/api/approve_quarantine_file/",
  archive_files: "/api/archive_files/",
  text_generation: "/api/text_generation/",
  export: "/api/export/",
  pubsub_token: "/api/pubsub_token/",
  get_teams: "/api/get_teams/",
  update_teams: "/api/update_teams/",
  get_task_status: "/api/workers/task-status/",
  revoke_task: "/api/workers/revoke-task/",
};
