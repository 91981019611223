import React, { useContext, useState } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import {
  addNewCatalog,
  deleteCatalog,
} from "../../../../../utilities/functions/apiCalls";
import { useCatalogNames } from "../../../../../../api/queryHooks";

type DataContextType = {
  catalogNames: string[];
  setCatalogNames: React.Dispatch<React.SetStateAction<string[]>>;
  handleCatalogRenameByName: (
    newName: string,
    oldName: string
  ) => Promise<void>;
};

export function CatalogComponent() {
  const { data: catalogNames = [] } = useCatalogNames();
  const { handleCatalogRenameByName } =
    useContext<DataContextType>(DataContext);
  const [catalogList, setCatalogList] = useState(catalogNames);
  const [newCatalogName, setNewCatalogName] = useState("");
  const [editingName, setEditingName] = useState<string | null>(null);
  const [editedName, setEditedName] = useState("");
  const [updatingCatalog, setUpdatingCatalog] = useState(false);

  const addCatalog = () => {
    if (newCatalogName && !catalogList.includes(newCatalogName)) {
      addNewCatalog(newCatalogName);
      const updatedCatalogs = [...catalogList, newCatalogName];
      setCatalogList(updatedCatalogs);
      setNewCatalogName("");
    }
  };

  const deleteFromCatalog = (name: string) => {
    const isConfirmed = window.confirm(
      `Deleting '${name}' will remove access to files in this catalog. Are you sure you want to proceed?`
    );
    if (isConfirmed) {
      deleteCatalog(name);
      const updatedCatalogs = catalogList.filter((catalog) => catalog !== name);
      setCatalogList(updatedCatalogs);
    }
  };

  const startEditing = (name: string) => {
    setEditedName(name);
    setEditingName(name);
  };

  const applyEdit = async (oldName: string) => {
    if (oldName === editedName || !editedName.trim()) {
      setEditingName(null);
      setEditedName("");
      return;
    }
    setUpdatingCatalog(true);
    try {
      await handleCatalogRenameByName(editedName, oldName);
      const updatedCatalogs = [...catalogList];
      const index = updatedCatalogs.indexOf(oldName);
      if (index !== -1) {
        updatedCatalogs[index] = editedName;
        setCatalogList(updatedCatalogs);
      }
      setEditingName(null);
      setEditedName("");
    } catch (error) {
      console.error("Failed to update catalog name:", error);
    }
    setUpdatingCatalog(false);
  };

  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <div className="flex flex-wrap gap-3 mb-5">
        {catalogList.map((name) => (
          <div
            key={name}
            className="flex flex-grow items-center justify-between bg-gray-200 p-3 rounded-lg min-w-0"
          >
            {editingName === name ? (
              updatingCatalog ? (
                <span className="text-gray-500">Updating...</span>
              ) : (
                <input
                  type="text"
                  className="form-input flex-grow border border-gray-300 rounded-lg shadow-sm p-3"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  onBlur={() => applyEdit(name)}
                  onKeyPress={(e) => e.key === "Enter" && applyEdit(name)}
                />
              )
            ) : (
              <span className="text-gray-800 truncate">{name}</span>
            )}
            <div className="flex items-center">
              {editingName !== name && !updatingCatalog && (
                <button
                  className="ml-2 hover:bg-blue-100 p-2"
                  onClick={() => startEditing(name)}
                >
                  ✎
                </button>
              )}
              {editingName !== name && !updatingCatalog && (
              <button
                className="ml-2 hover:bg-red-100 p-2"
                onClick={() => deleteFromCatalog(name)}
              >
                ✕
              </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center gap-3">
        <input
          type="text"
          className="form-input flex-grow border border-gray-300 rounded-lg shadow-sm p-3"
          placeholder="Add new catalog"
          value={newCatalogName}
          onChange={(e) => setNewCatalogName(e.target.value)}
        />
        <button
          className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg shadow"
          onClick={addCatalog}
        >
          Add
        </button>
      </div>
    </div>
  );
}
