import { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  faFile,
  faArrowLeft,
  faCog,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import "./NavigationBar.css";
import { debounce } from "lodash";
import Logo from "../../../assets/images/deasie.png";
import useLogout from "../functions/authentication";
import { sendRequest } from "../functions/api";
import { ENDPOINTS } from "../../../api/endpoints";
import Auth from "../../../auth/AuthProvider";
import { isFileContainedInArray, objectDeepCopy } from "../functions/utils";
import { DataContext } from "../../../context/DataContext";
import { UsecaseContext } from "../../../context/UsecaseContext";
import Export from "./Components/Export/Export";
import { TagContext } from "../../../context/TagContext";
import FolderList from "./FolderList";
import { useAtom } from "jotai";
import {
  darkModeAtom,
  documentsUploadTaskAtom,
  runningTasksAtom,
} from "../../../atoms";
import RunningTasksModal from "../RunningTasksModal";
import { TeamManager } from "./../TeamManager";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "../Toast";
import { SchedulingModalContent } from "../SchedulingModalContent";
import { useUserProfile } from "../../../context/UserProfile";
import { waitTaskDone } from "../../../utils/workers";
import AddTaggingRules from "../../pages/Home/HomeComponents/TaggingRules/TaggingRules";
import TagSelection from "./Components/TagSelection/TagSelection";

export default function NavigationBar() {
  const [showRunningTasksModal, setShowRunningTasksModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [isModalLoading, setModalLoading] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [schedulingOptions, setSchedulingOptions] = useState({});
  const [checkSensitivity, setCheckSensitivity] = useState(false);
  const [integration, setIntegration] = useState("s3");
  const [showTagsDropdown, setShowTagsDropdown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [_, setDocumentsUploadTask] = useAtom(documentsUploadTaskAtom);
  const [filteredFolderKeys, setFilteredFolderKeys] = useState([]);
  const [showExportLogoutDropdown, setShowExportLogoutDropdown] =
    useState(false);
  const [showExport, setShowExport] = useState(false);
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);
  const [isTeamManagerOpen, setIsTeamManagerOpen] = useState(false);
  const [createEmbeddings, setCreateEmbeddings] = useState(true);
  const [showTagOptions, setShowTagOptions] = useState(false);
  const [showRuleOptions, setShowRuleOptions] = useState(false);
  const [filePreviewContent, setFilePreviewContent] = useState(false);

  const {
    setShowScreen,
    showScreen,
    catalogSummary,
    catalogFiles,
    availableTags,
    quarantinedFiles,
    setCurrentTag,
    preferences,
    usedCatalog,
    setCurrentDataGroup,
    clearAllFilters,
    setSelectedFilters,
    showFilePreview,
    setShowFilePreview,
    view,
    setView,
    setShowConnectData,
    showConnectData,
    fetchInitialCatalog,
  } = useContext(DataContext);
  const { permissions } = useUserProfile();
  const location = useLocation();

  const { setIsActiveAction } = useContext(TagContext);

  const { setUsecaseStage } = useContext(UsecaseContext);

  const dropdownRef = useRef();

  const [selectedTags, setSelectedTags] = useState({});
  const [, setRunningTasks] = useAtom(runningTasksAtom);
  const navigate = useNavigate();
  const listContainerRef = useRef();

  useEffect(() => {
    const defaultTags = Object.keys(catalogSummary).reduce((acc, category) => {
      const availableTagsKeys = Object.keys({
        ...availableTags.llm.tagger_params.tag_dict,
      });
      if (availableTagsKeys.includes(category)) {
        acc[category] = true;
      }
      return acc;
    }, {});
    const handleClickOutside = (event) => {
      if (
        showTagsDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowTagsDropdown(false);
      }
    };

    if (showTagsDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    setSelectedTags(defaultTags);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goToUsecase = () => {
    setShowScreen("usecase");
    setUsecaseStage("menu");
    setCurrentDataGroup(catalogFiles);
    setSelectedFilters({});
  };

  const handleTagChange = (category) => {
    setSelectedTags((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const handleSelectAllToggle = (event, tagCategories) => {
    const newSelectedState = event.target.checked;
    const updatedTags = {};
    tagCategories.forEach((category) => {
      updatedTags[category] = newSelectedState;
    });

    setSelectedTags(updatedTags);
  };

  const closePreviewModal = () => {
    setShowFilePreview(false);
  };

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      const folderKeys = Object.keys(folders);
      setFilteredFolderKeys(
        folderKeys.filter(
          (folderKey) =>
            folderKey.toLowerCase().includes(searchText.toLowerCase()) ||
            folders[folderKey].some((file) =>
              file.toLowerCase().includes(searchText.toLowerCase())
            )
        )
      );
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchText, folders]);

  function PreviewModal({ isOpen, onClose, content }) {
    if (!isOpen) return null;

    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <button
            onClick={onClose}
            className="modal-close-button hover:bg-primary"
          >
            &times;
          </button>
          <div className="modal-body">{content}</div>
        </div>
      </div>
    );
  }
  const handleBreadcrumbClick = (index) => {
    setCurrentFolder(breadcrumbs[index]);
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };

  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      handleBreadcrumbClick(breadcrumbs.length - 2);
    } else if (breadcrumbs.length === 1) {
      setCurrentFolder(null);
      setBreadcrumbs([]);
    } else {
      if (view === "s3List") {
        setView("options");
      } else if (view === "tagSelection") {
        setView("taggingRules");
      } else if (view === "schedulingTagSelection") {
        setView("scheduling-s3List");
      } else if (view === "schedulingDatetime") {
        setView("schedulingTagSelection");
      } else if (view === "scheduling-s3List") {
        setView("scheduling");
      }
    }
  };

  const handleIntegrationClick = (integration, view = "options") => {
    if (view === "scheduling") {
      setView("scheduling-s3List");
    } else {
      setView("s3List");
    }
    fetchFolders(integration, view);
    setIntegration(integration);
  };

  const tagDocument = async () => {
    setIsActiveAction(true);

    const usedTags = {
      ...availableTags.llm.tagger_params.tag_dict,
    };

    const filteredLLMTagDict = {};
    const chooseSensitivity = checkSensitivity;

    Object.keys(usedTags).forEach((tag) => {
      if (selectedTags[tag]) {
        filteredLLMTagDict[tag] = usedTags[tag];
      }
    });

    const availableTagsCopy = objectDeepCopy(availableTags);
    availableTagsCopy.llm.tagger_params.tag_dict = filteredLLMTagDict;
    setShowConnectData(false);

    const entries = [];

    for (const file_name of Object.keys(checkedItems)) {
      if (
        !checkedItems[file_name].isChecked ||
        isFileContainedInArray(
          file_name,
          Object.keys({ ...catalogFiles, ...quarantinedFiles })
        )
      ) {
        continue;
      }

      const sendChunkObject = {
        data_store: JSON.stringify({
          ...preferences.webapp_profile.DATA_STORES[
            checkedItems[file_name].source
          ],
          path: `${
            preferences.webapp_profile.DATA_STORES[
              checkedItems[file_name].source
            ].base_path
          }${checkedItems[file_name].folder}${file_name}`,
        }),
        tagger_list: JSON.stringify(availableTagsCopy),
        file_catalog_entry: JSON.stringify({ [file_name]: {} }),
        catalog_name: usedCatalog,
        quarantine_name: preferences.system.QUARANTINECATALOG,
        check_sensitivity: chooseSensitivity,
        create_index: createEmbeddings,
      };

      entries.push(sendChunkObject);

      // TODO: Move catalog summary and search detail update to function that checks "Processed" for catalog and then calls APIs
      // setCatalogSummary(catalogResponse.new_catalog_summary);
      // setSearchDetails(catalogResponse.search_details);
    }

    const creds = (await Auth.currentAuthenticatedUser()).username;
    try {
      const res = await sendRequest(
        {
          entries,
          [preferences.system.API_USERNAME_KEYWORD]: creds,
        },
        ENDPOINTS["create_catalog_in_bulk"]
      );
      const { task_id } = await res.json();
      setDocumentsUploadTask(task_id);
      setRunningTasks((tasks) => [
        ...tasks,
        {
          id: task_id,
          process: "Uploading Catalog",
          description: `Uploading ${entries.length} documents from: "${usedCatalog}"`,
        },
      ]);
      waitTaskDone(task_id, creds).then(() => {
        setDocumentsUploadTask(null);
        setRunningTasks((tasks) => tasks.filter((task) => task.id !== task_id));
      });
    } catch (error) {
      console.log("Error during the request", error);
    } finally {
      setCheckedItems({});
      setView("options");
      setIsActiveAction(false);
      await fetchInitialCatalog(usedCatalog);
    }
  };

  const scheduleTagging = async () => {
    setIsActiveAction(true);

    const usedTags = {
      ...availableTags.llm.tagger_params.tag_dict,
    };

    const filteredLLMTagDict = {};
    const chooseSensitivity = checkSensitivity;

    Object.keys(usedTags).forEach((tag) => {
      if (selectedTags[tag]) {
        filteredLLMTagDict[tag] = usedTags[tag];
      }
    });

    const availableTagsCopy = objectDeepCopy(availableTags);
    availableTagsCopy.llm.tagger_params.tag_dict = filteredLLMTagDict;
    setShowConnectData(false);

    try {
      for (const file_name of Object.keys(checkedItems)) {
        if (
          !checkedItems[file_name].isChecked ||
          isFileContainedInArray(
            file_name,
            Object.keys({ ...quarantinedFiles })
          )
        ) {
          continue;
        }

        const creds = (await Auth.currentAuthenticatedUser()).username;

        let endpoint;
        let params;
        if (schedulingOptions.schedulingPeriod) {
          endpoint = ENDPOINTS["schedule_periodic_catalog_creation"];
          params = {
            schedule_at: schedulingOptions.scheduleAt.toISOString(),
            period: schedulingOptions.schedulingPeriod,
          };
        } else {
          endpoint = ENDPOINTS["schedule_catalog_creation"];
          params = {
            schedule_at: schedulingOptions.scheduleAt.toISOString(),
          };
        }
        const sendChunkObject = {
          ...params,
          data_store: JSON.stringify({
            ...preferences.webapp_profile.DATA_STORES[
              checkedItems[file_name].storage_type
            ],
            path: `${checkedItems[file_name].file_directory}/${file_name}`,
          }),
          tagger_list: JSON.stringify(availableTagsCopy),
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          file_catalog_entry: JSON.stringify({ [file_name]: {} }),
          catalog_name: usedCatalog,
          quarantine_name: preferences.system.QUARANTINECATALOG,
          check_sensitivity: chooseSensitivity,
        };

        setTimeout(() => {
          sendRequest(sendChunkObject, endpoint)
            .then(
              toast.success({
                title: `Labeling scheduling at ${schedulingOptions.scheduleAt.toLocaleDateString()}`,
                description: `File: ${file_name}`,
              })
            )
            .catch(() => {
              toast.error({
                title: `Labeling scheduled at ${schedulingOptions.scheduleAt.toLocaleDateString()} failed`,
                description: `File: ${file_name}`,
              });
            });
        }, 10);
      }
    } catch (error) {
      console.log("Error during the request", error);
    } finally {
      setCheckedItems({});
      setView("options");
      setSelectedTags({});
      setIsActiveAction(false);
      setSchedulingOptions({});
    }
  };

  const fetchFolders = async (integration, view = "options") => {
    let dataStore = preferences.webapp_profile.NEW_FILE_DATA_STORE;
    if (integration === "sharepoint") {
      dataStore = preferences.webapp_profile.SHAREPOINT_FILE_DATA_STORE;
    } else if (integration === "azureblob") {
      dataStore = preferences.webapp_profile.AZURE_BLOB_DATA_STORE;
    }
    setModalLoading(true);
    try {
      const folderResponse = await sendRequest(
        {
          data_store: JSON.stringify(dataStore),
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["fetch_folders"]
      );

      const retrievedFolders = await folderResponse.json();
      setFolders(retrievedFolders.folder_list);
    } catch (error) {
      console.error("Error during the request", error);
    } finally {
      setModalLoading(false);
      if (view === "scheduling") {
        setView("scheduling-s3List");
      } else {
        setView("s3List");
      }
    }
  };

  useEffect(() => {
    if (showConnectData) {
      const allTagsTrue = Object.keys({
        ...availableTags.llm.tagger_params.tag_dict,
      }).reduce((acc, category) => {
        acc[category] = true;
        return acc;
      }, {});

      setSelectedTags(allTagsTrue);
    }

    const handleClickOutside = (event) => {
      if (
        listContainerRef.current &&
        !listContainerRef.current.contains(event.target)
      ) {
        setCurrentFolder(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listContainerRef]);

  const LogoutButton = () => {
    const logout = useLogout();

    const handleLogoutClick = async (e) => {
      e.preventDefault();
      await logout();
      setShowExportLogoutDropdown(false);
      setShowTagsDropdown(false);
    };

    return (
      <div
        className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
        onClick={handleLogoutClick}
      >
        Logout
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-between bg-gradient-to-b bg-secondary w-full">
        <div className="flex items-center px-4 py-2 shrink-0 grow-0">
          <img src={Logo} className="h-[38px] m-1" alt="LogoImageNavBar" />
        </div>
        <div className="h-full flex w-full max-w-6xl">
          <button
            onClick={(e) => {
              setCurrentDataGroup(catalogFiles);
              clearAllFilters();
              setShowScreen("catalog");
              if (!e.shiftKey) navigate("/");
              else navigate("/catalog");
            }}
            className={`text-white h-full w-full whitespace-nowrap bg-primary text-xl border-t-4 transition-all ${
              showScreen === "catalog"
                ? "bg-opacity-100 border-white dark:border-b-zinc-600"
                : "border-transparent bg-opacity-40"
            }`}
          >
            Data Catalog
          </button>
          <button
            onClick={() => {
              setShowScreen("access");
              if (location.pathname.includes("/catalog")) navigate("/");
            }}
            className={`text-white h-full w-full whitespace-nowrap bg-primary text-xl border-t-4 transition-all ${
              showScreen === "access"
                ? "bg-opacity-100 border-white dark:border-b-zinc-600"
                : "border-transparent bg-opacity-70"
            }`}
          >
            Data Quarantine
          </button>
          {permissions.usecases.canView && (
            <button
              onClick={() => {
                goToUsecase();
                if (location.pathname.includes("/catalog")) navigate("/");
              }}
              className={`text-white h-full w-full whitespace-nowrap bg-primary text-xl border-t-4 transition-all ${
                showScreen === "usecase"
                  ? "bg-opacity-100 border-white dark:border-b-zinc-600"
                  : "border-transparent bg-opacity-40"
              }`}
            >
              Usecase Library
            </button>
          )}
        </div>

        <div className="flex">
          <div className="relative text-xl">
            <button
              onClick={() =>
                setShowExportLogoutDropdown((prevState) => !prevState)
              }
              className="navbar-button pl-1 pr-8 bg-opacity-40 text-2xl text-white flex justify-center w-full items-center h-full"
              title="Export / Logout"
            >
              <div className="inline-block transition-transform duration-300 hover:rotate-180">
                <FontAwesomeIcon icon={faCog} />
              </div>
            </button>
            {showExportLogoutDropdown && (
              <>
                <div
                  className="fixed inset-0 z-40"
                  onClick={() => setShowExportLogoutDropdown(false)}
                ></div>
                <div className="absolute right-0 mt-2 py-2 w-[20vw] bg-white rounded-md shadow-xl z-50 overflow-auto">
                  <div className="py-1">
                    {permissions.catalogs.canEdit && (
                      <a
                        className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                        onClick={() => {
                          setShowConnectData(true);
                          setShowExportLogoutDropdown(false);
                          setShowTagsDropdown(false);
                          setView("options");
                        }}
                      >
                        Connect to Datasource
                      </a>
                    )}
                    <div
                      className="dropdown-item px-4 py-2 text-xl text-gray-700 flex justify-between items-center cursor-pointer hover:bg-gray-100 transition ease-in-out duration-150"
                      onClick={() =>
                        setShowTagOptions((prevState) => !prevState)
                      }
                    >
                      Tag
                      <FontAwesomeIcon
                        icon={showTagOptions ? faChevronUp : faChevronDown}
                        className="ml-2"
                      />
                    </div>
                    {showTagOptions && (
                      <div className="pl-4">
                        {permissions.tags.canEdit && (
                          <a
                            className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                            onClick={() => {
                              setShowScreen("addNewTag");
                              setShowExportLogoutDropdown(false);
                              setShowTagsDropdown(false);
                              setCurrentTag({});
                              if (location.pathname.includes("/catalog"))
                                navigate("/");
                            }}
                          >
                            Add
                          </a>
                        )}
                        <a
                          className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                          onClick={() => {
                            setShowExport(true);
                            setShowExportLogoutDropdown(false);
                            setShowTagsDropdown(false);
                          }}
                        >
                          Export
                        </a>
                        <a
                          className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                          onClick={() => {
                            setShowConnectData(true);
                            setView("scheduling");
                            setShowExportLogoutDropdown(false);
                          }}
                        >
                          Schedule
                        </a>
                      </div>
                    )}
                  </div>
                  <a
                    onClick={() =>
                      setShowRuleOptions((prevState) => !prevState)
                    }
                    className="dropdown-item px-4 py-2 text-xl text-gray-700 flex justify-between items-center cursor-pointer hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    Rules
                    <FontAwesomeIcon
                      icon={showRuleOptions ? faChevronUp : faChevronDown}
                      className="ml-2"
                    />
                  </a>
                  {showRuleOptions && (
                    <div className="pl-4">
                      <p
                        className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                        onClick={() => {
                          setShowScreen("rules");
                          setShowExportLogoutDropdown(false);
                          setShowTagsDropdown(false);
                        }}
                      >
                        Static Rules
                      </p>
                      <p
                        className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                        onClick={() => {
                          setShowScreen("taggingRules");
                          setShowExportLogoutDropdown(false);
                          setShowTagsDropdown(false);
                        }}
                      >
                        Tagging Rules
                      </p>
                    </div>
                  )}

                  <a
                    className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                    onClick={() => {
                      setShowScreen("preferences");
                      setShowExportLogoutDropdown(false);
                      setShowTagsDropdown(false);
                      if (location.pathname.includes("/catalog")) navigate("/");
                    }}
                  >
                    Preferences
                  </a>

                  <a
                    className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                    onClick={() => {
                      setShowRunningTasksModal(true);
                      setShowExportLogoutDropdown(false);
                      setShowTagsDropdown(false);
                      setView("options");
                    }}
                  >
                    Background Tasks
                  </a>

                  {permissions.teams.canEdit && (
                    <a
                      className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                      onClick={() => setIsTeamManagerOpen(true)}
                    >
                      Manage teams
                    </a>
                  )}

                  <a
                    className="dropdown-item block px-4 py-2 text-xl text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
                    onClick={() => {
                      setShowExportLogoutDropdown(false);
                      setShowTagsDropdown(false);
                      const newTheme =
                        localStorage.getItem("theme") === "dark"
                          ? "light"
                          : "dark";
                      localStorage.setItem("theme", newTheme);
                      if (newTheme === "dark") {
                        setDarkMode(true);
                        return document.documentElement.classList.add("dark");
                      }
                      setDarkMode(false);
                      document.documentElement.classList.remove("dark");
                    }}
                  >
                    {darkMode ? "Light" : "Dark"} mode
                  </a>
                  <LogoutButton />
                </div>
              </>
            )}
          </div>
        </div>
        {showRunningTasksModal && (
          <div className="Modal z-50 backdrop-blur-md">
            <div className="ModalContent">
              <span
                className="Close"
                onClick={() => {
                  setShowRunningTasksModal(false);
                }}
              >
                &times;
              </span>
              <RunningTasksModal />
            </div>
          </div>
        )}

        {showConnectData && (
          <div className="Modal z-50 backdrop-blur-md">
            <div className="ModalContent">
              {isModalLoading ? (
                <div className="ModalLoading">Loading...</div>
              ) : (
                <>
                  <span
                    className="Close"
                    onClick={() => {
                      setShowConnectData(false);
                      setCurrentFolder(null);
                      setBreadcrumbs([]);
                    }}
                  >
                    &times;
                  </span>
                  <div className="ModalHeader">
                    {view === "options" && "Connect to Data Source"}
                    {view === "s3List" && "Select Datasets"}
                    {view === "scheduling-s3List" &&
                      "Select Datasets for scheduling"}
                    {view === "tagSelection" && "Select Tags to apply"}
                    {view === "scheduling" && "Select Documents for scheduling"}
                    {view === "schedulingTagSelection" &&
                      "Select Tags for scheduling"}
                    {view === "schedulingDatetime" && "Pick a date and time"}
                  </div>

                  {view === "schedulingDatetime" && (
                    <SchedulingModalContent onChange={setSchedulingOptions} />
                  )}
                  {view === "taggingRules" && <AddTaggingRules />}
                  {(view === "options" || view === "scheduling") && (
                    <div className="ModalOptions">
                      <div
                        className="Option"
                        onClick={() => handleIntegrationClick("s3", view)}
                      >
                        <FontAwesomeIcon
                          icon={faAmazon}
                          className="Icon AmazonIcon"
                        />
                        Connect to S3
                      </div>
                      <div
                        className="Option"
                        onClick={() =>
                          handleIntegrationClick("sharepoint", view)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faMicrosoft}
                          className="Icon SharePointIcon"
                        />
                        Connect to Sharepoint
                      </div>
                      <div
                        className="Option"
                        onClick={() =>
                          handleIntegrationClick("azureblob", view)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          className="Icon SharePointIcon"
                        />
                        Connect to Azure Blob
                      </div>
                      <div className="Option Disabled">
                        <FontAwesomeIcon
                          icon={faFile}
                          className="Icon LocalIcon"
                        />
                        Connect Locally
                      </div>
                    </div>
                  )}
                  {(view === "s3List" || view === "scheduling-s3List") && (
                    <div className="w-full h-full overflow-auto">
                      <div
                        className="h-full w-full overflow-hidden"
                        ref={listContainerRef}
                      >
                        <div className="w-full h-full overflow-hidden">
                          <FolderList
                            folders={folders}
                            currentFolder={currentFolder}
                            checkedItems={checkedItems}
                            searchText={searchText}
                            setCurrentFolder={setCurrentFolder}
                            setCheckedItems={setCheckedItems}
                            integration={integration}
                            setFilePreviewContent={setFilePreviewContent}
                            setShowFilePreview={setShowFilePreview}
                            filteredFolderKeys={filteredFolderKeys}
                            setSearchText={setSearchText}
                          />
                          <PreviewModal
                            isOpen={showFilePreview}
                            onClose={closePreviewModal}
                            content={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: filePreviewContent,
                                }}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {["tagSelection", "schedulingTagSelection"].includes(
                    view
                  ) && (
                    <div className="TagSelectionView">
                      <div className="FileListContainer" ref={listContainerRef}>
                        <TagSelection
                          checkSensitivity={checkSensitivity}
                          setCheckSensitivity={setCheckSensitivity}
                          handleTagChange={handleTagChange}
                          selectedTags={selectedTags}
                          handleSelectAllToggle={handleSelectAllToggle}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {["s3List", "scheduling-s3List"].includes(view) && (
                <div className="flex w-full justify-between items-center">
                  <button
                    onClick={handleBackClick}
                    className="cursor-pointer mt-4 bg-grey hover:bg-grey text-white font-bold py-2 px-4 rounded"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>{Object.keys(checkedItems).length} Files selected</p>
                  <button
                    className="RefreshButton"
                    onClick={() => {
                      if (view === "scheduling-s3List") {
                        setView("schedulingTagSelection");
                        return;
                      }
                      setView("taggingRules");
                    }}
                    disabled={Object.keys(checkedItems).length === 0}
                  >
                    Choose tags to apply
                  </button>
                </div>
              )}
              {view === "tagSelection" && (
                <div className="flex w-full justify-between items-center">
                  <button
                    onClick={handleBackClick}
                    className="cursor-pointer mt-4 bg-grey hover:bg-grey text-white font-bold py-2 px-4 rounded"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>
                    {Object.values(selectedTags).filter(Boolean).length} Tags
                    selected
                  </p>
                  <button className="RefreshButton" onClick={tagDocument}>
                    Continue
                  </button>
                </div>
              )}
              {view === "schedulingTagSelection" && (
                <div className="flex w-full justify-between items-center">
                  <button onClick={handleBackClick} className="BackButton">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>
                    {Object.values(selectedTags).filter(Boolean).length} Tags
                    selected
                  </p>
                  <button
                    className="RefreshButton"
                    onClick={() => setView("schedulingDatetime")}
                  >
                    Choose date and time
                  </button>
                </div>
              )}
              {view === "schedulingDatetime" && (
                <div className="flex w-full justify-between items-center">
                  <button
                    onClick={handleBackClick}
                    className="cursor-pointer mt-4 bg-grey hover:bg-grey text-white font-bold py-2 px-4 rounded"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                  </button>
                  <p>
                    {Object.values(selectedTags).filter(Boolean).length} Tags
                    selected
                  </p>
                  <button
                    disabled={!schedulingOptions.scheduleAt}
                    className="RefreshButton"
                    onClick={scheduleTagging}
                  >
                    Schedule labeling your data
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <PreviewModal
          isOpen={showExport}
          onClose={() => setShowExport(false)}
          content={<Export onConfirm={""} />}
        />
        {isTeamManagerOpen && permissions.teams.canEdit && (
          <TeamManager onClose={() => setIsTeamManagerOpen(false)} />
        )}
      </div>
    </>
  );
}
