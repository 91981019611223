// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

[data-amplify-authenticator] {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.amplify-input amplify-field-group__control {
  height: 100%;
}

#radix-\\:r0\\:-content-0 {
  padding: 4vw;
}

[data-amplify-authenticator] input {
  border: 1px solid #ccc;
}

[data-amplify-authenticator] button {
  background-color: #28a490;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; 
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".App {\n  text-align: center;\n}\n\n[data-amplify-authenticator] {\n  background-color: #f0f0f0;\n  border-radius: 8px;\n  padding: 20px;\n  display: flex;\n  height: 100vh;\n  width: 100vw;\n  align-items: center;\n  justify-content: center;\n}\n\n.amplify-input amplify-field-group__control {\n  height: 100%;\n}\n\n#radix-\\:r0\\:-content-0 {\n  padding: 4vw;\n}\n\n[data-amplify-authenticator] input {\n  border: 1px solid #ccc;\n}\n\n[data-amplify-authenticator] button {\n  background-color: #28a490;\n  color: white;\n  padding: 10px 20px;\n  border-radius: 5px;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.hide-scrollbar {\n  scrollbar-width: none;\n  -ms-overflow-style: none; \n}\n\n.hide-scrollbar::-webkit-scrollbar {\n  display: none; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
