import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faLock } from "@fortawesome/free-solid-svg-icons";
import "./QuarantineList.css";
import Modal from "../../../../../../utilities/Modal/Modal";
import { fetchDocumentContent } from "../../../../../../utilities/functions/apiCalls";
import Auth from "../../../../../../../auth/AuthProvider";
import { ENDPOINTS } from "../../../../../../../api/endpoints";
import { sendRequest } from "../../../../../../utilities/functions/api";
import QuarantineListItem from "./Components/QuarantineListItem/QuarantineListItem";
import { getRandomColor } from "../../../../../../utilities/functions/utils";
import { DataContext } from "../../../../../../../context/DataContext";
import { toast } from "../../../../../../utilities/Toast";
import { useUserProfile } from "../../../../../../../context/UserProfile";

export default function QuarantineList({ ...props }) {
  const [modalContent, setModalContent] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const {
    quarantinedFiles,
    setQuarantinedFiles,
    catalogFiles,
    setCatalogFiles,
    setCatalogSummary,
    deleteMultipleQuarantine,
    preferences,
    usedCatalog,
    setShowScreen,
  } = useContext(DataContext);
  const { permissions } = useUserProfile();

  const handleSearchChange = (e) => {
    props.setSearchTerm(e.target.value);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent("");
    props.setSearchText("");
  };

  const approveFile = async (fileNames) => {
    const confirmApprove = window.confirm(`Are you sure you want to approve?`);
    if (!confirmApprove) {
      return;
    }
    const creds = (await Auth.currentAuthenticatedUser()).username;

    try {
      toast.info({
        title: "Info",
        description: "Preparing your document to be approved",
      });
      const rawApprovalResponse = await sendRequest(
        {
          catalog_name: usedCatalog,
          quarantine_name: preferences.system.QUARANTINECATALOG,
          quarantined_files: JSON.stringify(quarantinedFiles),
          catalog_files: JSON.stringify(catalogFiles),
          file_names: fileNames,
          [preferences.system.API_USERNAME_KEYWORD]: creds,
        },
        ENDPOINTS["approve_quarantine_file"]
      );

      const approvalResponse = await rawApprovalResponse.json();
      setCatalogFiles(approvalResponse.new_catalog);
      setQuarantinedFiles(approvalResponse.new_quarantine);
      setCatalogSummary(approvalResponse.new_catalog_summary);
      toast.success({
        title: "Success",
        description: "Document successfully approved",
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error has ocurred trying to approve your document ${String(
          error
        )}`,
      });
    }
  };

  const getDocumentContent = async (
    doc,
    chunk = ["none"],
    chunkEntries = []
  ) => {
    setSelectedName(doc);
    setModalOpen(true);

    if (chunk[0] !== "none") {
      chunk = chunk.split("_").map((chunk) => parseInt(chunk));
    }

    setModalContent("Loading...");

    const documentText = await fetchDocumentContent(
      quarantinedFiles[doc].file_directory + "/" + doc,
      preferences.webapp_profile.DATA_STORES,
      chunk,
      false,
      quarantinedFiles[doc].storage_type
    );
    setModalContent(documentText);

    if (chunkEntries.length > 0) {
      const evidenceList = chunkEntries.map((entry) => ({
        evidence: entry.evidence,
        name: entry.key || "Default Name",
        reason: entry.reason,
        color: getRandomColor(),
      }));
      props.setSearchText(evidenceList);
    }
  };
  return (
    <div className="overflow-hidden flex flex-col bg-white rounded-md border w-full dark:border-zinc-500">
      <div className="flex flex-col w-full">
        <h2 className="p-4 rounded-t-md bg-slate-200">Quarantined Files</h2>
        <div className="w-full flex">
          <p className="p-4 max-w-lg text-sm">
            Below is a list of files that have been quarantined due to
            sensitivity concerns. Review the files, adjust access permissions,
            approve their release with current permission or delete them from
            Deasie due their sensitive nature.
          </p>
        </div>
        <div className="flex p-4">
          <input
            type="text"
            placeholder="Search files..."
            value={props.searchTerm}
            onChange={handleSearchChange}
            className="border px-4 py-2 w-full outline-none rounded-l-md"
          />
          <select
            value={props.sensitivityFilter}
            onChange={(e) => props.setSensitivityFilter(e.target.value)}
            className="text-xs w-36 truncate shrink-0 grow-0 border-r border-t border-b px-2"
          >
            <option value="">Sensitivity Tags</option>
            {props.availableTags.sensitivity &&
              Object.keys(
                props.availableTags.sensitivity.tagger_params.tag_dict
              ).map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
          </select>
          <select
            value={props.riskFilter}
            onChange={(e) => props.setRiskFilter(e.target.value)}
            className="text-xs w-36 truncate shrink-0 grow-0 border-r border-t border-b px-2"
          >
            <option value="">Risk Tags</option>
            {preferences.webapp_profile.RISK_LEVELS.map((tag) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </select>
          <select
            value={props.reviewFilter}
            onChange={(e) => props.setReviewFilter(e.target.value)}
            className="text-xs w-36 truncate shrink-0 grow-0 border-r border-t border-b px-2"
          >
            <option value="">Review tags</option>
            <option value={true}>Reviewed</option>
            <option value={false}>Not reviewed</option>
          </select>
          {permissions.catalogs.canEdit && (
            <button
              className="bg-primary text-white px-4 hover:opacity-80 transition-all py-2 whitespace-nowrap"
              onClick={() => approveFile(Object.keys(props.filteredFiles))}
            >
              <FontAwesomeIcon icon={faThumbsUp} /> Approve{" "}
              {props.filteredFiles
                ? Object.keys(props.filteredFiles).length
                : "All"}
            </button>
          )}
          {permissions.catalogs.canEdit && (
            <button
              className="bg-red-400 text-white px-4 hover:opacity-80 transition-all py-2 whitespace-nowrap rounded-r-md"
              onClick={() =>
                deleteMultipleQuarantine(
                  props.filteredFiles ? Object.keys(props.filteredFiles) : ""
                )
              }
            >
              <FontAwesomeIcon icon={faLock} /> Delete{" "}
              {props.filteredFiles
                ? Object.keys(props.filteredFiles).length
                : "All"}
            </button>
          )}
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="flex flex-col overflow-auto h-full">
          {props.filteredFiles && Object.keys(props.filteredFiles).length ? (
            Object.entries(props.filteredFiles).map(
              ([fileName, details], index) => (
                <QuarantineListItem
                  key={fileName}
                  index={index}
                  fileName={fileName}
                  details={details}
                  updateAccessGroup={setQuarantinedFiles}
                  getDocumentContent={getDocumentContent}
                  approveFile={() => approveFile([fileName])}
                  setRiskFilter={props.setRiskFilter}
                  setFilteredFiles={props.setFilteredFiles}
                  isScanning={isScanning}
                  setIsScanning={setIsScanning}
                />
              )
            )
          ) : (
            // TODO: Here we could actually directly add a button to check the sensitivity of the data
            <div className="flex flex-col items-center justify-center h-[60vh] w-full text-center">
              <p className="text-lg text-gray-600 px-4 py-2">
                <svg
                  className="mx-auto mb-2 h-8 w-8 text-gray-500"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                No data in quarantine
              </p>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={selectedName}
        searchText={props.searchText}
        children={modalContent}
        setSearchText={props.setSearchText}
        redactions={props.redactions}
        setRedactions={props.setRedactions}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
