import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { fetchUserTokenUsage } from "../../../../../utilities/functions/apiCalls";
import { format, startOfMonth } from "date-fns";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";

type TokenModelCost = {
  name: string;
  cost: number;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
};

type TokenEntry = {
  date: string;
  model_costs: TokenModelCost[];
};

type ApiResponse = {
  token_list: {
    token_list: TokenEntry[];
    total_cost: number;
    total_token_cost: number;
  };
};

type ChartDataEntry = {
  date: string;
  models: TokenModelCost[];
  totalCost: number;
  promptTokens: number;
  completionTokens: number;
  totalTokens: number;
};

type CustomTooltipProps = TooltipProps<number, string> & {
  payload?: { payload: ChartDataEntry }[];
};

export function TokenUsageComponent() {
  const { preferences } = useContext(DataContext);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [tokenUsageData, setTokenUsageData] = useState<ApiResponse>();
  const [chartData, setChartData] = useState<ChartDataEntry[]>();

  const fetchTokenUsage = async () => {
    const start = formatDateInputValue(startDate);
    const end = formatDateInputValue(endDate);
    try {
      const response = await fetchUserTokenUsage(start, end);
      setTokenUsageData(response);
      const transformedData = transformDataForGraph(response);
      setChartData(transformedData);
    } catch (error) {
      console.error("Failed to fetch token usage data:", error);
    }
  };

  const transformDataForGraph = (
    apiResponse: ApiResponse
  ): ChartDataEntry[] => {
    const result = apiResponse.token_list.token_list.reduce(
      (groupedByDate, entry) => {
        if (!groupedByDate[entry.date]) {
          groupedByDate[entry.date] = {
            date: entry.date,
            models: [],
            totalCost: 0,
            promptTokens: 0,
            completionTokens: 0,
            totalTokens: 0,
          };
        }

        entry.model_costs.forEach((model) => {
          groupedByDate[entry.date].models.push(model);
          groupedByDate[entry.date].totalCost += Number(model.cost || 0);
          groupedByDate[entry.date].promptTokens += Number(model.prompt_tokens);
          groupedByDate[entry.date].completionTokens += Number(model.completion_tokens);
          groupedByDate[entry.date].totalTokens += Number(model.total_tokens);
        });

        return groupedByDate;
      },
      {} as { [key: string]: ChartDataEntry }
    );

    return Object.values(result);
  };

  const formatDateInputValue = (date: Date): string =>
    format(date, "yyyy-MM-dd");

  useEffect(() => {
    setChartData(undefined);
    fetchTokenUsage();
  }, [startDate, endDate]);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedDate = new Date(event.target.value);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    setEndDate(selectedDate);
  };

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      const formatCurrency = (value: any) => {
        let numberValue = Number(value);
    
        if (Number.isFinite(numberValue)) {
          return `$${numberValue.toFixed(2)}`;
        } else {
          console.error('Expected a number or a numeric string, but received:', value);
          return `$0.00`;
        }
      };

      const formatNumber = (value: any) => {
        let numberValue = Number(value);
    
        if (Number.isFinite(numberValue)) {
          return numberValue.toLocaleString();
        } else {
          console.error('Expected a number or a numeric string, but received:', value);
          return `0`;
        }
      };

      const totals = data.models.reduce(
        (acc: ChartDataEntry, model: TokenModelCost) => {
          acc.promptTokens += Number(model.prompt_tokens);
          acc.completionTokens += Number(model.completion_tokens);
          acc.totalTokens += Number(model.total_tokens);
          acc.totalCost += Number(model.cost);
          return acc;
        },
        { promptTokens: 0, completionTokens: 0, totalTokens: 0, totalCost: 0 }
      );

      return (
        <div className="bg-white p-2 border border-gray-300 shadow-lg rounded">
          <p className="font-medium text-base">{`Date: ${label}`}</p>
          <table className="min-w-full divide-y divide-gray-200 mt-2">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Model
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Prompt Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Completion Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Cost
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.models.map((model: TokenModelCost, index: number) => (
                <tr key={index}>
                  <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                    {model.name}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                    {formatNumber(model.prompt_tokens)}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                    {formatNumber(model.completion_tokens)}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                    {formatNumber(model.total_tokens)}
                  </td>
                  <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                    {formatCurrency(model.cost)}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-100">
                <td className="px-2 py-1 font-medium text-gray-800">Totals</td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(totals.promptTokens)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(totals.completionTokens)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(totals.totalTokens)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatCurrency(totals.totalCost)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return null;
  };

  const NoDataComponent: React.FC = () => (
    <ResponsiveContainer width="100%" height="100%">
      <svg>
        <rect width="100%" height="100%" fill="#f9f9f9" />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="central"
          fill="#a9a9a9"
          fontSize="16"
        >
          No Data Available
        </text>
        <text
          x="50%"
          y="50%"
          dy="20"
          textAnchor="middle"
          dominantBaseline="central"
          fill="#a9a9a9"
          fontSize="12"
        >
          Please select a different date range.
        </text>
      </svg>
    </ResponsiveContainer>
  );

  interface GraphProps {
    data: ChartDataEntry[];
  }

  const CostUsageGraph: React.FC<GraphProps> = ({ data }) => {
    if (!data || data.length === 0) {
      return <NoDataComponent />;
    }

    const dollarFormatter = (value: any) => {
      let numberValue = Number(value);
  
      if (Number.isFinite(numberValue)) {
        return `$${numberValue.toFixed(2)}`;
      } else {
        console.error('Expected a number or a numeric string, but received:', value);
        return `$0.00`;
      }
    };

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis tickFormatter={dollarFormatter} />
          <Tooltip formatter={(value: any) => dollarFormatter(value)} />
          <Legend />
          <Bar dataKey="totalCost" fill="#82ca9d" name="Token Cost" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const TokenUsageGraph: React.FC<GraphProps> = ({ data }) => {
    if (!data || data.length === 0) {
      return <NoDataComponent />;
    }

    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            yAxisId="left"
            dataKey="promptTokens"
            fill="#8884d8"
            name="Prompt Tokens"
            stackId="a"
          />
          <Bar
            yAxisId="left"
            dataKey="completionTokens"
            fill="#82ca9d"
            name="Completion Tokens"
            stackId="a"
          />
          <Line
            yAxisId="right"
            type="monotone"
            dataKey="totalCost"
            stroke="#ff7300"
            name="Total Cost ($)"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  if (!preferences) {
    return <div>Loading preferences...</div>;
  }

  return (
    <div className="p-3">
      <div className="flex flex-col md:flex-row justify-center items-center gap-6 mt-4">
        <div>
          <label className="block text-gray-800 font-medium mb-1">Start:</label>
          <input
            type="date"
            className="form-input mt-1 block w-full border-2 border-green-500 rounded-lg shadow-lg focus:border-green-700 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(startDate)}
            onChange={handleStartDateChange}
            max={formatDateInputValue(endDate)}
          />
        </div>
        <div>
          <label className="block text-gray-800 font-medium mb-1">End:</label>
          <input
            type="date"
            className="form-input mt-1 block w-full border-2 border-green-500 rounded-lg shadow-lg focus:border-green-700 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
            value={formatDateInputValue(endDate)}
            onChange={handleEndDateChange}
            min={formatDateInputValue(startDate)}
            max={formatDateInputValue(new Date())}
          />
        </div>
      </div>
      {chartData ? (
        <div className="space-y-6">
          <div className="shadow-lg rounded-lg overflow-hidden">
            <TokenUsageGraph data={chartData} />
          </div>
          <div className="shadow-lg rounded-lg overflow-hidden">
            <CostUsageGraph data={chartData} />
          </div>
          {tokenUsageData && (
            <div className="bg-white p-4 shadow-lg rounded-lg">
              <div className="text-center space-y-2">
                <span className="text-green-600 text-xl font-semibold">
                  Total Spend: ${tokenUsageData.token_list.total_cost} USD
                </span>
                <br />
                <span className="text-gray-700 text-lg">
                  Token Usage: {tokenUsageData.token_list.total_token_cost}{" "}
                  Tokens
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="text-gray-500 text-lg text-center py-10">
          Loading chart...
        </div>
      )}
    </div>
  );
}
