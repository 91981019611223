import React from "react";
interface StandardizationOptionProps {
  setStandardSensitivity: (value: number) => void;
  standardizeSensitivity: number;
}
const StandardizationOption = (props: StandardizationOptionProps) => {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setStandardSensitivity(Number(event.target.value));
  };

  return (
    <div>
      <input
        type="range"
        min={0}
        max={1}
        step={0.1}
        value={props.standardizeSensitivity}
        onChange={handleSliderChange}
        className="hover:cursor-pointer"
      />
      <p>Similarity threshold: {props.standardizeSensitivity}</p>
    </div>
  );
};

export default StandardizationOption;
