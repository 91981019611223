import twExtend from "../../../twExtend";
import "./PieChart.css";
import { PieChart } from "react-minimal-pie-chart";
export default function ProgressPie({ percentage }) {
  const sanitizedPercentage =
    isNaN(percentage) || typeof percentage !== "number" ? 0 : percentage;
  const displayValue = sanitizedPercentage.toFixed(0); // Rounds the percentage

  return (
    <div className="">
      <PieChart
        data={[
          {
            title: "Tagged",
            value: sanitizedPercentage,
            color: twExtend.colors.primary,
          },
          {
            title: "Untagged",
            value: 100 - sanitizedPercentage,
            color: "#e0e0e0",
          },
        ]}
        animate
        animationDuration={1000}
        animationEasing="ease-out"
        lineWidth={15} // Adjust the thickness of the pie chart line
        rounded // This makes the chart segments look rounded
        paddingAngle={5} // Adds space between segments
        totalValue={100}
        startAngle={270}
        label={({ dataEntry }) =>
          dataEntry.value > 0 ? `${displayValue}%` : ""
        }
        labelStyle={{
          fontSize: "25px", // Adjust label font size
          fontWeight: "600", // Font weight for the label
          fill: "#28A490", // Font color
        }}
        labelPosition={0}
      />
    </div>
  );
}
