import React, { useContext, useState, useEffect } from "react";
import "./Message.css";
import assistantIcon from "../../../../../../../assets/images/deasie-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCopy,
  faTrash,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../../../../../../../context/DataContext";
import { fetchDocumentContent } from "../../../../../../utilities/functions/apiCalls";

function Message({ message, handleDeleteMessage }) {
  const [showEvidence, setShowEvidence] = useState({});
  const [imageEvidence, setImageEvidence] = useState("");

  const { catalogFiles, usecaseSelected, preferences } =
    useContext(DataContext);

  const toggleEvidence = async (evidenceId, imageName) => {
    setShowEvidence((prevShowEvidence) => {
      const newShowEvidence = {
        ...prevShowEvidence,
        [evidenceId]: !prevShowEvidence[evidenceId],
      };
      return newShowEvidence;
    });

    if (!imageEvidence[evidenceId] && imageName) {
      const content = await fetchImageContent(imageName);
      const imageSrc = `data:image/png;base64,${content}`;

      setImageEvidence((prev) => {
        const newImageEvidence = { ...prev, [evidenceId]: imageSrc };
        return newImageEvidence;
      });
    }
  };

  const fetchImageContent = async (imageName) => {
    const fileName = Object.keys(catalogFiles).find((key) =>
      imageName.includes(key.replace(/\.\w+$/, ""))
    );

    if (!fileName) {
      console.error("File name not found in catalog for image:", imageName);
      return;
    }

    const folderName = imageName.includes("table") ? "tables" : "infographics";

    const path = `vector-store/${fileName}/${folderName}/${imageName}`;
    try {
      const imageContent = await fetchDocumentContent(
        path,
        preferences.webapp_profile.DATA_STORES,
        ["none"],
        false,
        catalogFiles[fileName].storage_type
      );
      return imageContent;
    } catch (error) {
      console.error("Error fetching image content:", error);
    }
  };

  if (message.type === "user") {
    return (
      <div className="Message user">
        <div className="MessageIconContainer">
          <FontAwesomeIcon icon={faUser} className="MessageIcon" />
        </div>
        <p
          className="MessageText"
          dangerouslySetInnerHTML={{ __html: message.text }}
        ></p>
        <div className="MessageActions">
          <button
            className="DeleteMessageButton"
            onClick={() => handleDeleteMessage(message.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    );
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(message.text, "text/html");

  const sections = Array.from(doc.querySelectorAll("section:not(:last-child)"))
    .map((section, sectionIndex) => {
      const subQuestion =
        section.querySelector("[data-sub-question]")?.textContent || "";
      const answer = section.querySelector("[data-answer]")?.textContent || "";
      const sourceEvidences = section.querySelectorAll(
        "[data-source-evidence]"
      );

      const formatExtractedText = (extractedText) => {
        const splitIndex = extractedText.indexOf(":");
        if (splitIndex === -1) {
          // No colon found, return the original text
          return { label: extractedText, content: "" };
        }
        // Split the text into two parts and trim the start of the content
        const label = extractedText.substring(0, splitIndex);
        const content = extractedText.substring(splitIndex + 1).trimStart();
        return { label, content };
      };

      if (!subQuestion.trim() || !answer.trim()) return null;

      return (
        <div key={`section-${sectionIndex}`} className="Section">
          <strong>{subQuestion}</strong>
          <pre> {answer}</pre>
          {Array.from(sourceEvidences).map((evidence, evidenceIndex) => {
            const evidenceId = `evidence-${sectionIndex}-${evidenceIndex}`;
            const pattern =
              /Source Evidence \((Page.*(?:table|infographic).*?\.png)\)/i;
            const match = evidence.textContent.match(pattern);
            const extractedText = match ? match[1] : evidence.textContent;
            const { label, content } = formatExtractedText(extractedText);
            return (
              <div key={evidenceId}>
                <button
                  className="EvidenceToggle"
                  onClick={() => toggleEvidence(evidenceId, extractedText)}
                >
                  {showEvidence[evidenceId] ? (
                    <FontAwesomeIcon icon={faCaretDown} />
                  ) : (
                    <FontAwesomeIcon icon={faCaretRight} />
                  )}
                  <span>Source Evidence</span>
                </button>
                {showEvidence[evidenceId] && (
                  <div className="m-5">
                    {match ? (
                      <img
                        src={imageEvidence[evidenceId]}
                        className="w-full h-full"
                        alt={`Evidence ${extractedText}`}
                      />
                    ) : (
                      <>
                        <span className="font-serif text-base font-normal tracking-normal leading-normal mt-10">
                          {label}:
                        </span>
                        <pre className="font-mono bg-gray-100 p-4 rounded">
                          {content}
                        </pre>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    })
    .filter(Boolean);

  const finalAnswerSection = doc.querySelector("section:last-child");
  const finalAnswer = finalAnswerSection
    ? finalAnswerSection.querySelector("[data-final-answer]")?.textContent
    : "";

  return (
    <div className="Message assistant">
      <div className="MessageIconContainer">
        <img src={assistantIcon} alt="Assistant" className="MessageLogo" />
      </div>
      <div className="MessageContent">
        {sections}
        {finalAnswer && (
          <div className="FinalAnswer">
            <strong>Final Answer:</strong> <pre> {finalAnswer}</pre>
          </div>
        )}
      </div>
      <div className="MessageActions">
        <button
          className="DeleteMessageButton m-3"
          onClick={() => handleDeleteMessage(message.id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
}

export default Message;
