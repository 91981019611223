import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Auth from "../auth/AuthProvider";
import { sendRequest } from "../components/utilities/functions/api";
import { ENDPOINTS } from "./endpoints";
import { AvailableCatalogsResponse } from "./types";

export const useCatalogNames = () =>
  useQuery({
    queryKey: ["catalogNames"],
    queryFn: async () => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest({ username }, "/api/catalog", "GET")
        .then((res) => res.json())
        .then((res: { data: AvailableCatalogsResponse }) => {
          const catalogNames = res.data.catalogs;
          return catalogNames;
        });
    },
  });

export const useCreateCatalogMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (catalogName: string) => {
      const username = (await Auth.currentAuthenticatedUser()).username;
      return sendRequest(
        { username, catalog_name: catalogName },
        ENDPOINTS["add_new_catalog"],
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["catalogNames"] });
    },
  });
};
