// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamManagerLoadingIndicator {
  background-color: white;
  position: absolute;
  top: 30%;
  right: 50%;
  border-radius: 4px;
  height: 450px;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.TeamManagerContainer {
  background-color: white;
  position: absolute;
  top: 30%;
  right: 50%;
  border-radius: 4px;
}
.TeamManagerContainer > h4 {
  margin: auto;
  padding: 10px;
  background-color: #f5f5f5;
  text-align: center;
}

.TeamManagerFooter {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.TeamManagerFooter > button[type="submit"] {
  background-color: rgb(40 164 144 / 1)
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/TeamManager/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;AAErB;AACA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE;AACF","sourcesContent":[".TeamManagerLoadingIndicator {\n  background-color: white;\n  position: absolute;\n  top: 30%;\n  right: 50%;\n  border-radius: 4px;\n  height: 450px;\n  width: 450px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n}\n.TeamManagerContainer {\n  background-color: white;\n  position: absolute;\n  top: 30%;\n  right: 50%;\n  border-radius: 4px;\n}\n.TeamManagerContainer > h4 {\n  margin: auto;\n  padding: 10px;\n  background-color: #f5f5f5;\n  text-align: center;\n}\n\n.TeamManagerFooter {\n  padding: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.TeamManagerFooter > button[type=\"submit\"] {\n  background-color: rgb(40 164 144 / 1)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
