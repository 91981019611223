// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterTableDataList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.DataContainers {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.DataDiscoveryContainer,
.DataSummaryContainer {
  display: flex;
  height: 100%;
  width: 48%;
  flex-direction: column;
}

.LoaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}

/* Header Styling */
.DataDiscoveryContainer p,
.DataSummaryContainer p {
  font-weight: bold;
  padding: 10px;
  background-color: #f0f0f0; /* Light grey background */
  margin-bottom: 10px; /* Added space below the header */
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/FilterTableList/FilterTableList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,8BAA8B;AAChC;;AAEA;;EAEE,aAAa;EACb,YAAY;EACZ,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0CAA0C;AAC5C;;AAEA,mBAAmB;AACnB;;EAEE,iBAAiB;EACjB,aAAa;EACb,yBAAyB,EAAE,0BAA0B;EACrD,mBAAmB,EAAE,iCAAiC;AACxD","sourcesContent":[".FilterTableDataList {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n}\n\n.DataContainers {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.DataDiscoveryContainer,\n.DataSummaryContainer {\n  display: flex;\n  height: 100%;\n  width: 48%;\n  flex-direction: column;\n}\n\n.LoaderContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.7);\n}\n\n/* Header Styling */\n.DataDiscoveryContainer p,\n.DataSummaryContainer p {\n  font-weight: bold;\n  padding: 10px;\n  background-color: #f0f0f0; /* Light grey background */\n  margin-bottom: 10px; /* Added space below the header */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
