import { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./DataCatalog.css";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import Auth from "../../../../../auth/AuthProvider";
import {
  getCatalogSummary,
  updateCatalog,
} from "../../../../utilities/functions/apiCalls";
import { DataContext } from "../../../../../context/DataContext";
import { Debouncer } from "./../../../../../utils/debounce";

export default function useCatalogData() {
  const {
    setCatalogFiles,
    setCatalogSummary,
    catalogFiles,
    setCurrentDataGroup,
    currentDataGroup,
    quarantinedFiles,
    setQuarantinedFiles,
    setSearchTerm,
    searchTerm,
    searchDetails,
    setHiddenCategories,
    selectedFilters,
    setSelectedFilters,
    preferences,
    setSearchDetails,
    failedTags,
    usedCatalog,
  } = useContext(DataContext);

  const [answerLoading, setAnswerLoading] = useState(false);
  const [mappings, setMappings] = useState({});
  const [showStandardization, setShowStandardization] = useState(false);
  const [standardizeTag, setStandardizeTag] = useState("");
  const [standardizeScreen, setStandardizeScreen] = useState("config");
  const [standardizeSensitivity, setStandardSensitivity] = useState(0.5);
  const [standardizeMinCategories, setStandardizeMinCategories] = useState(3);
  const [standardizationLoading, setStandardizationLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState("none");
  const [semanticSimilarity, setSemanticSimilarity] = useState(false);
  const lastFetchedTimeRef = useRef(Date.now());

  const menuRef = useRef();
  const noOfDocumentsWithFailedTags = useMemo(
    () => new Set(Array.from(failedTags.values()).flat()).size,
    [failedTags]
  );

  const handleFilterChange = (categoryKey, selectedOptions) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [categoryKey]: selectedOptions,
    }));
  };

  const getSummary = () =>
    Debouncer.debounce(async () => {
      const newSummary = await getCatalogSummary(currentDataGroup);
      if (!newSummary) return;

      const newCatalogSummary = newSummary.catalog_summary;
      if (newCatalogSummary.hasOwnProperty("tags")) {
        delete newCatalogSummary.tags;
      }
      setCatalogSummary(newSummary.catalog_summary);
      setSearchDetails(newSummary.search_details);
    }, 5000);

  useEffect(() => {
    getSummary();
  }, [currentDataGroup]);

  const clearAllFilters = () => {
    setSelectedFilters({});
    setHiddenCategories([]);
    setSearchTerm("");
  };

  const handleResetFilter = (e, category) => {
    e.stopPropagation();
    const filterCopy = { ...selectedFilters };
    delete filterCopy[category];
    setSelectedFilters(filterCopy);
  };

  const standardizeValues = async (e, tag) => {
    setStandardizeScreen("config");
    e.stopPropagation();
    setStandardizeTag(tag);
    setShowStandardization(true);
  };

  const runStandardization = async () => {
    setStandardizationLoading(true);
    setStandardizeScreen("mappings");
    const rawConsolidationResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        tag: standardizeTag,
        catalog: JSON.stringify(catalogFiles),
        sensitivity: standardizeSensitivity,
        minimum_categories: parseInt(standardizeMinCategories),
        semantic_similarity: semanticSimilarity,
      },
      ENDPOINTS["get_standardize_tag_map"]
    );

    const catalogResponse = await rawConsolidationResponse.json();
    setMappings(catalogResponse.mapping);
    setStandardizationLoading(false);
    return catalogResponse.mapping;
  };

  const acceptNewValues = async () => {
    const rawConsolidationResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        mapping: JSON.stringify(mappings),
        catalog: JSON.stringify(catalogFiles),
        tag: standardizeTag,
      },
      ENDPOINTS["apply_standardize_tag_map"]
    );
    const catalogResponse = await rawConsolidationResponse.json();
    setSelectedFilters({});
    setCatalogFiles(catalogResponse.new_catalog);
    setCurrentDataGroup(catalogResponse.new_catalog);
    setCatalogSummary(catalogResponse.new_catalog_summary);
    updateCatalog(usedCatalog, catalogResponse.new_catalog);
    setShowStandardization(false);
    setMappings({});
    setStandardizeScreen("config");
  };

  const quarantineFiles = async () => {
    const fileNames = Object.keys(currentDataGroup);
    const totalFiles = fileNames.length;

    const confirmQuarantine = window.confirm(
      `You are about to quarantine ${totalFiles} file(s). Are you sure you want to proceed?`
    );
    if (!confirmQuarantine) {
      return;
    }

    const newCatalogFiles = { ...catalogFiles };
    const newQuarantinedFiles = { ...quarantinedFiles };
    const constructSensitivityString = () => {
      if (!selectedFilters || Object.keys(selectedFilters).length === 0) {
        return "No filters applied";
      }

      let sensitivityDescriptions = [];
      for (const [key, values] of Object.entries(selectedFilters)) {
        let valuesArray;

        if (values instanceof Set) {
          valuesArray = Array.from(values);
        } else if (Array.isArray(values)) {
          valuesArray = values;
        } else {
          valuesArray = [values];
        }

        if (valuesArray.length > 0) {
          const valuesString = valuesArray.join(", ");
          sensitivityDescriptions.push(`${key}: ${valuesString}`);
        }
      }

      if (sensitivityDescriptions.length === 0) {
        return "No filters applied";
      }

      return sensitivityDescriptions.join("; ");
    };

    fileNames.forEach((fileName) => {
      if (newCatalogFiles.hasOwnProperty(fileName)) {
        newQuarantinedFiles[fileName] = newCatalogFiles[fileName];
        newQuarantinedFiles[fileName].quarantine = "quarantined";
        newQuarantinedFiles[fileName].custom_sensitivity = [
          "Yes",
          constructSensitivityString(),
        ];
        delete newCatalogFiles[fileName];
      }
    });

    setCatalogFiles(newCatalogFiles);
    setQuarantinedFiles(newQuarantinedFiles);
    await updateCatalog(preferences.system.QUARANTINECATALOG, quarantinedFiles);
    await updateCatalog(usedCatalog, catalogFiles);
    clearAllFilters();
  };

  useEffect(() => {
    let data = { ...catalogFiles };

    const matchesSelectedFilters = (item, categoryKey) => {
      if (!item || !categoryKey) return false;

      if (!(categoryKey in item)) {
        return false;
      }

      const selectedOptions = selectedFilters[categoryKey];
      if (!selectedOptions || selectedOptions.size === 0) {
        return true;
      }

      const itemValue = item[categoryKey];
      if (!itemValue || !itemValue.value || !Array.isArray(itemValue.value))
        return false;

      return Array.from(selectedOptions).some((option) =>
        itemValue.value.includes(option)
      );
    };

    data = Object.keys(data).reduce((result, key) => {
      const file = data[key];
      const fileChunks = file.chunks || {};

      // Check if the file has chunks to process
      if (Object.keys(fileChunks).length > 0) {
        let matchingChunks = {};

        Object.keys(fileChunks).forEach((chunkKey) => {
          const chunk = fileChunks[chunkKey];
          if (
            Object.keys(selectedFilters).every((categoryKey) =>
              matchesSelectedFilters(chunk, categoryKey)
            )
          ) {
            matchingChunks[chunkKey] = chunk;
          }
        });

        if (Object.keys(matchingChunks).length > 0) {
          result[key] = { ...file, chunks: matchingChunks };
        }
      } else {
        result[key] = { ...file };
      }

      return result;
    }, {});

    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase().trim();
      data = Object.keys(data)
        .filter((key) => {
          const searchDetail = searchDetails[key];
          return (
            searchDetail &&
            (searchDetail.title.toLowerCase().includes(searchTermLower) ||
              searchDetail.metadata_search_string
                .toLowerCase()
                .includes(searchTermLower))
          );
        })
        .reduce((newData, key) => {
          newData[key] = data[key];
          return newData;
        }, {});
    }

    setCurrentDataGroup(data);
  }, [searchTerm, selectedFilters, catalogFiles]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setDropdownOpen("none");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return {
    mappings,
    setMappings,
    quarantineFiles,
    acceptNewValues,
    runStandardization,
    standardizeValues,
    handleResetFilter,
    handleFilterChange,
    noOfDocumentsWithFailedTags,
    lastFetchedTimeRef,
    semanticSimilarity,
    setSemanticSimilarity,
    dropdownOpen,
    setDropdownOpen,
    standardizationLoading,
    setStandardizationLoading,
    standardizeMinCategories,
    setStandardizeMinCategories,
    standardizeSensitivity,
    setStandardSensitivity,
    standardizeScreen,
    setStandardizeScreen,
    showStandardization,
    setShowStandardization,
    answerLoading,
    setAnswerLoading,
    standardizeTag,
    setStandardizeTag,
    clearAllFilters,
  };
}