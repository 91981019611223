import React, { useState, useRef, useEffect, useContext } from "react";
import "./DataList.css";
import { sendRequest } from "../../../../../../../components/utilities/functions/api";
import { ENDPOINTS } from "../../../../../../../api/endpoints";
import Auth from "../../../../../../../auth/AuthProvider";
import LoadComponent from "../../../../../../utilities/LoadComponent/LoadComponent";
import { List, AutoSizer } from "react-virtualized";
import Modal from "../../../../../../utilities/Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTrashAlt,
  faArrowCircleDown,
  faArrowCircleUp,
  faWarning,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";
import { fetchDocumentContent } from "../../../../../../utilities/functions/apiCalls";
import { DataContext } from "../../../../../../../context/DataContext";
import { isSpreadsheetFile } from "../../../../../../utilities/functions/utils";
import ProgressBar from "../../../../../../utilities/NavigationBar/Components/ProgressBar/ProgressBar";
import EvidenceTable from "./DataListComponent/EvidenceTable/EvidenceTable";
import { useUserProfile } from "../../../../../../../context/UserProfile";
import { useAtom } from "jotai";
import { documentsUploadTaskAtom } from "../../../../../../../atoms";
import { abortTask } from "../../../../../../../utils/workers";
import CatalogTable from "../../../../../../../pages/CatalogTable";
import { toast } from "../../../../../../utilities/Toast";

const TagInfo = ({ value, tag, hasFailed, tooltip }) => {
  if (hasFailed) {
    return (
      <>
        <span className="">
          <FontAwesomeIcon icon={faWarning} />
          {tag}
        </span>
        <span className="CatalogTooltip">Tag failed</span>
      </>
    );
  }

  return (
    <div className="relative">
      {typeof value === "object" ? (
        <span>
          <span className="font-bold">{tooltip}: </span>
          {JSON.stringify(value)}
        </span>
      ) : (
        <span>
          <span className="font-bold">{tooltip}: </span>
          {value}
        </span>
      )}
    </div>
  );
};
export default function DataList(props) {
  const legendRef = useRef(null);
  const [modalContent, setModalContent] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [expandTags, setExpandTags] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [newValue, setNewValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const [evidenceData, setEvidenceData] = useState([]);
  const [showAllEvidence, setShowAllEvidence] = useState(false);
  const [currentItemKey, setCurrentItemKey] = useState("");
  const [sortedChunkMappings, setSortedChunkMappings] = useState({});
  const [documentsUploadTask, setDocumentsUploadTask] = useAtom(
    documentsUploadTaskAtom
  );

  const {
    currentDataGroup,
    isModalOpen,
    setModalOpen,
    processingFile,
    catalogSummary,
    handleDatasetDelete,
    currentProcessCount,
    currentTotalProcessCount,
    quarantinedFiles,
    hiddenCategories,
    handleMultipleDelete,
    preferences,
    hasTagFailedFor,
    setCurrentDataGroup,
    usedCatalog,
    availableTags,
    ruleDict,
  } = useContext(DataContext);
  const listRef = useRef(null);
  const { permissions } = useUserProfile();

  useEffect(() => {
    const sortedChunkKeys = Object.keys(
      currentDataGroup[currentItemKey]?.chunks || {}
    ).sort((a, b) => {
      const aStart = parseInt(a.split("_")[0], 10);
      const bStart = parseInt(b.split("_")[0], 10);
      return aStart - bStart;
    });

    const chunkMappings = {};
    sortedChunkKeys.forEach((chunkKey, index) => {
      chunkMappings[chunkKey] = `Chunk ${index + 1}`;
    });

    setSortedChunkMappings(chunkMappings);
  }, [currentDataGroup, currentItemKey]);

  const toggleExpandRow = (rowKey, index) => {
    if (expandedRowKey === index) {
      setExpandedRowKey(null);
    } else {
      setExpandedRowKey(index);
    }

    setExpandedRows((prevState) => ({
      ...prevState,
      [rowKey]: !prevState[rowKey],
    }));

    if (listRef.current) {
      listRef.current.recomputeRowHeights(index);
      listRef.current.forceUpdateGrid();
    }
  };

  const getRowHeight = ({ index }) => {
    return expandedRowKey === index ? 300 : 200;
  };

  const isCategoryHidden = (category) => {
    return hiddenCategories && hiddenCategories.includes(category);
  };

  const get_document_content = async (item, chunkKey = "", chunk = false) => {
    setSelectedLabel(null);
    setModalOpen(true);
    setIsLoading(true);

    let documentText = "...Loading";

    if (!isSpreadsheetFile(item)) {
      setSelectedName(item);

      let chunk_index = ["none"];
      if (chunk) {
        chunk_index = chunkKey.split("_").map(Number);
      }

      const item_catalog = currentDataGroup[item];
      documentText = await fetchDocumentContent(
        item_catalog.file_directory + "/" + item,
        preferences.webapp_profile.DATA_STORES,
        chunk_index,
        false,
        item_catalog.storage_type[0]
      );
    }

    setModalContent(documentText);
    setIsLoading(false);
  };

  const handleLabelClick = (labelKey, itemKey, tagAttr) => {
    const labelValue = tagAttr.hasOwnProperty("value")
      ? tagAttr.value
      : tagAttr;
    const tagReason = tagAttr.hasOwnProperty("reason") ? tagAttr.reason : "";
    const tagEvidence = tagAttr.hasOwnProperty("evidence")
      ? tagAttr.evidence
      : "";
    setModalOpen(true);
    setSelectedLabel({ labelKey, itemKey, tagReason, tagEvidence });
    setNewValue(Array.isArray(labelValue) ? labelValue : [labelValue]);
  };

  const handleEvidenceButtonClick = (itemKey) => {
    const item = currentDataGroup[itemKey];
    const groupedEvidence = {};

    if (item.hasOwnProperty("chunks")) {
      Object.entries(item.chunks).forEach(([chunkKey, chunkValue]) => {
        Object.entries(chunkValue).forEach(([tag, details]) => {
          if (!groupedEvidence[tag]) {
            groupedEvidence[tag] = [];
          }

          if (details.evidence) {
            groupedEvidence[tag].push({
              chunk: chunkKey,
              evidence: details.evidence,
              reason: details.reason,
              values: details.value,
              isValid: details.isValid ?? true,
            });
          }
        });
      });
    }

    const evidenceList = Object.entries(groupedEvidence)
      .map(([tag, evidences]) => ({
        tag,
        evidences,
      }))
      .filter(({ evidences }) => evidences.length > 0);

    setEvidenceData(evidenceList);
    setCurrentItemKey(itemKey);
    setIsEvidenceModalOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!legendRef.current?.contains(event.target)) {
        setExpandTags(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    processingFile,
    expandedRowKey,
    getRowHeight,
    currentProcessCount,
    currentTotalProcessCount,
  ]);
  const filenameToColor = (fileName, opacity) => {
    let hash = 0;
    for (let i = 0; i < fileName.length; i++) {
      hash = fileName.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }

    let color = "#";
    for (let i = 0; i < 3; i++) {
      // Use parts of the hash for different color components
      let value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).slice(-2);
    }
    return color + opacity;
  };

  const rowRenderer = ({ key, index, style }) => {
    const itemKey = Object.keys(currentDataGroup)[index];
    const item = currentDataGroup[itemKey];
    const isLoading = itemKey === processingFile;
    const isRowExpanded = expandedRows[itemKey];
    const hasChunks = item.hasOwnProperty("chunks");

    const sortedChunkKeys = hasChunks
      ? Object.keys(item.chunks).sort((a, b) => {
          const aStart = parseInt(a.split("_")[0], 10);
          const bStart = parseInt(b.split("_")[0], 10);
          return aStart - bStart;
        })
      : [];

    return (
      <div
        key={key}
        style={style}
        className="DataItemContainerMain border-b bg-white"
      >
        <div className="DataItemContentMain">
          <header className="text-zinc-600 dark:text-white pb-4">
            {itemKey}
          </header>

          <div
            className="flex gap-1 px-4 flex-wrap overflow-auto"
            onClick={() => setExpandTags(true)}
          >
            {isLoading ? (
              <div className="spinnerContainer">
                <div className="spinner"></div>
              </div>
            ) : !isRowExpanded ? (
              catalogSummary &&
              Object.keys(item).map((prop) => {
                if (
                  prop !== "file_directory" &&
                  prop !== "chunks" &&
                  item[prop] &&
                  !isCategoryHidden(prop)
                ) {
                  const values = Array.isArray(item[prop])
                    ? item[prop]
                    : [item[prop]];
                  return values.map((value, valIndex) => {
                    if (
                      value &&
                      prop in
                        availableTags.sensitivity.tagger_params.tag_dict ===
                        false &&
                      Object.keys(
                        {
                          ...availableTags.llm.tagger_params.tag_dict,
                          ...ruleDict,
                        } || {}
                      ).includes(prop)
                    ) {
                      console.log({ prop });
                      return (
                        <div
                          className={`relative text-xs px-4 py-1 cursor-pointer rounded-md group ${
                            hasTagFailedFor(prop, itemKey) ? "failed-tag" : ""
                          }`}
                          key={`tag-${prop}-${valIndex}`}
                          style={{
                            backgroundColor: hasTagFailedFor(prop, itemKey)
                              ? ""
                              : filenameToColor(value, "40") || "#80808028",
                          }}
                        >
                          <TagInfo
                            tag={prop}
                            value={value}
                            hasFailed={hasTagFailedFor(prop, itemKey)}
                            tooltip={catalogSummary[prop]?.real}
                          />
                        </div>
                      );
                    }
                    return null;
                  });
                }
                return null;
              })
            ) : (
              <div className="w-full h-full border rounded-md">
                {sortedChunkKeys.map((chunkKey, chunkIndex) => {
                  const chunkValue = item.chunks[chunkKey];
                  return (
                    <div key={`chunk-${chunkKey}`} className="flex flex-col">
                      <div className="flex justify-between p-4 bg-slate-100">
                        <div className="ChunkName whitespace-pre-wrap text-sm">
                          <strong>Chunk {chunkIndex + 1}</strong>
                          <br />
                          Starts at: {chunkKey.replace("_", " \nEnds at: ")}
                        </div>
                        <button
                          className="previewButtonDataList bg-secondary"
                          onClick={() =>
                            get_document_content(itemKey, chunkKey, true)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            className="previewIcon"
                          />
                        </button>
                      </div>

                      <div className="ChunkLabelsWrapper p-4">
                        {Object.entries(chunkValue).map(
                          ([attrKey, attrValue]) => {
                            const valuesArray = Array.isArray(attrValue)
                              ? attrValue
                              : [attrValue];
                            return valuesArray.map((value, valIndex) => {
                              if (
                                !Object.keys(
                                  availableTags.sensitivity.tagger_params
                                    .tag_dict
                                ).includes(attrKey)
                              ) {
                                return (
                                  <div
                                    className="ChunkLabelContainer"
                                    key={`chunk-label-${attrKey}-${valIndex}`}
                                  >
                                    <span
                                      className="Tag"
                                      style={{
                                        backgroundColor:
                                          catalogSummary[attrKey]?.colour ||
                                          "gray",
                                      }}
                                      onClick={() =>
                                        handleLabelClick(
                                          attrKey,
                                          itemKey,
                                          attrValue
                                        )
                                      }
                                    >
                                      <span className="font-bold">
                                        {attrKey}:
                                      </span>{" "}
                                      {value && typeof value === "object"
                                        ? value.value[0]
                                        : value}
                                    </span>
                                  </div>
                                );
                              }
                              return null;
                            });
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="DataActionButtons">
          {!isRowExpanded && (
            <>
              <button
                className="previewButtonDataList bg-secondary"
                onClick={async () => {
                  const fileDirectory =
                    currentDataGroup[itemKey]["file_directory"][0];
                  const storageName =
                    currentDataGroup[itemKey]["storage_name"][0];
                  const storageType =
                    currentDataGroup[itemKey]["storage_type"][0];
                  const response = await sendRequest(
                    {
                      [preferences.system.API_USERNAME_KEYWORD]: (
                        await Auth.currentAuthenticatedUser()
                      ).username,
                    },
                    `/api/catalog/download?file_directory=${fileDirectory}&storage_type=${storageType}&storage_name=${storageName}&file_name=${itemKey}`,
                    "GET"
                  );
                  const { data } = await response.json();
                  window.open(data.url, "_blank");
                }}
              >
                <FontAwesomeIcon icon={faEye} className="previewIcon" />
              </button>

              {permissions.catalogs.canEdit && (
                <button
                  onClick={() => handleDatasetDelete(itemKey)}
                  className="delete-button-datalist"
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ color: "white" }}
                  />
                </button>
              )}

              <button
                className="EvidenceButton"
                onClick={() => handleEvidenceButtonClick(itemKey)}
              >
                <FontAwesomeIcon style={{ color: "black" }} icon={faTimeline} />
              </button>
            </>
          )}

          {hasChunks && (
            <button
              className="ExpandButton shrink-0 w-10"
              onClick={() => toggleExpandRow(itemKey, index)}
            >
              <FontAwesomeIcon
                style={{ color: "black" }}
                icon={isRowExpanded ? faArrowCircleUp : faArrowCircleDown}
              />
            </button>
          )}
        </div>
      </div>
    );
  };

  if (props.answerLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="overflow-hidden flex w-full h-full flex-col rounded-b-md">
      <ProgressBar />

      <div className="DataListHeader bg-primary rounded-t-md">
        <header className="whitespace-nowrap text-m text-white">
          <div>
            <span style={{ fontWeight: 700 }}>
              {Object.keys(currentDataGroup).length}{" "}
            </span>
            <span>datasets in catalog</span>
          </div>
          <div>
            <span style={{ fontWeight: 700 }}>
              {quarantinedFiles ? Object.keys(quarantinedFiles).length : 0}{" "}
            </span>
            <span>datasets in quarantine</span>
          </div>
        </header>
        <p className="SelectionCountText">
          {documentsUploadTask && (
            <button
              onClick={async () => {
                const creds = (await Auth.currentAuthenticatedUser()).username;
                await abortTask(documentsUploadTask, creds);
                setDocumentsUploadTask(null);

                toast.info({
                  title: "Aborting document upload",
                });
              }}
              className="text-sm bg-red-400 px-4 py-2 rounded-md"
            >
              Abort Upload
            </button>
          )}
          {permissions.catalogs.canEdit && (
            <button
              onClick={handleMultipleDelete}
              className="text-sm bg-red-400 px-4 py-2 rounded-md"
            >
              <FontAwesomeIcon icon={faTrashAlt} /> Delete{" "}
              {Object.keys(currentDataGroup).length}
            </button>
          )}
        </p>
      </div>

      <div className="flex w-full h-full overflow-hidden">
        {window.location.pathname.includes("catalog") ? (
          <CatalogTable
            onEvidenceClick={handleEvidenceButtonClick}
            availableTags={availableTags}
          />
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                ref={listRef}
                rowCount={
                  currentDataGroup ? Object.keys(currentDataGroup).length : 0
                }
                rowHeight={getRowHeight}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        )}
      </div>
      <EvidenceTable
        showAllEvidence={showAllEvidence}
        setShowAllEvidence={setShowAllEvidence}
        evidenceData={evidenceData}
        setEvidenceData={setEvidenceData}
        currentItemKey={currentItemKey}
        isEvidenceModalOpen={isEvidenceModalOpen}
        setIsEvidenceModalOpen={setIsEvidenceModalOpen}
        sortedChunkMappings={sortedChunkMappings}
        onClose={async () => {
          evidenceData.forEach((evidenceData) => {
            const tag = evidenceData.tag;
            evidenceData.evidences.forEach((evidence) => {
              const chunk = evidence.chunk;
              currentDataGroup[currentItemKey].chunks[chunk][tag] = {
                evidence: evidence.evidence,
                isValid: evidence.isValid,
                reason: evidence.reason,
                value: evidence.values,
              };
            });
          });
          const id = currentItemKey;
          const catalogItem = currentDataGroup[currentItemKey];
          const creds = (await Auth.currentAuthenticatedUser()).username;
          await sendRequest(
            {
              entries: [
                {
                  data_store: JSON.stringify({
                    ...preferences.webapp_profile.DATA_STORES[
                      catalogItem.storage_type
                    ],
                    path: `${catalogItem.file_directory}/${id}`,
                  }),
                  tagger_list: JSON.stringify({
                    llm: {
                      tagger_params: {
                        model: {
                          provider: preferences.webapp_profile.PROVIDER_USED,
                          version: preferences.webapp_profile.MODEL_USED,
                        },
                        iters: 1,
                        tag_dict: {},
                      },
                    },
                  }),
                  file_catalog_entry: JSON.stringify({ [id]: catalogItem }),
                  catalog_name: usedCatalog,
                  quarantine_name: preferences.system.QUARANTINECATALOG,
                  check_sensitivity: false,
                },
              ],
              [preferences.system.API_USERNAME_KEYWORD]: creds,
            },
            ENDPOINTS["create_catalog_in_bulk"]
          );

          setCurrentDataGroup({ ...currentDataGroup });
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedName("");
          setSelectedLabel(null);
        }}
        title={selectedLabel ? selectedLabel.labelKey : selectedName}
      >
        {isLoading ? "Loading..." : modalContent}
      </Modal>
    </div>
  );
}
