import "./DataCatalog.css";
import TagFilter from "../../../../utilities/TagFilter/TagFilter";
import { toast } from "./../../../../utilities/Toast";
import { faWarning, faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCatalogData from "./useCatalogData";
import { useContext, useState } from "react";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";

const TagDefinitions = () => {
  const {
    showScreen,
    catalogSummary,
    availableTags,
    selectedFilters,
    preferences,
    failedTags,
    tagsToBeDeleted,
    ruleDict,
    currentDataGroup,
  } = useContext(DataContext);
  const { processTag } = useContext(TagContext);

  const {
    handleFilterChange,
    standardizeValues,
    handleResetFilter,
    dropdownOpen,
    setDropdownOpen,
    quarantineFiles,
    clearAllFilters,
    noOfDocumentsWithFailedTags,
  } = useCatalogData();

  const [searchTerm, handleSearchChange] = useState("");

  return (
    <div className="h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden">
      <div className="px-2 h-16 flex items-center shrink-0 bg-slate-200 dark:bg-zinc-600 dark:text-white">
        <div className="text-sm">Tag Definitions</div>
      </div>
      <input
        type="text"
        placeholder="Search Tags..."
        value={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
        className="search-bar p-4 text-sm outline-none w-full border"
      />
      <div className="flex flex-col overflow-auto h-full">
        {catalogSummary &&
          Object.keys({
            ...availableTags.llm.tagger_params.tag_dict,
            ...ruleDict,
          })
            .filter((key) => {
              return searchTerm
                ? key.toLowerCase().includes(searchTerm.toLowerCase())
                : key !== "file_directory" &&
                    !Object.keys(preferences.system.SENSITIVITY_TAGS).includes(
                      key
                    ) &&
                    !preferences.system.EXCLUDE_TAGS.includes(key);
            })
            .map((key) => {
              return (
                <TagFilter
                  key={key}
                  label={key}
                  categoryKey={key}
                  options={catalogSummary[key]?.availableValues || []}
                  onFilterChange={handleFilterChange}
                  selectedOptions={selectedFilters[key]}
                  handleReset={handleResetFilter}
                  showScreen={showScreen}
                  standardizeValues={standardizeValues}
                  catalogSummary={catalogSummary}
                  dropdownOpen={dropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                  isBeingDeleted={tagsToBeDeleted.includes(key)}
                ></TagFilter>
              );
            })}
      </div>
      <div className="FilterActions bg-slate-200 dark:bg-zinc-600">
        <button
          className="bg-primary hover:bg-secondary px-4 py-2 text-sm text-white rounded-md"
          onClick={() => {
            const confirmation = window.confirm(
              `Are you sure you want to quarantine ${Object.keys(currentDataGroup || {}).length} files?`
            );
            if (!confirmation) return;
            quarantineFiles();
          }}
        >
          Quarantine {Object.keys(currentDataGroup || {}).length}
        </button>

        {failedTags.size > 0 && (
          <button
            className="bg-yellow-500 px-4 py-2 text-white rounded-md text-sm"
            title={`${failedTags.size} Tag(s) failed on ${noOfDocumentsWithFailedTags} file(s) - Re-run all`}
            onClick={() => {
              failedTags.forEach((_, failedTag) => {
                processTag("ReRunFailed", failedTag);
              });

              toast.success({
                title: `Re-run of all failed tags queued: ${failedTags.size}`,
                description: "",
              });
            }}
          >
            Re-run failed tags: {failedTags.size}
          </button>
        )}
      </div>
    </div>
  );
};

export default TagDefinitions;
