import React, { useEffect, useContext, useMemo, memo, useState } from "react";
import "./Tags.css";
import { COLOURS } from "../../../../../../../assets/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../../../../../../../context/DataContext";
import { TagContext } from "../../../../../../../context/TagContext";

const Tags = memo(
  function Tags({ title, tagTypes }) {
    const {
      deleteAllLabel,
      currentDataGroup,
      editTag,
      availableTags,
      failedTags,
      currentTag,
    } = useContext(DataContext);
    const { setRelatedInfo, defaultTagTypes } = useContext(TagContext);
    const [filter, setFilter] = useState("all");
    const [searchTerm, setSearchTerm] = useState("");

    const tagDict = useMemo(() => {
      return tagTypes.reduce((acc, type) => {
        if (availableTags[type] && availableTags[type].tagger_params) {
          const filteredTags = Object.entries(
            availableTags[type].tagger_params.tag_dict
          )
            .filter(([_, value]) => {
              if (filter === "all") return true;
              if (filter === defaultTagTypes["classification"])
                return value.tagType === defaultTagTypes["classification"];
              return value.tagType !== defaultTagTypes["classification"];
            })
            .reduce((newAcc, [k, v]) => ({ ...newAcc, [k]: v }), {});
          return { ...acc, ...filteredTags };
        }
        return acc;
      }, {});
    }, [availableTags, tagTypes, filter]);

    const getCircleColor = (type) => {
      return type === defaultTagTypes["classification"]
        ? "bg-green-500"
        : "bg-grey-500";
    };

    useEffect(() => {
      const calculateRelatedInfo = () => {
        let info = {};
        Object.entries(tagDict).forEach(([key, value]) => {
          let counter = 0;
          let name = value.name;
          let matchingNames = [];

          Object.entries(currentDataGroup).forEach(([groupKey, groupValue]) => {
            if (!groupValue.hasOwnProperty(name)) {
              counter++;
              matchingNames.push(groupKey);
            }
          });

          info[key] = { name, counter, matchingNames };
        });

        setRelatedInfo(info);
      };

      calculateRelatedInfo();
    }, [currentDataGroup, setRelatedInfo, tagDict]);

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    return (
      <div className="label-container">
        <div
          className="DataGraphHeader"
          style={{ backgroundColor: COLOURS["HeaderBackground"] }}
        >
          <header
            style={{ color: COLOURS["MainText"], fontWeight: 700 }}
            className="font-bold text-m"
          >
            {title ? title : "Tag Definitions"}
          </header>
          <input
            type="text"
            placeholder="Search Tags..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar py-2 px-4 rounded-md outline-none mt-3 w-full"
          />
        </div>
        <div className="flex space-x-4 p-4">
          <button
            onClick={() => setFilter("all")}
            className={`px-2 py-1 rounded ${filter === "all" ? "bg-blue-300" : "bg-transparent"}`}
          >
            All
          </button>
          <button
            onClick={() => setFilter(defaultTagTypes["classification"])}
            className={`px-2 py-1 rounded ${filter === defaultTagTypes["classification"] ? "bg-gray-300" : "bg-transparent"}`}
          >
            {defaultTagTypes["classification"]}
          </button>
          <button
            onClick={() => setFilter("sensitivity")}
            className={`px-2 py-1 rounded ${filter === "sensitivity" ? "bg-gray-300" : "bg-transparent"}`}
          >
            Sensitivity
          </button>
        </div>
        <div className="label-list">
          {Object.keys(tagDict).length === 0 ? (
            <div className="flex justify-center items-center h-full">
              <p className="text-gray-500 text-lg">No tags created yet</p>
            </div>
          ) : (
            Object.entries(tagDict)
              .filter(([_, value]) =>
                value.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(([key, value]) => {
                return (
                  <div
                    key={key}
                    className={`flex items-center justify-between p-4 my-2 rounded-lg shadow ${
                      failedTags.has(value.name)
                        ? "bg-red-100"
                        : currentTag.name === value.name
                          ? "bg-light"
                          : "bg-white"
                    } ${currentTag.name === value.name ? "border-2 border-primary" : ""}`}
                  >
                    <div className="flex flex-1 items-center space-x-4">
                      <div
                        className="flex-shrink-0 h-5 w-5 rounded-full"
                        style={{
                          backgroundColor: getCircleColor(value.tagType),
                        }}
                      />
                      <div className="flex flex-col flex-grow">
                        <p className="text-sm font-bold text-gray-800">
                          {value.name}
                        </p>
                        <p className="text-sm text-gray-600">
                          {value.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <button
                        className="p-2 rounded-full text-blue-500 hover:text-blue-600"
                        onClick={(e) => editTag(e, value.name)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        className="p-2 rounded-full text-red-500 hover:text-red-600"
                        onClick={(e) => deleteAllLabel(e, key)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                      {failedTags.has(value.name) && (
                        <div className="flex items-center text-yellow-500">
                          <FontAwesomeIcon icon={faWarning} />
                          <span className="ml-1 text-xs">
                            Failed on {failedTags.get(value.name).length}{" "}
                            file(s)
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    );
  },
  (prev, next) => {
    return (
      prev.title === next.title &&
      prev.tagTypes?.length === next.tagTypes?.length &&
      prev.mode === next.mode
    );
  }
);

export default Tags;
