import React, { useState } from "react";

type ApiTypeOptions = {
  model_used: string[];
  embed_model_used: string[];
};

type ApiTypeOptionMap = {
  [key: string]: ApiTypeOptions;
};

type DataRow = {
  api_type: string;
  base_url?: string;
  embed_model?: string;
  model?: string;
  api_version?: string;
  api_key?: string;
  model_used?: string;
  embed_model_used?: string;
  enabled?: boolean;
};

type DynamicTableProps = {
  initialData: DataRow[];
  onDataChange: (newData: DataRow[]) => void;
};

function DynamicTable({ initialData, onDataChange }: DynamicTableProps) {
  const staticHeaders: string[] = [
    "api_type",
    "base_url",
    "embed_model",
    "model",
    "api_version",
    "api_key",
    "model_used",
    "embed_model_used",
    "enabled",
  ];

  const apiTypeOptions: ApiTypeOptionMap = {
    openai: {
      model_used: [
        "gpt-4-turbo-preview",
        "gpt-4-0125-preview",
        "gpt-4-1106-preview",
        "gpt-4",
        "gpt-4-0613",
        "gpt-4-32k",
        "gpt-4-32k-0613",
        "gpt-3.5-turbo-0125",
        "gpt-3.5-turbo",
        "gpt-3.5-turbo-1106",
        "gpt-3.5-turbo-instruct",
      ],
      embed_model_used: [
        "text-embedding-3-large",
        "text-embedding-3-small",
        "text-embedding-ada-002",
      ],
    },
    azure: {
      model_used: [
        "gpt-4",
        "gpt-4-32k",
        "gpt-35-turbo",
        "gpt-35-turbo-16k",
        "gpt-35-turbo-instruct",
      ],
      embed_model_used: ["text-embedding-ada-002"],
    },
    bedrock: {
      model_used: [
        "anthropic.claude-3-sonnet-20240229-v1:0",
        "anthropic.claude-3-haiku-20240307-v1:0",
        "anthropic.claude-v2:1",
        "anthropic.claude-instant-v1",
        "ai21.j2-mid-v1",
        "ai21.j2-ultra-v1",
        "cohere.command-text-v14",
        "mistral.mistral-7b-instruct-v0:2",
        "mistral.mixtral-8x7b-instruct-v0:1",
        "mistral.mistral-large-2402-v1:0",
        "meta.llama3-8b-instruct-v1:0",
        "meta.llama3-70b-instruct-v1:0",
      ],
      embed_model_used: [
        "amazon.titan-embed-text-v1",
        "amazon.titan-embed-image-v1",
      ],
    },
  };

  const editableFields = {
    openai: ["api_type", "model_used", "embed_model_used", "api_key", "enabled"],
    bedrock: ["api_type", "model_used", "embed_model_used", "enabled"],
    azure: ["api_type", "model_used", "embed_model_used", "api_key", "base_url", "api_version", "enabled", "model", "embed_model"],
  };

  const alignDataWithHeaders = (data: DataRow[]): DataRow[] => {
    return data.map((item) => {
      const alignedItem: Partial<DataRow> = {};

      staticHeaders.forEach((header) => {
        const value: string | boolean =
          (item as any)[header] ?? (header === "enabled" ? true : "");
        (alignedItem as any)[header] = value as DataRow[keyof DataRow];
      });

      return alignedItem as DataRow;
    });
  };

  const [tableData, setTableData] = useState<DataRow[]>(() =>
    alignDataWithHeaders(initialData),
  );

  const handleRowChange = <K extends keyof DataRow>(
    index: number,
    key: K,
    value: DataRow[K],
  ) => {
    const newData = [...tableData];
    newData[index][key] = value;

    if (key === 'api_type') {
      newData[index].model_used = (apiTypeOptions as any)[value].model_used[0];
      newData[index].embed_model_used = (apiTypeOptions as any)[value].embed_model_used[0];
    }

    setTableData(newData);
    onDataChange(newData);
  };

  const renderCellInput = (
    rowIndex: number,
    header: keyof DataRow,
    value: string | boolean,
  ) => {
    const apiType = tableData[rowIndex].api_type;

    const handleRowChangeWrapper = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
      let newValue: string | boolean;
      if (header === "enabled" && e.target.type === "checkbox") {
        newValue = (e.target as HTMLInputElement).checked;
      } else {
        newValue = e.target.value;
      }
      handleRowChange(rowIndex, header, newValue as any);
    };

    const isDisabled = !(editableFields as any)[apiType]?.includes(header);
    const disabledClasses = "bg-gray-200 text-gray-500 cursor-not-allowed border-gray-300";
    const enabledClasses = "bg-white border-gray-200";
    const commonClasses = "form-input block w-full mt-1 border-2 rounded-lg shadow focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 py-2 text-base";

    const finalClasses = `${commonClasses} ${isDisabled ? disabledClasses : enabledClasses}`;
    const valueToShow = isDisabled ? "" : String(value);

    if (header === "api_type") {
      return (
        <select
          className="form-select block w-full mt-1 border-2 border-gray-200 rounded-lg shadow focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 py-2 text-base leading-tight bg-white"
          value={String(value)}
          onChange={handleRowChangeWrapper}
        >
          <option value="openai">OpenAI</option>
          <option value="azure">Azure</option>
          <option value="bedrock">Bedrock</option>
        </select>
      );
    } else if ((header === "model_used" || header === "embed_model_used") && apiType !== "azure") {
      const options = apiTypeOptions[apiType]?.[header];
      const hasOptions = Array.isArray(options);

      if (!hasOptions) {
        return <span>No options available</span>;
      }

      return (
        <select
          disabled={isDisabled}
          className={finalClasses}
          value={String(value)}
          onChange={handleRowChangeWrapper}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    } else if (header === "enabled") {
      return (
        <input
          type="checkbox"
          disabled={isDisabled}
          className={`${commonClasses.replace('form-input', 'form-checkbox h-5 w-5 text-green-500')} ${isDisabled ? disabledClasses : enabledClasses}`}
          checked={Boolean(value)}
          onChange={handleRowChangeWrapper}
        />
      );
    } else {
      return (
        <input
          type="text"
          disabled={isDisabled}
          className={finalClasses}
          value={valueToShow}
          onChange={handleRowChangeWrapper}
        />
      );
    }
  };



  const addRow = () => {
    const defaultApiType = 'openai';
    const newRow: DataRow = staticHeaders.reduce<DataRow>((acc, header) => {
      if (header === 'model_used') {
        acc[header] = apiTypeOptions[defaultApiType].model_used[0];
      } else if (header === 'embed_model_used') {
        acc[header] = apiTypeOptions[defaultApiType].embed_model_used[0];
      } else {
        (acc as any)[header] = header === 'enabled' ? true : (header === 'api_type' ? defaultApiType : '');
      }
      return acc;
    }, {} as DataRow);
    const updatedTableData = [...tableData, newRow];
    setTableData(updatedTableData);
    onDataChange(updatedTableData);
  };

  const deleteRow = (index: number) => {
    const newData = tableData.filter((_, rowIndex) => rowIndex !== index);
    setTableData(newData);
    onDataChange(newData);
  };

  const getWidthClass = (header: keyof DataRow) => {
    switch (header) {
      case "api_type":
        return "w-1/12";
      default:
        return "w-auto";
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="divide-y divide-gray-300 min-w-full">
        <thead>
          <tr className="bg-gray-100">
            {staticHeaders.map((header) => (
              <th
                key={header}
                className="py-2 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {tableData.map((row, index) => (
            <tr key={index}>
              {staticHeaders.map((header) => (
                <td
                  key={header}
                  className={`px-2 py-3 whitespace-nowrap text-sm text-gray-700 bg-white hover:bg-gray-50 ${getWidthClass(header as keyof DataRow)}`}
                >
                  {renderCellInput(
                    index,
                    header as keyof DataRow,
                    (row as any)[header] ?? "",
                  )}
                </td>
              ))}
              <td>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => deleteRow(index)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="mt-4 px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded shadow-sm"
        onClick={addRow}
      >
        Add Row
      </button>
    </div>
  );
}

export default DynamicTable;
