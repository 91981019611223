// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SimplifiedDataList.css */

.SimpleDataList {
  display: flex;
  height: 45vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
}

.SimpleDataListHeader {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #3b7dc2;
}

.SimpleDataListContainer {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
}

.SimpleTagsDataName {
  max-width: 32vw;
  overflow: auto;
  white-space: nowrap;
}

.SimpleDataItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.SimpleDataItemContainer:hover {
  background-color: #f1f1f1;
}

.SimpleDataItemContent {
  flex-grow: 1;
}

.TagsDataName {
  font-size: 16px;
  color: #333;
}

.previewIcon {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/SimplifiedDataList/SimplifiedDataList.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,6BAA6B;EAC7B,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* SimplifiedDataList.css */\n\n.SimpleDataList {\n  display: flex;\n  height: 45vh;\n  width: 100%;\n  flex-direction: column;\n  align-items: center;\n  padding: 10px;\n  background-color: #f8f8f8;\n}\n\n.SimpleDataListHeader {\n  width: 100%;\n  padding: 10px;\n  background-color: #4a90e2;\n  color: #fff;\n  font-size: 18px;\n  font-weight: bold;\n  text-align: center;\n  border-bottom: 2px solid #3b7dc2;\n}\n\n.SimpleDataListContainer {\n  flex-grow: 1;\n  width: 100%;\n  overflow-y: auto;\n}\n\n.SimpleTagsDataName {\n  max-width: 32vw;\n  overflow: auto;\n  white-space: nowrap;\n}\n\n.SimpleDataItemContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n  border-bottom: 1px solid #ddd;\n  background-color: #fff;\n}\n\n.SimpleDataItemContainer:hover {\n  background-color: #f1f1f1;\n}\n\n.SimpleDataItemContent {\n  flex-grow: 1;\n}\n\n.TagsDataName {\n  font-size: 16px;\n  color: #333;\n}\n\n.previewIcon {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
