import { useEffect, useState, useCallback } from "react"
import { gatherFailedTags } from "../components/utilities/functions/utils"
import { toast } from "../components/utilities/Toast"

export const useFailedTags = (catalog) => {
  const [failedTags, setFailedTags] = useState(new Map())
  const [notifiedTags, setNotifiedFlags] = useState(new Set())

  useEffect(() => {
    const failedTags = gatherFailedTags(catalog)

    setFailedTags(failedTags)

  }, [catalog])

  useEffect(() => {
    failedTags.forEach((documents,failedTag ) => {
      if (notifiedTags.has(failedTag)) {
        return
      }

      toast.error({title: `Tag failed: ${failedTag}`, description: `No. of documents affected: ${documents.length}` })

      setNotifiedFlags(prev => prev.add(failedTag))
    })

  }, [failedTags.size, notifiedTags.size])

  const tagReRun = (tag) => {
    setFailedTags(prevTags => {
      prevTags.delete(tag)

      return prevTags
    })

    setNotifiedFlags(prev => {
      prev.delete(tag)
      return prev
    })
  }

  /**
   * Indicates whether given tag failed for the specific document
   *
   * @param {string} tag
   * @param {string} documentName
   *
   * @returns {boolean}
   */
  const hasTagFailedFor = (tag, documentName) =>  {
    const affectedDocuments = failedTags.get(tag) || []

    return affectedDocuments.includes(documentName)
  }

  /**
   * To be called on tag deletion so user will not see failed tag that has been
   * removed
   *
   * @param {string} tag
   *
   * @returns {void}
   */
  const failedTagDeleted = useCallback((tag) => {
    setFailedTags(oldTags => {
      oldTags.delete(tag);

      return oldTags;
    });
  }, [setFailedTags]);

  return {failedTags, tagReRun, hasTagFailedFor, failedTagDeleted}
}
