import React, { useContext } from "react";
import "./ProgressBar.css";
import { TagContext } from "../../../../../context/TagContext";
import { runningTasksAtom } from "../../../../../atoms";
import { useAtom } from "jotai";

const ProgressBar = () => {
  const [runningTasks] = useAtom(runningTasksAtom);
  const { processingTags } = useContext(TagContext);
  const getPercentage = () => {
    const filteredTasks = runningTasks.filter((task) => task.aborted !== true);
    const percentage =
      ((filteredTasks.length - processingTags.length) * 100) /
      filteredTasks.length;

    return isNaN(percentage) ? 0 : percentage;
  };

  if (getPercentage() === 100 || getPercentage() === 0) return <></>;

  return (
    <div className="relative bg-secondary bg-opacity-20 text-center text-xs py-0.5 text-zinc-500">
      <div
        className="absolute inset-y-0 bg-light bg-opacity-30 whitespace-nowrap"
        style={{
          width: `${getPercentage()}%`,
        }}
      ></div>
      {`Tasks running ${getPercentage().toFixed(2)}%`}
    </div>
  );
};

export default ProgressBar;
