import React, { useState } from "react";
import "./Standardization.css";
import "./StandardizationComponents/Sensitivity/Sensitivity.css";
import MappingList from "./StandardizationComponents/MappingList/MappingList";
import StandardizationOption from "./StandardizationComponents/StandardizationOption";
import { Checkbox } from "../../../../../../utilities/Checkbox";

interface StandardizationProps {
  showStandardization: boolean;
  standardizationLoading: boolean;
  setShowStandardization: React.Dispatch<React.SetStateAction<boolean>>;
  setStandardSensitivity: React.Dispatch<React.SetStateAction<number>>;
  standardizeSensitivity: number;
  standardizeTag: string;
  standardizeScreen: string;
  runStandardization: any;
  setStandardizeScreen: React.Dispatch<React.SetStateAction<string>>;
  acceptNewValues: any;
  mappings: any;
  setMappings: any;
  semanticSimilarity: boolean;
  setSemanticSimilarity: React.Dispatch<React.SetStateAction<boolean>>;
}
const Standardization = (props: StandardizationProps) => {
  const [errorMsg, setErrorMessage] = useState("");
 
  const handleRunStandardization = async () => {
    await props.runStandardization("text_standardize");
    props.setStandardizeScreen("mappings");
  };

  return (
    <>
      {props.showStandardization && (
        <div className="modal-mapping-list">
          <div className="modal-content-mapping-list">
            <span
              className="close-mapping-list"
              onClick={() => {
                props.setShowStandardization((prevState) => !prevState);
                props.setMappings({});
              }}
            >
              &times;
            </span>

            <div className="flex flex-col w-full">
              <h1 className="text-center font-bold leading-6 text-gray-900 text-3xl">
                Run Standardization for tag: {props?.standardizeTag}
              </h1>
              <div className="flex h-full mt-10">
                <div className="w-1/4 p-2 space-y-4 relative h-full border-r border-gray-200 mr-10">
                  <h2 className="text-2xl">Choose Sensitivity</h2>
                  <div>
                    <StandardizationOption
                      setStandardSensitivity={props.setStandardSensitivity}
                      standardizeSensitivity={props.standardizeSensitivity}
                    />
                    <Checkbox
                      id="semantic-similarity"
                      onChange={() =>
                        props.setSemanticSimilarity(!props.semanticSimilarity)
                      }
                      value={props.semanticSimilarity}
                      label="Use language based similarity"
                    />
                    <div className="absolute left-0 bottom-0 w-full">
                      <button
                        className="run-standardization-button"
                        style={{ width: "50%" }}
                        onClick={handleRunStandardization}
                      >
                        Run
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-3/4 results p-2 h-full relative">
                  <p className="error-message">{errorMsg}</p>
                  {props.standardizeScreen === "mappings" && (
                    <div style={{ height: "85%", overflowY: "scroll" }}>
                      <MappingList
                        mappings={props.mappings}
                        setMappings={props.setMappings}
                        acceptNewValues={props.acceptNewValues}
                        standardizeTag={props.standardizeTag}
                        setStandardizeScreen={props.setStandardizeScreen}
                        standardizationLoading={props.standardizationLoading}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Standardization;
