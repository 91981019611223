import { useContext, useEffect } from "react";
import { DataContext } from "../../../../../context/DataContext";

export default function TagSelection(props) {
  const { availableTags } = useContext(DataContext);

  useEffect(() => {
    console.log(availableTags);
  });

  const tagCategories = Object.keys({
    ...availableTags.llm.tagger_params.tag_dict,
  });

  const allTagsSelected = tagCategories.every(
    (category) => props.selectedTags[category]
  );
  return (
    <div className="TagSelectionContainer">
      <div className="TagSelectionHeader">
        <div className="flex flex-col bg-white rounded-bg shadow-md mt-1 w-54 ml-10 mr-10">
          <div className="flex my-5 mx-2.5 flex-col">
            <label className="flex items-center cursor-pointer p-2">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-primary bg-primary border-gray-300 rounded focus:ring-primary checked:primary"
                onChange={(e) =>
                  props.setCheckSensitivity((prevState) => !prevState)
                }
                value={props.checkSensitivity}
              />
              <span className="ml-2 text-gray-700 focus:ring-primary">
                Sensitivity Check
              </span>
            </label>

            {props.checkSensitivity && (
              <div className="Explanation">
                <p>
                  <strong>Yes:</strong> Performs all sensitivity checks tagging
                  the dataset and if triggered quarantines the dataset
                  <br />
                  <strong>No:</strong> Tags the dataset without running
                  additional sensitivity checks before tagging
                </p>
              </div>
            )}
            {/* <label className="flex items-center cursor-pointer p-2">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-primary bg-primary border-gray-300 rounded focus:ring-primary checked:primary"
                  checked={createEmbeddings}
                  onChange={() => setCreateEmbeddings(!createEmbeddings)}
                />
                <span className="ml-2 text-gray-700 focus:ring-primary">
                  Create embeddings
                </span>
              </label>

              {createEmbeddings && (
                <div className="ml-2 p-1 bg-blue-50 border-l-4 border-primary text-primary">
                  <p>Requirement for Knowledge Assistant</p>
                </div>
              )} */}
            <label className="flex items-center cursor-pointer p-2">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-primary bg-primary border-gray-300 rounded focus:ring-primary checked:primary"
                onChange={(e) => props.handleSelectAllToggle(e, tagCategories)}
                checked={allTagsSelected}
              />
              <span className="ml-2 text-gray-700 focus:ring-primary">
                Select all available tags to be used for tagging
              </span>
            </label>
          </div>
        </div>
      </div>
      {tagCategories.map((category) => (
        <div key={category} className="TagCategory">
          <h3>{category}</h3>
          <label>
            <input
              type="checkbox"
              className="hiddenCheckbox"
              checked={props.selectedTags[category]}
              onChange={() => props.handleTagChange(category)}
            />
            <span className="customCheckbox"></span>
          </label>
        </div>
      ))}
    </div>
  );
}
