// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataFilterComponent{
    display: flex;
    flex-direction: column;
    flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/UseCaseLibrary/UseCaseLibraryComponents/DefineUseCaseSection/Components/DataFilterComponent/DataFilterComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAO;AACX","sourcesContent":[".DataFilterComponent{\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
