import { useCallback, useMemo, useState } from "react";
import { sendRequest } from "../components/utilities/functions/api";
import { useUserProfile } from "../context/UserProfile";
import Auth from "../auth/AuthProvider";
import { ENDPOINTS } from "../api/endpoints";
import Paginator from "../components/Paginator/Paginator";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaMinimize } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { RiTableAltFill } from "react-icons/ri";
import { FaPlay } from "react-icons/fa";
import { updateCatalog } from "../components/utilities/functions/apiCalls";
import { toast } from "../components/utilities/Toast";
import NavigationBar from "../components/utilities/NavigationBar/NavigationBar";
import { CgSpinnerTwo } from "react-icons/cg";
import { AvailableTagsResponse, CatalogResponse } from "../types";
import Workers from "../utils/threading";
const stringToColor = (stringValue: string, opacity = "40") => {
  let hash = 0;
  for (let i = 0; i < stringValue.length; i++) {
    hash = stringValue.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    // Use parts of the hash for different color components
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color + opacity;
};

const getCatalogAndTags = async (
  userProfile: unknown,
  selectedCatalog: string
) => {
  const [rawCatalogResponse, rawtagsResponse] = await Promise.all([
    sendRequest(
      {
        catalog_name: selectedCatalog,
        [(userProfile as { system: Record<string, string> }).system
          .API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser())
          .username,
      },
      ENDPOINTS["get_catalog"]
    ),
    sendRequest(
      {
        catalog_name: selectedCatalog,
        [(userProfile as { system: Record<string, string> }).system
          .API_USERNAME_KEYWORD]: (await Auth.currentAuthenticatedUser())
          .username,
      },
      ENDPOINTS["get_tags"]
    ),
  ]);

  const [catalogResponse, tagsResponse] = await Promise.all([
    (rawCatalogResponse as any).json(),
    (rawtagsResponse as any).json(),
  ]);

  return [catalogResponse, tagsResponse];
};

const sanitizeFilteredText = (text: string) => {
  return text.toLowerCase().replace(/ /g, "");
};

const AdvancedTagFiltersModal = ({
  currentFilters,
  advancedTaggingFilters,
  onCancel,
  onAccept,
}: {
  currentFilters: string[];
  advancedTaggingFilters: Record<string, string[]>;
  onCancel: () => void;
  onAccept: (newFilters: string[]) => void;
}) => {
  const [newFilters, setNewFilters] = useState(new Set(currentFilters));
  const [search, setSearch] = useState("");

  return (
    <div className="w-full p-4 flex flex-col overflow-hidden">
      <div>Select tags to filter</div>
      <input
        type="text"
        placeholder="Search Tag"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className="w-full rounded-md px-4 py-2 mt-4 outline-none border"
      />
      <div className="w-full overflow-auto">
        <table className="w-full">
          <tbody>
            {Object.keys(advancedTaggingFilters)
              .filter((tagKey) => {
                if (search) {
                  return tagKey.toLowerCase().includes(search.toLowerCase());
                }
                return true;
              })
              .map((tagKey, index) => {
                return (
                  <tr
                    key={`filter-tag-${tagKey}`}
                    className={index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"}
                  >
                    <td className="text-sm p-1">{tagKey}</td>
                    <td>
                      <div className="flex p-1 gap-1 flex-wrap">
                        <input
                          type="checkbox"
                          checked={newFilters.has(tagKey)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              newFilters.add(tagKey);
                            } else {
                              newFilters.delete(tagKey);
                            }
                            setNewFilters(new Set(newFilters));
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="flex mt-2 gap-2 w-full">
        <button
          onClick={() => {
            onAccept([...newFilters]);
          }}
          className="text-sm w-full text-center bg-blue-500 text-white rounded-md shadow-md px-4 py-2"
        >
          Apply
        </button>
        <button
          onClick={onCancel}
          className="text-sm w-full text-center bg-rose-400 text-white rounded-md shadow-md px-4 py-2"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const Catalog = () => {
  const [runningTasks, setRunningTasks] = useState<
    { id: string; type: string; description: string }[]
  >([]);
  const [selectedTagsToFilter, setSelectedTagsToFilter] = useState<string[]>(
    []
  );
  const [catalogResponse, setCatalogResponse] = useState<CatalogResponse>();
  const [showAdvancedTagFilters, setShowAdvancedTagFilters] = useState(false);
  const [taggingFilters, setTaggingFilters] = useState(
    {} as Record<string, string[]>
  );
  const [selectedCatalog, setSelectedCatalog] = useState("catalog");
  const [availableCatalogs, setAvailableCatalogs] = useState(["catalog"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const userProfile = useUserProfile();
  const [expandedFile, setExpandedFile] = useState("");
  const [actionsFile, setActionsFile] = useState("");
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState<"fileName" | "tagValue">(
    "fileName"
  );
  const [searchSelectedTag, setSearchSelectedTag] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState<Set<string>>(
    new Set()
  );
  const [selectedAction, setSelectedAction] = useState("");
  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState(new Set<string>());
  const [availableTagsResponse, setAvailableTagsResponse] =
    useState<AvailableTagsResponse>();
  const [tagFilterOpened, setTagFilterOpened] = useState("");
  const [tagFilterOpenedSearch, setTagFilterOpenedSearch] = useState("");

  const onRefreshTable = useCallback(async () => {
    try {
      const [catalogResponse, tagsResponse] = await getCatalogAndTags(
        userProfile,
        selectedCatalog
      );
      setAvailableCatalogs(catalogResponse.catalog_names);
      setAvailableTagsResponse(tagsResponse);
      setCatalogResponse(catalogResponse);

      const advancedTaggingFilters = await Workers.invoke(
        "getAdvancedTaggingFilters",
        catalogResponse.catalog
      );
      setAdvancedTaggingFilters(advancedTaggingFilters);
    } catch (error) {}
  }, [selectedCatalog, userProfile]);

  const filteredCatalog = useMemo(() => {
    if (!catalogResponse?.catalog) return;
    const returnableObject = { ...catalogResponse.catalog };
    if (search && searchTerm === "fileName") {
      for (const key in catalogResponse.catalog) {
        if (!sanitizeFilteredText(key).includes(sanitizeFilteredText(search))) {
          delete returnableObject[key];
        }
      }
    }
    if (search && searchTerm === "tagValue" && searchSelectedTag) {
      for (const key in catalogResponse.catalog) {
        const doc = catalogResponse.catalog[key];
        const value = doc[searchSelectedTag];
        if (key === "chunks" || !value || !Array.isArray(value) || !value[0]) {
          delete returnableObject[key];
        } else if (
          !sanitizeFilteredText(value[0]).includes(sanitizeFilteredText(search))
        ) {
          delete returnableObject[key];
        }
      }
    }
    if (Object.keys(taggingFilters).length) {
      for (const key in catalogResponse.catalog) {
        const doc = catalogResponse.catalog[key];
        for (const tagKey in taggingFilters) {
          const tagDocValues = doc[tagKey];
          if (!tagDocValues) {
            delete returnableObject[key];
            continue;
          }
          const desiredTagValues = taggingFilters[tagKey];
          if (
            !desiredTagValues.some((desiredTagValue) => {
              try {
                return tagDocValues.includes(desiredTagValue);
              } catch (error) {
                return false;
              }
            })
          ) {
            delete returnableObject[key];
          }
        }
      }
    }

    return returnableObject;
  }, [
    catalogResponse?.catalog,
    search,
    searchSelectedTag,
    searchTerm,
    taggingFilters,
  ]);
  const [advancedTaggingFilters, setAdvancedTaggingFilters] = useState(
    {} as Record<string, string[]>
  );

  return (
    <div className="fixed inset-0 flex flex-col overflow-hidden">
      {showActionModal && (
        <div className="absolute z-50 inset-0 p-4 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center">
          <div className="w-full max-w-xs p-4 bg-white rounded-md shadow-md flex flex-col gap-4">
            <div>
              You are about to run a{" "}
              <span className="font-medium">{selectedAction}</span> action over{" "}
              {selectedDocuments.size} documents. This action can not be undone.
              Do you want to continue?
            </div>
            <div className="flex mt-2 gap-2 w-full">
              <button
                className="text-sm w-full text-center bg-blue-500 text-white rounded-md shadow-md px-4 py-2"
                onClick={async () => {
                  if (!catalogResponse) return;
                  if (selectedAction === "delete") {
                    const newCatalog = { ...(catalogResponse.catalog || {}) };
                    selectedDocuments.forEach((doc) => {
                      delete newCatalog[doc];
                    });
                    updateCatalog(selectedCatalog, newCatalog).then(() => {
                      toast.success({
                        title: "Success",
                        description: "Successfully updated your catalog!",
                      });
                      catalogResponse.catalog = newCatalog;
                      setCatalogResponse({ ...catalogResponse });
                    });
                    toast.info({
                      title: "Info",
                      description: "Your catalog is being updated...",
                    });
                    setSelectedDocuments(new Set());
                    setShowActionModal(false);
                  }

                  if (selectedAction === "tag") {
                    const promises = [] as Promise<unknown>[];
                    const runningTasksToAppend = [] as typeof runningTasks;
                    selectedTags.forEach((selectedTag) => {
                      const tagDict = {
                        [selectedTag]:
                          availableTagsResponse?.tags.llm.tagger_params
                            .tag_dict[selectedTag],
                      };
                      const selectedDocumentsArr = [...selectedDocuments];
                      for (const docName of selectedDocumentsArr) {
                        const catalogItem = catalogResponse.catalog[docName];
                        const sendChunkObject = {
                          data_store: JSON.stringify({
                            ...(userProfile as any).webapp_profile.DATA_STORES[
                              catalogItem.storage_type[0]
                            ],
                            path: `${catalogItem.file_directory[0]}/${docName}`,
                          }),
                          tagger_list: JSON.stringify({
                            llm: {
                              tagger_params: {
                                model: {
                                  provider: (userProfile as any).webapp_profile
                                    .PROVIDER_USED,
                                  version: (userProfile as any).webapp_profile
                                    .MODEL_USED,
                                },
                                iters: 1,
                                tag_dict: tagDict,
                              },
                            },
                          }),
                          file_catalog_entry: JSON.stringify({
                            [docName]: catalogItem,
                          }),
                          catalog_name: selectedCatalog,
                          quarantine_name: (userProfile as any).system
                            .QUARANTINECATALOG,
                          [(userProfile as any).system.API_USERNAME_KEYWORD]: (
                            userProfile as any
                          ).username,
                          check_sensitivity: false,
                        };
                        const taskId = crypto.randomUUID();
                        runningTasksToAppend.push({
                          id: taskId,
                          type: "tagging",
                          description: `Tagging ${docName} with tag ${selectedTag}`,
                        });
                        promises.push(
                          sendRequest(
                            sendChunkObject,
                            ENDPOINTS["create_catalog"]
                          )
                            .catch(() => {})
                            .finally(() => {
                              setRunningTasks((runningTasks) => {
                                return runningTasks.filter(
                                  (task) => task.id !== taskId
                                );
                              });
                            })
                        );
                      }
                    });
                    toast.info({
                      title: "Info",
                      description:
                        "Your documents have been scheduled for tagging...",
                    });
                    setRunningTasks(runningTasksToAppend);
                    setSelectedDocuments(new Set());
                    setSelectedTags(new Set());
                    setShowActionModal(false);
                    Promise.all(promises).then(async () => {
                      toast.success({
                        title: "Success",
                        description:
                          "All of your documents have been successfully tagged!",
                      });
                      try {
                        const [catalogResponse, tagsResponse] =
                          await getCatalogAndTags(userProfile, selectedCatalog);
                        setAvailableCatalogs(catalogResponse.catalog_names);
                        setAvailableTagsResponse(tagsResponse);
                        setCatalogResponse(catalogResponse);
                      } catch (error) {}
                    });
                  }
                }}
              >
                Yes
              </button>
              <button
                className="text-sm w-full text-center bg-blue-600 text-white rounded-md shadow-md px-4 py-2"
                onClick={() => {
                  setShowActionModal(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <NavigationBar />
      {runningTasks.length > 0 && (
        <>
          <div className="w-52 p-4 flex flex-col bg-gray-100 border rounded-md absolute right-10 bottom-20 min-h-44 max-h-44 overflow-y-auto overflow-x-hidden gap-2">
            {runningTasks.map((task) => (
              <div className="text-sm break-words" key={task.id}>
                <span className="font-medium">{task.type}: </span>
                {task.description}
              </div>
            ))}
          </div>

          <div className="animate-spin right-4 bottom-4 bg-primary text-white text-4xl rounded-full p-2 absolute">
            <CgSpinnerTwo />
          </div>
        </>
      )}
      {showAdvancedTagFilters && (
        <div className="absolute z-50 inset-0 p-4 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center">
          <div className="w-full max-w-lg max-h-[50vh] p-4 bg-white rounded-md shadow-md flex flex-col gap-4 overflow-hidden">
            <AdvancedTagFiltersModal
              onCancel={() => setShowAdvancedTagFilters(false)}
              onAccept={(newFilters) => {
                setSelectedTagsToFilter(newFilters);
                setShowAdvancedTagFilters(false);
              }}
              currentFilters={selectedTagsToFilter}
              advancedTaggingFilters={advancedTaggingFilters}
            />
          </div>
        </div>
      )}
      <div className="bg-slate-200 w-full h-full flex justify-center overflow-hidden">
        <div className="flex flex-col w-full h-full p-4 items-center">
          <div className="flex items-center bg-white shadow-md w-full gap-2 rounded-t-md overflow-hidden py-2 px-2">
            <div className="px-4">
              <IoSearch />
            </div>
            <input
              type="text"
              placeholder="Search File"
              value={search}
              onChange={(e) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
              className="w-full pr-4 py-2 outline-none"
            />

            <select
              className="hidden rounded-md text-sm border px-2 py-2 outline-none"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value as "fileName" | "tagValue");
              }}
            >
              <option value="fileName">File name</option>
              <option value="tagValue">Tag value</option>
            </select>
            <select
              className={`hidden rounded-md text-sm border px-2 py-2 outline-none ${searchTerm === "tagValue" ? "opacity-100" : "opacity-50"}`}
              value={searchSelectedTag}
              disabled={searchTerm !== "tagValue"}
              onChange={(e) => {
                setSearchSelectedTag(e.target.value);
              }}
            >
              <option value="">Select a tag</option>
              {availableTagsResponse &&
                Object.keys(
                  availableTagsResponse.tags?.llm?.tagger_params?.tag_dict || {}
                ).map((tag) => (
                  <option key={tag} value={tag}>
                    {tag}
                  </option>
                ))}
            </select>
            <button
              className="whitespace-nowrap bg-blue-500 text-sm text-white px-4 py-2 rounded-md"
              onClick={() => setShowAdvancedTagFilters(true)}
            >
              Select Tag Filters
            </button>
            <button
              disabled={Object.values(taggingFilters).flat().length === 0}
              className={`whitespace-nowrap bg-rose-400 text-sm text-white px-4 py-2 rounded-md ${Object.values(taggingFilters).flat().length === 0 ? "opacity-50" : "opacity-100"}`}
              onClick={() => {
                setTaggingFilters({});
                setSearch("");
                setSearchTerm("fileName");
              }}
            >
              Clear all Filters
            </button>
          </div>
          {selectedTagsToFilter.length !== 0 && (
            <div className="w-full bg-blue-50 flex gap-1 px-4 py-2 text border-y">
              {selectedTagsToFilter.map((tag) => (
                <div
                  className="relative text-sm select-none bg-blue-500 text-white rounded-md py-1 px-2 border cursor-pointer"
                  onClick={() => {
                    setTagFilterOpened(tag);
                    setTagFilterOpenedSearch("");
                  }}
                >
                  {tag} {`(${Object.keys(taggingFilters[tag] || {}).length})`}
                  {tagFilterOpened === tag && (
                    <>
                      <div className="text-zinc-600 absolute top-[calc(100%_+_4px)] left-0 z-[100] min-w-[200px] max-w-[300px] overflow-hidden bg-white border rounded-md">
                        <div>
                          <input
                            type="text"
                            className="w-full bg-white px-4 py-2 outline-none"
                            placeholder="Search Value"
                            value={tagFilterOpenedSearch}
                            onChange={(e) => {
                              setTagFilterOpenedSearch(e.target.value);
                            }}
                          />
                        </div>
                        <div className="max-h-[200px] overflow-auto w-full">
                          {advancedTaggingFilters[tag]
                            .filter((value) => {
                              if (tagFilterOpenedSearch) {
                                return value
                                  .toLowerCase()
                                  .includes(
                                    tagFilterOpenedSearch.toLowerCase()
                                  );
                              }
                              return true;
                            })
                            .map((value, index) => {
                              const isTagValueSelected =
                                !!taggingFilters[tag]?.includes(value);
                              return (
                                <div
                                  className={`px-4 py-2 ${isTagValueSelected ? "bg-slate-600 text-white" : index % 2 === 0 ? "bg-slate-50 hover:bg-slate-100" : "bg-white hover:bg-slate-100"}`}
                                  onClick={() => {
                                    taggingFilters[tag] =
                                      taggingFilters[tag] || [];
                                    if (isTagValueSelected) {
                                      taggingFilters[tag] = taggingFilters[
                                        tag
                                      ].filter((val) => val !== value);
                                      if (taggingFilters[tag].length === 0) {
                                        delete taggingFilters[tag];
                                      }
                                    } else {
                                      taggingFilters[tag].push(value);
                                    }
                                    setTaggingFilters({ ...taggingFilters });
                                  }}
                                >
                                  {value}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div
                        className="fixed z-[99] inset-0 cursor-default"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTagFilterOpened("");
                          setTagFilterOpenedSearch("");
                        }}
                      ></div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="w-full h-full  bg-white overflow-auto relative">
            {!filteredCatalog && (
              <div className="absolute inset-0 bg-white flex justify-center items-center">
                <div className="text-gray-200 animate-pulse text-6xl">
                  <RiTableAltFill />
                </div>
              </div>
            )}
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="text-xs uppercase">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 w-10 top-0 sticky bg-gray-100 z-40"
                  >
                    <input
                      type="checkbox"
                      className="mt-0.5"
                      checked={
                        Object.entries(filteredCatalog || {})
                          .slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          )
                          .filter(([key]) => {
                            return selectedDocuments.has(key);
                          }).length ===
                        Object.entries(filteredCatalog || {}).slice(
                          (currentPage - 1) * pageSize,
                          currentPage * pageSize
                        ).length
                      }
                      onChange={(e) => {
                        Object.entries(filteredCatalog || {})
                          .slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          )
                          .forEach(([key]) => {
                            e.target.checked
                              ? selectedDocuments.add(key)
                              : selectedDocuments.delete(key);
                          });
                        setSelectedDocuments(new Set(selectedDocuments));
                      }}
                    />
                  </th>
                  <th
                    scope="col"
                    className="pr-6 py-3 w-96 top-0 sticky bg-gray-100 z-40"
                  >
                    File name
                  </th>
                  <th
                    scope="col"
                    className="text-left px-6 py-3 top-0 sticky bg-gray-100 z-40"
                  >
                    Tags
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 w-20 top-0 sticky bg-gray-100 z-40"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {Object.entries(filteredCatalog || {})
                  .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map(([docKey, tags]) => {
                    const isExpanded = docKey === expandedFile;
                    const isActionsOpened = docKey === actionsFile;
                    return (
                      <tr className="bg-white border-b" key={docKey}>
                        <td className="w-2">
                          <div className="justify-center items-center flex">
                            <input
                              type="checkbox"
                              checked={selectedDocuments.has(docKey)}
                              onChange={() => {
                                setSelectedDocuments((selectedDocuments) => {
                                  if (selectedDocuments.has(docKey)) {
                                    selectedDocuments.delete(docKey);
                                    return new Set(selectedDocuments);
                                  }
                                  selectedDocuments.add(docKey);
                                  return new Set(selectedDocuments);
                                });
                              }}
                            />
                          </div>
                        </td>
                        <td className="pr-6 py-4 text-sm w-96 break-all text-gray-700">
                          {docKey}
                        </td>
                        <td className="px-6 py-4 flex gap-1">
                          <div className="flex-col flex">
                            <div
                              className={`text-xs h-6 flex items-center justify-center transition-all cursor-pointer py-1 px-2 rounded-md text-white ${isExpanded ? "bg-sky-400" : "bg-blue-500"}`}
                              onClick={() => {
                                setExpandedFile(isExpanded ? "" : docKey);
                              }}
                            >
                              {isExpanded ? (
                                <FaMinimize />
                              ) : (
                                <FaExpandArrowsAlt />
                              )}
                            </div>
                          </div>
                          <div
                            className={`flex gap-1 transition-all flex-wrap ${isExpanded ? "max-h-none overflow-auto" : "max-h-6 overflow-hidden"}`}
                          >
                            {Object.entries(tags)
                              .filter(([key, value]) => {
                                return (
                                  key !== "chunks" &&
                                  value &&
                                  Array.isArray(value) &&
                                  value[0]
                                );
                              })
                              .map(([tagKey, value]) => (
                                <div
                                  className="text-xs py-1 px-2 rounded-md"
                                  key={`${docKey}-${tagKey}-${value}`}
                                  style={{
                                    backgroundColor:
                                      stringToColor(
                                        (value as string[])[0],
                                        "40"
                                      ) || "#80808028",
                                  }}
                                >
                                  <span className="font-bold text-black">
                                    {tagKey}:
                                  </span>{" "}
                                  {(value as string[])[0]}
                                </div>
                              ))}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex justify-end relative">
                            <BsThreeDotsVertical
                              className="cursor-pointer"
                              onClick={() => {
                                setActionsFile(isActionsOpened ? "" : docKey);
                              }}
                            />
                            {isActionsOpened && (
                              <div className="absolute z-30 select-none top-[calc(100%_+_8px)] w-40 bg-white border rounded-md shadow-md flex flex-col">
                                <div
                                  className="fixed inset-0 z-20"
                                  onClick={() => setActionsFile("")}
                                ></div>
                                <div
                                  className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                                  onClick={async () => {
                                    if (!filteredCatalog) return;
                                    const file = filteredCatalog[docKey];
                                    const fileDirectory =
                                      file["file_directory"][0];
                                    const storageName = file["storage_name"][0];
                                    const storageType = file["storage_type"][0];
                                    const response = await sendRequest(
                                      {
                                        [(userProfile as any).system
                                          .API_USERNAME_KEYWORD]: (
                                          await Auth.currentAuthenticatedUser()
                                        ).username,
                                      },
                                      `/api/catalog/download?file_directory=${fileDirectory}&storage_type=${storageType}&storage_name=${storageName}&file_name=${docKey}`,
                                      "GET"
                                    );
                                    if (
                                      !response ||
                                      typeof response === "string"
                                    )
                                      return;

                                    const { data } = await response.json();
                                    window.open(data.url, "_blank");
                                    setActionsFile("");
                                  }}
                                >
                                  Open
                                </div>
                                <div
                                  className="cursor-pointer  z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                                  onClick={() => {
                                    setSelectedDocuments(new Set([docKey]));
                                    setSelectedAction("delete");
                                    setShowActionModal(true);
                                    setActionsFile("");
                                  }}
                                >
                                  Delete
                                </div>
                                <div
                                  className="cursor-pointer  z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                                  onClick={() => setActionsFile("")}
                                >
                                  Evidence
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="bg-white shadow-md w-full  rounded-b-md">
            <Paginator
              onNewPage={(newPage) => setCurrentPage(newPage)}
              currentPage={currentPage}
              onNewPageSize={(newPageSize) => {
                setCurrentPage(1);
                setPageSize(newPageSize);
              }}
              pageSize={pageSize}
              numberOfItems={Object.keys(filteredCatalog || {}).length}
            />
          </div>
        </div>
        <div className="w-full h-full max-w-xs p-4 pl-0">
          <div className="overflow-hidden bg-white shadow-md rounded-md w-full h-full flex flex-col">
            <div className="w-full flex flex-col gap-2 items-center p-4 bg-gray-100 shrink-0">
              <div className="text-nowrap w-full text-sm font-medium">
                Selected Catalog
              </div>
              <select
                value={selectedCatalog}
                className="px-4 py-2 border rounded-md text-sm outline-none truncate w-full"
                onChange={(e) => {
                  setCurrentPage(1);
                  setCatalogResponse(undefined);
                  setSelectedDocuments(new Set());
                  setSelectedCatalog(e.target.value);
                }}
                name=""
                id=""
              >
                {availableCatalogs.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full flex flex-col gap-2 overflow-hidden items-center p-4 border-t border-t-gray-300 bg-gray-100 shrink-0">
              <div className="text-nowrap w-full text-sm font-medium">
                Selected Documents ({selectedDocuments.size}){" "}
                <span className="underline">view</span>
              </div>
              <div className="flex gap-2 w-full overflow-hidden"></div>
              <div className="flex mt-2 gap-2 w-full">
                <button
                  className={`text-sm w-full text-center bg-blue-500 text-white rounded-md shadow-md px-4 py-2 ${selectedDocuments.size === Object.keys(filteredCatalog || {}).length ? "opacity-50" : "opacity-100"}`}
                  onClick={() => {
                    setSelectedDocuments(
                      new Set(Object.keys(catalogResponse?.catalog || {}))
                    );
                  }}
                >
                  Select All
                </button>
                <button
                  className={`text-sm w-full text-center bg-rose-400 text-white rounded-md shadow-md px-4 py-2 ${selectedDocuments.size === 0 ? "opacity-50" : "opacity-100"}`}
                  disabled={selectedDocuments.size === 0}
                  onClick={() => {
                    setSelectedDocuments(new Set());
                  }}
                >
                  Deselect All
                </button>
              </div>
            </div>
            <div className="w-full flex flex-col gap-4 overflow-hidden items-center p-4 border-t border-t-gray-300 bg-gray-100 h-full">
              <div className="text-nowrap w-full text-sm font-medium">
                Run actions on {selectedDocuments.size} documents
              </div>
              <div className="flex gap-2 w-full overflow-hidden shrink-0">
                <div className="flex flex-col  w-full">
                  <div className="text-xs">Action</div>
                  <select
                    className="px-4 border py-2 rounded-md text-sm outline-none truncate w-full"
                    value={selectedAction}
                    onChange={(e) => setSelectedAction(e.target.value)}
                  >
                    <option value="">Select an action</option>
                    <option value="delete">Delete</option>
                    <option value="tag">Tag</option>
                  </select>
                </div>
              </div>
              {selectedAction === "tag" && (
                <div className="flex flex-col gap-2 w-full h-full">
                  <div className="text-xs">Tags to apply</div>
                  <div className="bg-gray-100 p-2 border rounded-md h-full w-full">
                    <div className="flex gap-2 w-full overflow-auto">
                      <table className="w-full">
                        <thead>
                          <tr className="border-b">
                            <th className="text-left">
                              <input
                                type="checkbox"
                                checked={selectedTags.size > 0}
                                onChange={(e) => {
                                  if (
                                    e.target.checked &&
                                    availableTagsResponse
                                  ) {
                                    setSelectedTags(
                                      new Set(
                                        Object.keys(
                                          availableTagsResponse.tags.llm
                                            .tagger_params.tag_dict
                                        )
                                      )
                                    );
                                  } else {
                                    setSelectedTags(new Set());
                                  }
                                }}
                              />
                            </th>
                            <th className="text-sm text-left">Name</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm">
                          {availableTagsResponse &&
                            Object.keys(
                              availableTagsResponse.tags.llm.tagger_params
                                .tag_dict
                            ).map((tag) => (
                              <tr key={`table-${tag}`} className="border-b">
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedTags.has(tag)}
                                    onChange={(e) => {
                                      if (selectedTags.has(tag)) {
                                        selectedTags.delete(tag);
                                      } else {
                                        selectedTags.add(tag);
                                      }

                                      setSelectedTags(new Set(selectedTags));
                                    }}
                                  />
                                </td>
                                <td>{tag}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex mt-2 gap-2 w-full">
                <button
                  className={`text-sm w-full flex justify-center items-center bg-blue-500 text-white rounded-md px-4 py-2 h-9 ${!selectedDocuments.size || !selectedAction || (selectedAction === "tag" && selectedTags.size === 0) ? "bg-opacity-20" : "opacity-100"}`}
                  onClick={() => {
                    if (!selectedDocuments.size) return;
                    if (!selectedAction) return;
                    if (selectedAction === "tag" && selectedTags.size === 0)
                      return;

                    setShowActionModal(true);
                  }}
                >
                  <FaPlay />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
