// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.evidence-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.evidence-table th,
.evidence-table td {
  border: 1px solid #dee2e6;
  text-align: left;
  padding: 8px;
}

.evidence-table tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.evidence-table tbody tr:hover {
  background-color: #c3ffd752;
}

.modal-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}

.filtered-count {
  font-size: 13px;
  color: #333;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: flex-end;
}

.download-button {
  background: none;
  border: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/DataCatalog/DataCatalogComponents/DataList/DataListComponent/EvidenceTable/EvidenceTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".evidence-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\n.evidence-table th,\n.evidence-table td {\n  border: 1px solid #dee2e6;\n  text-align: left;\n  padding: 8px;\n}\n\n.evidence-table tbody tr:nth-child(odd) {\n  background-color: #f8f9fa;\n}\n\n.evidence-table tbody tr:hover {\n  background-color: #c3ffd752;\n}\n\n.modal-filter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 20px;\n}\n\n.filtered-count {\n  font-size: 13px;\n  color: #333;\n}\n\n.modal-header {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  align-items: flex-end;\n}\n\n.download-button {\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
