import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchDocumentContent } from "../functions/apiCalls";
import { DataContext } from "../../../context/DataContext";

import {
  faEye,
  faFolder,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FileCheckbox } from "../FileCheckbox";

const FolderList = ({
  folders,
  currentFolder,
  checkedItems,
  setCheckedItems,
  searchText,
  setSearchText,
  setShowFilePreview,
  setFilePreviewContent,
  filteredFolderKeys,
  setCurrentFolder,
  integration,
}) => {
  const { catalogFiles, quarantinedFiles, preferences } =
    useContext(DataContext);

  const handleCheckboxChange = (file, folderKey) => {
    setCheckedItems((prevState) => {
      const isChecked = !(prevState[file]?.isChecked ?? false);
      if (!isChecked && prevState[file]) {
        const { [file]: removedFile, ...rest } = prevState;
        return rest;
      }
      return {
        ...prevState,
        [file]: {
          isChecked,
          folder: folderKey,
          source: integration,
        },
      };
    });
  };
  const handleFolderCheckboxChange = (folderKey) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      const filesInFolder = folders[folderKey] || [];
      const allChecked = filesInFolder.every(
        (file) => newCheckedItems[file]?.isChecked
      );

      if (allChecked) {
        filesInFolder.forEach((file) => {
          if (newCheckedItems[file]) {
            delete newCheckedItems[file];
          }
        });
      } else {
        filesInFolder.forEach((file) => {
          newCheckedItems[file] = {
            isChecked: true,
            folder: folderKey,
            source: integration,
          };
        });
      }

      return newCheckedItems;
    });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFilePreviewClick = async (file, folderKey) => {
    const filePath = `${preferences.webapp_profile.DATA_STORES[integration].base_path}${folderKey}/${file}`;
    try {
      const content = await fetchDocumentContent(filePath, preferences.webapp_profile.DATA_STORES);
      setFilePreviewContent(content);
      setShowFilePreview(true);
    } catch (error) {
      console.error("Error fetching document content", error);
    }
  };

  const handleFolderClick = (folderKey) => {
    setCurrentFolder((prevFolder) =>
      prevFolder === folderKey ? null : folderKey
    );
  };

  const renderItems = () => {
    return (
      <>
        <div className="w-full">
          <input
            type="text"
            placeholder="Search..."
            className="w-full px-4 py-2 rounded-t-md outline-none border"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
        <div className="flex flex-col overflow-hidden">
          <ul className="w-full text-sm h-full overflow-auto">
            {filteredFolderKeys &&
              filteredFolderKeys.map((folderKey) => {
                if (!folders[folderKey]) return <></>;
                const totalFiles = folders[folderKey].length || 0;
                const containedFiles = folders[folderKey].filter(
                  (file) => catalogFiles[file] || quarantinedFiles[file]
                ).length;
                const containedFilesPercentage = Math.round(
                  (containedFiles / totalFiles) * 100
                );
                const isFolderSelected = folders[folderKey].every(
                  (file) => checkedItems[file]?.isChecked
                );
                return (
                  <li
                    key={folderKey}
                    className={`border-b px-2 ${
                      isFolderSelected ? "selected" : ""
                    } ${
                      containedFilesPercentage === 100
                        ? "!bg-primary !bg-opacity-40"
                        : "bg-slate-100"
                    }`}
                  >
                    <div
                      className={`flex gap-2 items-center cursor-pointer py-2`}
                      onClick={() => handleFolderClick(folderKey)}
                    >
                      <FontAwesomeIcon
                        icon={
                          currentFolder === folderKey
                            ? faChevronDown
                            : faChevronRight
                        }
                      />

                      <input
                        type="checkbox"
                        id={`checkbox-folder-${folderKey}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={() => handleFolderCheckboxChange(folderKey)}
                        checked={
                          folders[folderKey] &&
                          folders[folderKey].every(
                            (file) => checkedItems[file]?.isChecked
                          )
                        }
                      />
                      <div htmlFor={`checkbox-folder-${folderKey}`}>
                        <FontAwesomeIcon
                          icon={faFolder}
                          className="pr-1 text-yellow-400"
                        />
                        {folderKey}
                      </div>
                      <span className="file-count">
                        {totalFiles} files (
                        {containedFilesPercentage.toFixed(0)}% contained)
                      </span>
                    </div>
                    {currentFolder === folderKey && (
                      <ul className="file-tree">
                        {folders &&
                          folders[currentFolder] &&
                          folders[currentFolder]
                            .filter((file) =>
                              file
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            )
                            .map((file, index) => {
                              const isContained =
                                catalogFiles[file] || quarantinedFiles[file];
                              const isFileSelected =
                                checkedItems[file]?.isChecked ?? false;

                              return (
                                <li
                                  key={file}
                                  className={`p-2 flex gap-2 items-center border-b border-x justify-between md:flex-nowrap flex-wrap break-all ${
                                    isContained
                                      ? "bg-primary bg-opacity-30"
                                      : index % 2
                                        ? "bg-slate-50"
                                        : "bg-slate-100"
                                  }`}
                                >
                                  <FileCheckbox
                                    fileName={file}
                                    isSelected={
                                      checkedItems[file]?.isChecked ?? false
                                    }
                                    onClick={() =>
                                      handleCheckboxChange(file, currentFolder)
                                    }
                                  />
                                  <div className="md:w-auto w-full flex justify-end">
                                    <button
                                      onClick={() =>
                                        handleFilePreviewClick(
                                          file,
                                          currentFolder
                                        )
                                      }
                                      className="bg-primary px-2 py-1 text-white rounded-md whitespace-nowrap"
                                    >
                                      <FontAwesomeIcon icon={faEye} /> Preview
                                    </button>
                                  </div>
                                </li>
                              );
                            })}
                      </ul>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    );
  };

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      {renderItems()}
    </div>
  );
};

export default FolderList;
