import { Auth as AWSAuth } from "aws-amplify";
import { IAuthProvider, IAWSUser } from "./IAuthProvider";

export class AWSAuthProvider implements IAuthProvider<IAWSUser> {
  async currentAuthenticatedUser(): Promise<IAWSUser> {
    return await AWSAuth.currentAuthenticatedUser();
  }

  async getIdToken(): Promise<string> {
    return (await AWSAuth.currentSession()).getIdToken().getJwtToken();
  }

  async getAccessToken(): Promise<string> {
    return (await AWSAuth.currentSession()).getAccessToken().getJwtToken();
  }
}