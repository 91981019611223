import "./Rules.css";
import React, { useContext, useEffect, useState } from "react";
import { COLOURS } from "../../../../../assets/colours";
import {
  uploadRules,
  getRules,
} from "../../../../utilities/functions/apiCalls";
import { DataContext } from "../../../../../context/DataContext";
import { useUserProfile } from "../../../../../context/UserProfile";

export default function AddRules() {
  const { availableRules, setAvailableRules, availableTags, usedCatalog } =
    useContext(DataContext);
  const [rules, setRules] = useState([]);
  const [currentRule, setCurrentRule] = useState({
    rules: [],
    output_tag: "",
    output_value: "",
  });
  const [currentCondition, setCurrentCondition] = useState({
    tag_name: "",
    value: "",
  });
  const { permissions } = useUserProfile();

  useEffect(() => {
    getRules(usedCatalog)
      .then((fetchedRules) => {
        setRules(fetchedRules);
      })
      .catch((error) => {
        console.error("Failed to fetch rules:", error);
        alert("Failed to load rules.");
      });
  }, []);

  const addCondition = () => {
    if (!currentCondition.tag_name) {
      alert("Please select a tag from the dropdown.");
      return;
    }
    setCurrentRule((prevRule) => ({
      ...prevRule,
      rules: [...prevRule.rules, currentCondition],
    }));
    setCurrentCondition({ tag_name: "", value: "" });
  };

  const saveRule = () => {
    if (!currentRule.rules || currentRule.rules.length === 0) {
      alert("Please add a condition.");
      return;
    }

    if (!currentRule.output_tag) {
      alert("Please give an output tag.");
      return;
    }

    const tags = Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...availableTags.sensitivity.tagger_params.tag_dict,
    });

    if (tags.includes(currentRule.output_tag)) {
      alert("Tag exists, please use a different name for the output.");
      return;
    }

    setRules([...rules, currentRule]);
    setCurrentRule({ rules: [], output_tag: "", output_value: "" });
  };

  const handleConditionChange = (field, value) => {
    setCurrentCondition({ ...currentCondition, [field]: value });
  };

  const handleOutputChange = (field, value) => {
    setCurrentRule({ ...currentRule, [field]: value });
  };

  const removeCondition = (index) => {
    setCurrentRule((prevRule) => ({
      ...prevRule,
      rules: prevRule.rules.filter((_, i) => i !== index),
    }));
  };

  const removeRule = (index) => {
    setRules(rules.filter((_, i) => i !== index));
  };

  const uploadAllRules = () => {
    uploadRules(rules, usedCatalog)
      .then(() => {
        setAvailableRules(rules);
        alert("Rules successfully saved!");
      })
      .catch((error) => {
        console.error("Failed to upload rules:", error);
        alert("Failed to upload rules.");
      });
  };

  return (
    <div className="add-rules-container w-full max-w-6xl">
      {permissions.rules.canEdit && (
        <div className="conditions-container">
          <div className="rule-conditions">
            <h3 className="text-primary">Conditions</h3>
            {currentRule.rules.map((condition, index) => (
              <div key={index} className="condition">
                <span>
                  {condition.tag_name}: {condition.value}
                </span>
                <button
                  className="rules-button bg-primary"
                  onClick={() => removeCondition(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <select
              className="rules-dropdown"
              value={currentCondition.tag_name}
              onChange={(e) =>
                handleConditionChange("tag_name", e.target.value)
              }
            >
              <option value="" disabled>
                Select a tag
              </option>
              {Object.keys({
                ...availableTags.llm.tagger_params.tag_dict,
                ...availableTags.sensitivity.tagger_params.tag_dict,
              }).map((tag) => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="rules-input"
              placeholder="Value"
              value={currentCondition.value}
              onChange={(e) => handleConditionChange("value", e.target.value)}
            />
            <button className="rules-button bg-primary" onClick={addCondition}>
              Add Condition
            </button>
          </div>

          <div className="rule-output">
            <h3>Output</h3>
            <input
              type="text"
              className="rules-input"
              placeholder="Output Tag"
              value={currentRule.output_tag}
              onChange={(e) => handleOutputChange("output_tag", e.target.value)}
            />
            <input
              type="text"
              className="rules-input"
              placeholder="Output Value"
              value={currentRule.output_value}
              onChange={(e) =>
                handleOutputChange("output_value", e.target.value)
              }
            />
            <button className="rules-button bg-primary" onClick={saveRule}>
              Save Rule
            </button>
          </div>
        </div>
      )}
      <div className="all-rules-section">
        <h3>All Rules</h3>
        {rules && rules.length > 0 ? (
          <div className="all-rules-list">
            {rules.map((rule, index) => (
              <div key={index} className="rule">
                <div className="rule-details">
                  <div className="rule-conditions">
                    <h4>Conditions</h4>
                    <ul>
                      {rule.rules.map((cond, idx) => (
                        <li key={idx}>
                          <strong>{cond.tag_name}</strong>: {cond.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="rule-output">
                    <h4 className="text-primary">Output</h4>
                    <p>
                      <strong>{rule.output_tag}</strong>: {rule.output_value}
                    </p>
                  </div>
                </div>
                {permissions.rules.canEdit && (
                  <button
                    className="rules-button bg-primary"
                    onClick={() => removeRule(index)}
                  >
                    Remove Rule
                  </button>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No rules created yet.</p>
        )}

        {permissions.rules.canEdit && (
          <button
            className="upload-rules-btn bg-primary"
            onClick={uploadAllRules}
          >
            Save All Rules
          </button>
        )}
      </div>
    </div>
  );
}
