import TaggingStudio from "./TaggingStudio";
import React, { useContext, useState } from "react";
import { COLOURS } from "../../../../../assets/colours";
import { RiExpandLeftFill, RiExpandRightFill } from "react-icons/ri";
import {
  DataContext,
  defaultCurrentTag,
} from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";
import Tags from "../DataCatalog/DataCatalogComponents/Tags/Tags";
import { mergeTagWithDefaults } from "../../../../utilities/functions/utils";
import { Checkbox } from "../../../../utilities/Checkbox";

const sensitivityTagType = "Sensitivity";

export default function AddNewTag() {
  const [currentValue, setCurrentValue] = useState("");
  const [validatedTags, setValidatedTags] = useState({});
  const { currentTag, setCurrentTag } = useContext(DataContext);
  const { saveTag, defaultTagTypes } = useContext(TagContext);
  const [showTaggingStudio, setShowTaggingStudio] = useState(false);
  const [previousExamples, setPreviousExamples] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTaggingStudio = () => {
    const mergedTag = mergeTagWithDefaults(currentTag, defaultCurrentTag);
    setCurrentTag({ ...mergedTag });
    setShowTaggingStudio(!showTaggingStudio);
  };

  const clearCurrentTag = () => {
    setCurrentTag({ ...defaultCurrentTag });
    setPreviousExamples(null);
    setShowTooltip(false);
  };

  const handleAddValue = (e) => {
    if (e.key === "Enter" && currentValue.trim()) {
      setCurrentTag((prevTag) => ({
        ...prevTag,
        availableValues: [
          ...(prevTag.availableValues || []),
          currentValue.trim(),
        ],
      }));
      setCurrentValue("");
      e.preventDefault();
    }
  };

  const handleDeleteValue = (value) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      availableValues: prevTag.availableValues.filter((item) => item !== value),
    }));
  };

  const handleChangeOption = (option) => {
    switch (option) {
      case "yesNo":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: ["Yes", "No"],
          max_words: 1,
          allow_other_values: false,
          reference_file: "",
          option: "yesNo",
        }));
        break;
      case "fileUpload":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: ["Yes", "No"],
          max_words: 1,
          allow_other_values: false,
          option: "fileUpload",
        }));
        break;
      case "aiGenerated":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: [],
          allow_other_values: true,
          reference_file: "",
          max_words: 2,
          option: "aiGenerated",
        }));
        break;
      case "custom":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: prevTag.availableValues,
          allow_other_values: false,
          reference_file: "",
          option: "custom",
        }));
        break;
      default:
        break;
    }
  };

  const changeType = (type) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      type: type || "word",
    }));
  };

  const changeIsDocumentLevel = (isDocumentLevel) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      is_document_level: isDocumentLevel,
    }));
  };

  const handleTagTypeChange = (type) => {
    setCurrentTag((prevTag) => {
      const newTag = { ...prevTag };
      if (type === defaultTagTypes["sensitivity"]) {
        newTag.tagType = defaultTagTypes["sensitivity"];
        newTag.risk_level = newTag.risk_level || "High";
      } else {
        newTag.tagType = newTag.tagType === type ? "" : type;
      }
      return newTag;
    });
  };

  const handleRiskLevelChange = (level) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      risk_level: level,
    }));
  };

  const addExamplePair = () => {
    const updatedExamples = currentTag.examples
      ? [...currentTag.examples, { evidence: "", value: "" }]
      : [{ evidence: "", value: "" }];

    setCurrentTag({
      ...currentTag,
      examples: updatedExamples,
    });
  };

  const updateExample = (index, field, value) => {
    const newExamples = [...currentTag.examples];
    newExamples[index] = {
      ...newExamples[index],
      [field]: value,
    };
    setCurrentTag({
      ...currentTag,
      examples: newExamples,
    });
  };

  const deleteExamplePair = (index) => {
    const newExamples = currentTag.examples.filter((_, i) => i !== index);
    setCurrentTag({
      ...currentTag,
      examples: newExamples,
    });
  };

  return (
    <div className="relative flex w-[90vw] overflow-hidden h-[90vh]">
      <div className={`flex flex-row gap-4 ${!showTaggingStudio && "w-full"}`}>
        <div
          className={`bg-white rounded-md flex flex-col text-sm ${showTaggingStudio ? "pb-8" : "max-w-full pb-4"}`}
          style={{ display: showTaggingStudio ? "none" : "block" }}
        >
          <Tags title="Existing Tags" tagTypes={["llm", "sensitivity"]} />
        </div>
        <div
          className={`h-full bg-white rounded-md overflow-auto flex flex-col text-sm ${showTaggingStudio ? "" : "w-full"} ${showTaggingStudio ? "extra-scrollable-padding" : ""}`}
        >
          <div className="h-full overflow-y-auto hide-scrollbar flex flex-col mb-5">
            <div
              style={{
                backgroundColor: COLOURS["HeaderBackground"],
                color: COLOURS["MainText"],
              }}
              className="flex flex-row justify-between"
            >
              <header className="flex z-30 p-3 font-bold">
                {showTaggingStudio ? "Fine-Tune Tag" : "Add a new Tag"}
              </header>
              {!showTaggingStudio && (
                <button
                  onClick={() => {
                    clearCurrentTag();
                  }}
                  className="text-sm bg-gray-500 hover:bg-red-600 text-white py-1 px-3 rounded cursor-pointer transition ease-in duration-200 rounded-l-none"
                >
                  Clear Tag Selection
                </button>
              )}
            </div>

            <div className="flex flex-col p-5 gap-4 h-full hide-scrollbar">
              <div className="flex flex-col">
                <p className="font-semibold text-lg">1. Tag Name</p>
                <input
                  type="text"
                  placeholder="Enter tag name"
                  className="px-4 py-2 outline-none border rounded-md"
                  value={currentTag.name}
                  onChange={(e) =>
                    setCurrentTag((prevTag) => ({
                      ...prevTag,
                      name: e.target.value.replace(/ /g, "_"),
                    }))
                  }
                />
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-lg">2. Type of Tag</p>
                <div className="flex gap-2">
                  <div
                    className={`px-4 py-2 border rounded-md cursor-pointer ${currentTag?.tagType === defaultTagTypes["sensitivity"] ? "bg-zinc-200" : ""}`}
                    onClick={() =>
                      handleTagTypeChange(defaultTagTypes["sensitivity"])
                    }
                  >
                    {defaultTagTypes["sensitivity"]}
                  </div>
                  <div
                    className={`px-4 py-2 border rounded-md cursor-pointer ${
                      currentTag?.tagType === defaultTagTypes["classification"]
                        ? "bg-zinc-200"
                        : ""
                    }`}
                    onClick={() =>
                      handleTagTypeChange(defaultTagTypes["classification"])
                    }
                  >
                    {defaultTagTypes["classification"]}
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full">
                <p className="font-semibold text-lg">3. Description</p>
                <textarea
                  placeholder="Enter description"
                  rows="4"
                  className="flex px-4 py-2 rounded-md border"
                  value={currentTag.description}
                  onChange={(e) =>
                    setCurrentTag((prevTag) => ({
                      ...prevTag,
                      description: e.target.value,
                    }))
                  }
                ></textarea>
              </div>
              <div
                className="w-full flex flex-col"
                style={{
                  display:
                    currentTag.tagType &&
                    currentTag.tagType === defaultTagTypes["sensitivity"]
                      ? "none"
                      : "flex",
                }}
              >
                <p className="font-semibold text-lg">
                  4. Possible Values (Optional)
                </p>
                <div className="flex">
                  <select
                    value={currentTag.option}
                    onChange={(e) => handleChangeOption(e.target.value)}
                    className="px-4 py-2 rounded-md border flex"
                  >
                    <option value="aiGenerated" disabled hidden>
                      I don't have possible values
                    </option>
                    <option value="aiGenerated">
                      I don't have possible values
                    </option>
                    <option value="yesNo">Yes/No</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>
                {currentTag.option === "custom" && (
                  <div className="flex flex-col gap-1 mt-2">
                    <div className="text-xs text-zinc-400">
                      Note: Enter a possible value and hit Enter to save it
                    </div>
                    <div className="text-xs">
                      <div className="flex gap-2 whitespace-nowrap text-zinc-400">
                        <div>Enable AI for this tag?</div>
                        <input
                          type="checkbox"
                          defaultChecked={currentTag.allow_other_values}
                          onChange={(e) =>
                            setCurrentTag((prevTag) => ({
                              ...prevTag,
                              allow_other_values: e.target.checked,
                            }))
                          }
                          className="hidden-checkbox"
                        />
                      </div>
                    </div>
                    <div className="flex gap-1">
                      <input
                        type="text"
                        placeholder=""
                        className="px-4 py-2 rounded-md border outline-none min-w-0"
                        value={currentValue}
                        onChange={(e) => setCurrentValue(e.target.value)}
                        onKeyPress={handleAddValue}
                      />
                      <select
                        value={currentTag.max_words}
                        onChange={(e) =>
                          setCurrentTag((prevTag) => ({
                            ...prevTag,
                            max_words: e.target.value,
                          }))
                        }
                        className="p-2 rounded-md border outline-none w-20"
                        style={{ color: COLOURS["MainText"] }}
                      >
                        <option value="" disabled>
                          Number of words
                        </option>
                        <option value="Doesn't matter">Doesn't matter</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                )}

                {currentTag.option &&
                  currentTag.availableValues &&
                  currentTag.availableValues.length > 0 && (
                    <div className="flex gap-2 pt-2">
                      {currentTag &&
                        currentTag.availableValues.map((value, index) => (
                          <div
                            key={index}
                            className="px-2 py-1 bg-blue-400 rounded-md text-white"
                          >
                            {value}
                            <span
                              className="font-black cursor-pointer"
                              onClick={() => handleDeleteValue(value)}
                            >
                              &times;
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
              </div>
              {currentTag.tagType === sensitivityTagType && (
                <div className="flex flex-col">
                  <p className="font-semibold text-lg">3. Risk Level</p>
                  <div>
                    <select
                      value={currentTag.risk_level}
                      onChange={(e) => handleRiskLevelChange(e.target.value)}
                      className="flex border px-4 py-2 rounded"
                    >
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-2 py-2">
                    <p className="font-semibold text-lg">Define Low Risk</p>
                    <textarea
                      placeholder="Input Low Risk (Optional)"
                      className="flex px-4 py-2 rounded-md border w-full"
                      value={currentTag.risk_level_description?.low || ""}
                      onChange={(e) =>
                        setCurrentTag((prevTag) => {
                          prevTag.risk_level_description =
                            prevTag.risk_level_description || {};
                          prevTag.risk_level_description.low = e.target.value;
                          return {
                            ...prevTag,
                          };
                        })
                      }
                    ></textarea>
                    <p className="font-semibold text-lg">Define Medium Risk</p>
                    <textarea
                      placeholder="Input Medium Risk (Optional)"
                      className="flex px-4 py-2 rounded-md border w-full"
                      value={currentTag.risk_level_description?.medium || ""}
                      onChange={(e) =>
                        setCurrentTag((prevTag) => {
                          prevTag.risk_level_description =
                            prevTag.risk_level_description || {};
                          prevTag.risk_level_description.medium =
                            e.target.value;
                          return {
                            ...prevTag,
                          };
                        })
                      }
                    ></textarea>
                    <p className="font-semibold text-lg">Define High Risk</p>
                    <textarea
                      placeholder="Input High Risk (Optional)"
                      className="flex px-4 py-2 rounded-md border w-full"
                      value={currentTag.risk_level_description?.high || ""}
                      onChange={(e) =>
                        setCurrentTag((prevTag) => {
                          prevTag.risk_level_description =
                            prevTag.risk_level_description || {};
                          prevTag.risk_level_description.high = e.target.value;
                          return {
                            ...prevTag,
                          };
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              )}
              <div
                className="flex flex-col"
                style={{
                  display:
                    currentTag.tagType &&
                    currentTag.tagType === sensitivityTagType
                      ? "none"
                      : "flex",
                }}
              >
                <p className="font-semibold text-lg">5. Expecting a</p>
                <div className="flex">
                  <select
                    value={currentTag.type}
                    onChange={(e) => changeType(e.target.value)}
                    className="px-4 py-2 border rounded-md flex"
                  >
                    <option value="word">Word</option>
                    <option value="number">Number</option>
                    <option value="date">Date</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-lg">
                  {currentTag.tagType === sensitivityTagType ? "4. " : "6. "}{" "}
                  Apply tag on the document level
                </p>
                <p className="text-m text-gray-500">
                  If "No" - tags are applied on the chunks of the document.
                </p>
                <Checkbox
                  id={"is_document_level"}
                  defaultChecked={currentTag.is_document_level}
                  value={currentTag.is_document_level}
                  onChange={(e) => {
                    changeIsDocumentLevel(e.target.checked);
                  }}
                  label={currentTag.is_document_level ? "Yes" : "No"}
                ></Checkbox>
              </div>
            </div>
          </div>
          <div className="h-[50vh] overflow-y-auto">
            <div className="flex flex-col overflow-auto h-full">
              <div className="bg-white">
                <div className="p-4 bg-slate-300 text-base sticky top-0">
                  <div className="flex flex-row w-full items-center">
                    <h2 className="font-bold whitespace-nowrap">
                      Fine-tune Your Model with Examples
                    </h2>
                    <div className="flex flex-row justify-end w-full">
                      <button
                        onClick={addExamplePair}
                        className=" bg-blue-500 text-white px-4 py-2 rounded-md"
                      >
                        Add new pair
                      </button>
                      <div className="relative flex items-center">
                        {!showTaggingStudio && (
                          <div className="relative group">
                            <button
                              onMouseOver={() =>
                                setShowTooltip(
                                  !currentTag.name ||
                                    !currentTag.description ||
                                    !currentTag.tagType
                                )
                              }
                              onFocus={() =>
                                setShowTooltip(
                                  !currentTag.name ||
                                    !currentTag.description ||
                                    !currentTag.tagType
                                )
                              }
                              onBlur={() => setShowTooltip(false)}
                              onClick={
                                currentTag.name &&
                                currentTag.description &&
                                currentTag.tagType
                                  ? toggleTaggingStudio
                                  : undefined
                              }
                              className={`p-2 ml-1 rounded-md text-white inline-flex items-center justify-center transition-colors duration-300 ${
                                currentTag.name &&
                                currentTag.description &&
                                currentTag.tagType
                                  ? "bg-slate-400 hover:bg-slate-500"
                                  : "bg-slate-400 cursor-not-allowed opacity-50"
                              }`}
                            >
                              <RiExpandRightFill />
                              <span className="whitespace-nowrap p-2">
                                Open Tagging Studio
                              </span>
                            </button>
                            {showTooltip && (
                              <div
                                className="absolute z-50 w-48 p-2 mt-2 text-sm text-white bg-black rounded-md shadow-lg left-1/2 transform -translate-x-1/2 opacity-100" // Adjust positioning and ensure full opacity
                                style={{ top: "100%" }}
                              >
                                Please fill out a name, description, and type of
                                tag to proceed to the Tagging Studio.
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <p className="text-sm mt-2">
                    Improve your model's accuracy by adding pairs of evidences
                    from your documents and your expected output.
                  </p>
                </div>

                <div className="space-y-4 p-4">
                  {currentTag.examples && currentTag.examples.length > 0 ? (
                    currentTag.examples.map((example, index) => (
                      <div key={index} className="flex gap-4 items-start">
                        <textarea
                          className={`flex-1 p-3 rounded-md border h-32 resize-none`}
                          placeholder="Enter your evidence"
                          value={example.evidence}
                          onChange={(e) =>
                            updateExample(index, "evidence", e.target.value)
                          }
                        />
                        <textarea
                          className={`flex-1 p-3 rounded-md border h-32 resize-none`}
                          placeholder="Enter the expected output"
                          value={example.value}
                          onChange={(e) =>
                            updateExample(index, "value", e.target.value)
                          }
                        />
                        <button
                          onClick={() => deleteExamplePair(index)}
                          className="bg-red-500 text-white p-2 rounded-md"
                        >
                          &times;
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="text-start p-4">
                      <p className="text-lg text-gray-700">
                        It looks like there are no example pairs added yet.
                      </p>
                      <p className="text-md text-gray-500">
                        Head over to the{" "}
                        <span className="font-semibold">Tagging Studio</span>{" "}
                        where you can auto-generate them or add them manually
                        here.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!showTaggingStudio && (
            <div className="flex w-full text-base items-end text-right justify-end">
              <button
                className="bg-primary w-3/12 text-white m-4 p-2 rounded-md"
                onClick={() => {
                  saveTag(currentTag);
                }}
              >
                Save Tag
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${!showTaggingStudio ? "absolute" : ""} top-0 bottom-0 right-0 h-full flex items-center justify-center pr-2`}
      >
        {showTaggingStudio && (
          <button
            onClick={toggleTaggingStudio}
            className="bg-slate-400 text-white rounded-md inline-flex items-center justify-center rotate-[-270deg] hover:bg-slate-500 transition-colors duration-300 px-3 py-1"
          >
            <RiExpandLeftFill className="mr-2" />{" "}
            <span className="whitespace-nowrap">Close Tagging Studio</span>{" "}
          </button>
        )}
      </div>
      <div
        className={`w-full h-full transition-all  ${
          !showTaggingStudio ? "translate-x-full absolute" : "relative"
        }`}
      >
        <div className="flex absolute inset-0 duration-300 w-full">
          <TaggingStudio
            currentTag={currentTag}
            setCurrentTag={setCurrentTag}
            validatedTags={validatedTags}
            setValidatedTags={setValidatedTags}
            setPreviousExamples={setPreviousExamples}
            previousExamples={previousExamples}
          />
        </div>
      </div>
    </div>
  );
}
