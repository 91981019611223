// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.css */
.DataFilterModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.DataFilterModal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  height: 87vh;
  width: 90vw;
  overflow: hidden;
  margin-top: 10vh;
}

.DataFilterModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DataFilterCloseButton {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.DataFilterModalContent {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/DataFilterModal/DataFilterModal.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* Modal.css */\n.DataFilterModalOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.DataFilterModal {\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  position: relative;\n  height: 87vh;\n  width: 90vw;\n  overflow: hidden;\n  margin-top: 10vh;\n}\n\n.DataFilterModalHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.DataFilterCloseButton {\n  background-color: transparent;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n}\n\n.DataFilterModalContent {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
