// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-mapping-list {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  overflow: auto;
}

.modal-content-mapping-list {
  position: relative;
  padding: 30px;
  width: 80vw;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  overflow: auto;
  height: 90vh;
  justify-content: center;
}

.close-mapping-list {
  position: absolute;
  right: 1vw;
  top: 1vh;
  padding: 20px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/DataCatalog/DataCatalogComponents/Standardization/Standardization.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,aAAa;EACb,eAAe;AACjB","sourcesContent":[".modal-mapping-list {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 999999;\n  overflow: auto;\n}\n\n.modal-content-mapping-list {\n  position: relative;\n  padding: 30px;\n  width: 80vw;\n  background-color: #fff;\n  border-radius: 5px;\n  display: flex;\n  overflow: auto;\n  height: 90vh;\n  justify-content: center;\n}\n\n.close-mapping-list {\n  position: absolute;\n  right: 1vw;\n  top: 1vh;\n  padding: 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
