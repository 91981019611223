import React from "react";

export const Checkbox = ({
  id,
  defaultChecked,
  value,
  onChange,
  label,
}: {
  id: string;
  defaultChecked?: boolean;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}) => {
  return (
    <div className="flex items-center gap-2 p-2 bg-gray-50 rounded-lg hover:bg-gray-100">
      <input
        type={"checkbox"}
        id={id}
        defaultChecked={defaultChecked}
        value={value as any}
        checked={value}
        onChange={onChange}
        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
      />
      <label htmlFor={id} className="text-sm font-medium text-gray-700">
        {label}
      </label>
    </div>
  );
};
