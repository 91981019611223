import "./DataCatalog.css";
import LoadComponent from "../../../../utilities/LoadComponent/LoadComponent";
import SearchBar from "../../../../utilities/SearchBar/SearchBar";
import DataGraph from "./DataCatalogComponents/DataGraph/DataGraph";
import DataList from "./DataCatalogComponents/DataList/DataList";
import Standardization from "./DataCatalogComponents/Standardization/Standardization";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";
import TagDefinitions from "./TagDefinitions";
import useCatalogData from "./useCatalogData";
import { useContext } from "react";

export default function DataCatalog() {
  const {
    showScreen,
    catalogSummary,
    availableTags,
    setSearchTerm,
    searchTerm,
    isLoading,
    selectedFilters,
    preferences,
    failedTags,
    tagsToBeDeleted,
    ruleDict,
  } = useContext(DataContext);
  const { processTag } = useContext(TagContext);

  const {
    mappings,
    setMappings,
    quarantineFiles,
    acceptNewValues,
    runStandardization,
    standardizeValues,
    handleResetFilter,
    handleFilterChange,
    noOfDocumentsWithFailedTags,
    semanticSimilarity,
    setSemanticSimilarity,
    dropdownOpen,
    setDropdownOpen,
    standardizationLoading,
    standardizeMinCategories,
    setStandardizeMinCategories,
    standardizeSensitivity,
    setStandardSensitivity,
    standardizeScreen,
    setStandardizeScreen,
    showStandardization,
    setShowStandardization,
    answerLoading,
    setAnswerLoading,
    standardizeTag,
    setStandardizeTag,
    clearAllFilters,
  } = useCatalogData();

  if (isLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="flex w-full h-full gap-4 overflow-hidden">
      <div className="flex flex-col rounded-md w-full h-full overflow-hidden">
        <Standardization
          mappings={mappings}
          setMappings={setMappings}
          showStandardization={showStandardization}
          setShowStandardization={setShowStandardization}
          acceptNewValues={acceptNewValues}
          standardizeTag={standardizeTag}
          setStandardizeTag={setStandardizeTag}
          standardizeScreen={standardizeScreen}
          setStandardizeScreen={setStandardizeScreen}
          setStandardSensitivity={setStandardSensitivity}
          standardizeSensitivity={standardizeSensitivity}
          semanticSimilarity={semanticSimilarity}
          setSemanticSimilarity={setSemanticSimilarity}
          runStandardization={runStandardization}
          standardizeMinCategories={standardizeMinCategories}
          setStandardizeMinCategories={setStandardizeMinCategories}
          standardizationLoading={standardizationLoading}
        />
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        <DataList
          answerLoading={answerLoading}
          setAnswerLoading={setAnswerLoading}
        />
      </div>
      <div
        className={`flex flex-col w-full gap-4 ${window.location.href.includes("/catalog") ? "max-w-xs" : "max-w-md"} overflow-hidden`}
      >
        <TagDefinitions />
      </div>
    </div>
  );
}
