import { useContext, useMemo, useState } from "react";
import { useCatalogNames } from "../../../api/queryHooks";
import { DataContext } from "../../../context/DataContext";
import { useUserProfile } from "../../../context/UserProfile";
import { FaPencilAlt } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

import "./SearchBar.css";
import { toast } from "../Toast";

export default function SearchBar() {
  const {
    setSearchTerm,
    usedCatalog,
    handleCatalogChange,
    handleCatalogRename,
  } = useContext(DataContext);
  const { permissions } = useUserProfile();
  const { isLoading, data: catalogNames = [], refetch } = useCatalogNames();
  const [isCatalogNameBeingEdited, setIsCatalogNameBeingEdited] =
    useState(false);
  const [oldCatalogName, setOldCatalogName] = useState("");
  const [newCatalogName, setNewCatalogName] = useState("");
  const [isRenaming, setIsRenaming] = useState(false);

  const selectValue = useMemo(() => {
    return catalogNames.includes(usedCatalog) ? usedCatalog : "";
  }, [usedCatalog, catalogNames]);

  return (
    <div className="w-full shrink-0 flex-flex-col rounded-t-md overflow-hidden bg-slate-200 dark:bg-zinc-600">
      <div className="flex w-full outline-none dark:text-white">
        <div className="flex w-full flex-col">
          <div className="text-sm bg-slate-200 dark:bg-zinc-600 px-4 h-16 flex items-center">
            <div className="catalog-selection">
              Catalog:
              {isLoading || isRenaming ? (
                <div className="px-2 mx-2 text-white py-1 rounded-md bg-green-500">
                  Loading...
                </div>
              ) : isCatalogNameBeingEdited ? (
                <div>
                  <input
                    className="p-2 ml-2 rounded-md border outline-none"
                    value={newCatalogName}
                    onChange={(e) => setNewCatalogName(e.target.value)}
                  />
                </div>
              ) : (
                <select onChange={handleCatalogChange} value={selectValue}>
                  <option value="" disabled>
                    Select a catalog
                  </option>
                  {catalogNames.map((catalog, index) => (
                    <option key={index} value={catalog}>
                      {catalog}
                    </option>
                  ))}
                  {permissions.catalogs.canEdit && (
                    <option value="newCatalog">Create New Catalog</option>
                  )}
                </select>
              )}
              {permissions.catalogs.canEdit && !isCatalogNameBeingEdited && (
                <button
                  className="pl-2 text-gray-600"
                  onClick={() => {
                    setIsCatalogNameBeingEdited(true);
                    setOldCatalogName(selectValue);
                    setNewCatalogName(selectValue);
                  }}
                >
                  <FaPencilAlt />
                </button>
              )}
              {isCatalogNameBeingEdited && (
                <div className="flex">
                  <button
                    onClick={() => {
                      toast.info({
                        title: "Info",
                        description:
                          "Your catalog is being renamed, please wait",
                      });
                      setIsRenaming(true);
                      handleCatalogRename(oldCatalogName, newCatalogName)
                        .then(async () => {
                          await refetch();
                          toast.success({
                            title: "Info",
                            description:
                              "Your catalog was successfully renamed!",
                          });
                        })
                        .finally(() => {
                          setIsRenaming(false);
                        });
                      setIsCatalogNameBeingEdited(false);
                      setOldCatalogName("");
                      setNewCatalogName("");
                    }}
                    className="text-white bg-green-500 text-xl"
                  >
                    <MdCheck />
                  </button>
                  <button
                    onClick={() => {
                      setIsCatalogNameBeingEdited(false);
                      setOldCatalogName("");
                      setNewCatalogName("");
                    }}
                    className="text-white bg-rose-500 text-xl"
                  >
                    <RxCross2 />
                  </button>
                </div>
              )}
            </div>
          </div>
          <input
            type="text"
            className={`block w-full bg-white dark:bg-zinc-800 text-sm dark:text-white bg-transparent p-4 dark:rounded-none focus:outline-none`}
            placeholder={"Search for a document"}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
