// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-interface {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-between;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/ChatInterface/ChatInterface.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,8BAA8B;EAC9B,aAAa;AACf","sourcesContent":[".chat-interface {\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n  justify-content: space-between;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
