import React, { useState } from 'react';
import { Authenticator as AWSAuthenticator } from "@aws-amplify/ui-react";
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalInstance } from "./msalConfig";
import Logo from "../assets/images/Logo.png"
import "./LoginProvider.css";

interface LoginProviderProps {
  children: React.ReactNode;
}

const AzureSignInButton: React.FC = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    try {
      setLoading(true);
      await instance.loginPopup();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button onClick={handleLogin} className="login-button" disabled={loading}>
      {loading ? <div className="spinner"></div> : 'Azure AD Login'}
    </button>
  );
};

const AzureSignInCard: React.FC = () => {
  return (
    <div className="login-container">
      <div className="login-card">
        <div className="flex items-center justify-center">
          <img src={Logo} className="h-[60px] logo" alt="LogoImageNavBar" />
        </div>
        <AzureSignInButton />
      </div>
    </div>
  );
};

const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const authProvider = process.env.REACT_APP_AUTH_PROVIDER?.toLowerCase() ?? "aws";

  if (authProvider === "azure") {
    return (
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          {children}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AzureSignInCard />
        </UnauthenticatedTemplate>
      </MsalProvider>
    );
  } else {
    return <AWSAuthenticator>{children}</AWSAuthenticator>;
  }
};

export default LoginProvider;
